import { useCallback, useRef } from 'react';
import { styled } from '@material-ui/core/styles';
import MomentAdapter from '@mui/lab/AdapterMoment';
import { LocalizationProvider } from '@mui/lab';
import Add from '@mui/icons-material/Add';
import momentTimezone from 'moment-timezone';
import { IGroupedCustomShift } from '../../models/IEmployee';
import {
    Switch,
    Box,
    Typography,
    Table,
    TableBody,
    Button,
    TableRow,
    TableCell,
    Stack,
    IconButton,
    FormHelperText,
    TextField
} from '@mui/material';
import AppTimePicker from '../../ui-component/form/time-picker/AppTimePicker';
import Delete from '@mui/icons-material/Delete';
import { FormikErrors } from 'formik';
import ShiftPicker from '../../ui-component/custom-shifts/shift-picker/shift-picker';
import { format } from 'date-fns';
import useCustomShiftConversions from '../../hooks/useCustomShiftConversions';
import { isArray } from 'lodash';
import { EmployeeSubFormValueType } from './use-employee-form-schema';

const StyledBox = styled(Box)(() => ({
    width: '100%',
    maxWidth: '750px',
    maxHeight: '400px',
    overflow: 'auto',

    '& .shifts-wrap': {
        display: 'block',
        overflow: 'auto',
        // minWidth: '550px',

        '& .MuiTable-root': {
            width: '100%'
        }
    },

    '& tr:last-child td, & tr:last-child th': {
        border: 0
    }
}));

interface CustomShiftsTableProps {
    shifts: IGroupedCustomShift[];
    setShifts: (data: IGroupedCustomShift[]) => void;
    errors?: FormikErrors<EmployeeSubFormValueType>;
}

const CustomShiftsTable = ({ shifts, setShifts, errors }: CustomShiftsTableProps) => {
    const { moment } = new MomentAdapter({ instance: momentTimezone });
    const { getShiftExcludedValues, getSelectionDetails } = useCustomShiftConversions();
    const shiftsListRef = useRef<null | HTMLDivElement>(null);

    const scrollShiftsToBottom = useCallback(() => {
        if (shiftsListRef.current) shiftsListRef.current.scrollTop = shiftsListRef.current.scrollHeight;
    }, []);

    const addRow = useCallback(() => {
        const newShifts = [
            ...shifts,
            {
                opened: false,
                startTime: '09:00:00',
                endTime: '18:00:00',
                shifts: []
            }
        ];
        setShifts(newShifts);
        setTimeout(scrollShiftsToBottom, 200);
    }, [shifts, setShifts, scrollShiftsToBottom]);

    const removeRow = useCallback(
        (index: number) => {
            const newShifts = shifts.map((shift) => ({ ...shift }));
            newShifts.splice(index, 1);
            setShifts(newShifts);
        },
        [setShifts, shifts]
    );

    const updateShiftByIndex = useCallback(
        (index: number, data: Partial<IGroupedCustomShift>) => {
            const newShifts = shifts.map((shift, i) => (index === i ? { ...shift, ...data } : shift));
            setShifts(newShifts);
        },
        [setShifts, shifts]
    );

    /* const convertRowTimeToMoment = (time: string) => {
        if (time) {
            const parsedTime = time.split(':').map((value) => parseInt(value, 10));
            const [hour, minutes, second] = parsedTime;

            return moment().set({ hour, minutes, second, millisecond: 0 });
        }

        return moment().set({ hour: 9, minutes: 0, second: 0, millisecond: 0 });
    }; */

    const getFieldErrors = useCallback(
        (index, field) => {
            // @ts-ignore
            const errValue = errors?.shifts?.[index]?.[field];
            if (errValue) {
                return isArray(errValue) ? errValue.join(', ') : errValue;
            }

            return null;
        },
        [errors]
    );

    const getExcludedByIndex = useCallback((index: number) => getShiftExcludedValues(index, shifts), [getShiftExcludedValues, shifts]);

    return (
        <>
            <StyledBox ref={shiftsListRef}>
                {/* {!shifts.length && <Typography mb={1}>Нет выходных</Typography>} */}
                <Box className="shifts-wrap">
                    <Table size="small" padding="none">
                        <TableBody>
                            <LocalizationProvider dateAdapter={MomentAdapter}>
                                {shifts.map((row, index) => (
                                    <TableRow key={index} sx={{ verticalAlign: 'top' }}>
                                        <TableCell scope="row" width={50} sx={{ py: 1, verticalAlign: 'middle' }}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                {/* <TextField
                                                    sx={{
                                                        '.MuiInputBase-root:before': {
                                                            borderBottom: '1px solid transparent'
                                                        },

                                                        input: {
                                                            '&::placeholder': {
                                                                opacity: 1
                                                            }
                                                        }
                                                    }}
                                                    variant="standard"
                                                    placeholder={
                                                        getSelectionDetails(row.shifts.map((s) => new Date(`${s}T00:00:00`))).details??'Не выбрано дат'
                                                    }
                                                    value={row.label ?? ''}
                                                    onChange={(e) => updateShiftByIndex(index, { label: e.target.value })}
                                                /> */}
                                                <Typography>
                                                    {getSelectionDetails(row.shifts.map((s) => new Date(`${s}T00:00:00`))).details ??
                                                        'Не выбрано дат'}
                                                </Typography>
                                                <ShiftPicker
                                                    value={row.shifts.map((s) => new Date(`${s}T00:00:00`))}
                                                    onChange={(dates) => {
                                                        updateShiftByIndex(index, { shifts: dates.map((d) => format(d, 'yyyy-MM-dd')) });
                                                    }}
                                                    excluded={getExcludedByIndex(index)}
                                                />
                                            </Stack>

                                            {!!getFieldErrors(index, 'shifts') && (
                                                <FormHelperText error>{getFieldErrors(index, 'shifts')}</FormHelperText>
                                            )}
                                        </TableCell>
                                        {/* <TableCell align="left" width={130} sx={{ py: 1 }}>
                                            <Stack direction="row" alignItems="center" width={100}>
                                                <Switch
                                                    checked={row.opened}
                                                    name={`shifts[${index}].opened`}
                                                    onChange={() => {
                                                        updateShiftByIndex(index, { opened: !row.opened });
                                                    }}
                                                    value={row.opened}
                                                />
                                                {row.opened ? 'Работает' : 'Не работает'}
                                            </Stack>
                                        </TableCell> */}
                                        {/* <TableCell align="left" width={100} sx={{ py: 1 }}>
                                            {row.opened && (
                                                <>
                                                    <AppTimePicker
                                                        size="small"
                                                        value={convertRowTimeToMoment(row.startTime)}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                updateShiftByIndex(index, { startTime: value.format('HH:mm:ss') });
                                                            }
                                                        }}
                                                    />
                                                    <FormHelperText error>{getFieldErrors(index, 'startTime')}</FormHelperText>
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell width={30} align="center" sx={{ pt: '16px' }}>
                                            {row.opened && 'до'}
                                        </TableCell>
                                        <TableCell align="left" width={100} sx={{ py: 1 }}>
                                            {row.opened && (
                                                <>
                                                    <AppTimePicker
                                                        size="small"
                                                        value={convertRowTimeToMoment(row.endTime)}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                updateShiftByIndex(index, { endTime: value.format('HH:mm:ss') });
                                                            }
                                                        }}
                                                    />
                                                    <FormHelperText error>{getFieldErrors(index, 'endTime')}</FormHelperText>
                                                </>
                                            )}
                                        </TableCell> */}
                                        <TableCell width={25} align="center" sx={{ py: 1 }}>
                                            <IconButton
                                                onClick={() => {
                                                    removeRow(index);
                                                }}
                                            >
                                                <Delete color="error" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </LocalizationProvider>
                        </TableBody>
                    </Table>
                </Box>
            </StyledBox>
            <Button startIcon={<Add />} onClick={addRow}>
                Добавить выходной
            </Button>
        </>
    );
};

export default CustomShiftsTable;
