import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import ServiceList from 'views/service/ServiceList';
import ServiceEdit from 'views/service/ServiceEdit';
import ServiceCreate from 'views/service/ServiceCreate';
import EmployeeList from 'views/employee/EmployeeList';
import EmployeeEdit from 'views/employee/EmployeeEdit';
import EmployeeCreate from 'views/employee/EmployeeCreate';
import CustomerTabs from 'views/customer/CustomerTabs';
import CustomerEdit from 'views/customer/CustomerEdit';
import CustomerCreate from '../views/customer/CustomerCreate';
import RoleGuard from '../utils/route-guard/RoleGuard';
import Head from '../utils/head/index';
import { WebsocketProvider } from '../contexts/WebsocketContext';
import { UserRoleProvider } from '../contexts/UserRolesContext';
import MailboxList from '../views/mailbox/MailboxList';
import MailboxSettings from '../views/mailbox/settings/MailboxSettings';
import Inbox from '../views/mailbox/inbox/Inbox';
import TestForm from '../views/test';
import WidgetSettings from '../views/widget-settings/WidgetSettings';
import Settings from '../views/settings/Settings';
import WaiverList from '../views/waiver/WaiverList';
import WaiverEdit from '../views/waiver/WaiverEdit';
import WaiverCreate from '../views/waiver/WaiverCreate';
import ErrorCatcher from '../ui-component/error-catcher';
import SmsPaymentSuccess from '../views/sms-management/components/SmsPaymentSuccess';
import SubscriptionsManagement from '../views/subscriptions-management';

const CompanyEdit = Loadable(lazy(() => import('../views/company/CompanyEdit')));
const CompanyCreate = Loadable(lazy(() => import('../views/company/CompanyCreate')));
const LocationList = Loadable(lazy(() => import('../views/location/LocationList')));
const AccountProfile = Loadable(lazy(() => import('../views/account-profile/index')));
const LocationEdit = Loadable(lazy(() => import('../views/location/LocationEdit')));
const LocationCreate = Loadable(lazy(() => import('../views/location/LocationCreate')));
const VerificationMail = Loadable(lazy(() => import('../views/authentication/VerificationMail')));
const Calendar = Loadable(lazy(() => import('../views/calendar')));
const Reports = Loadable(lazy(() => import('../views/reports/Reports')));
const SearchPage = Loadable(lazy(() => import('../views/search/SearchPage')));
const CouponsPage = Loadable(lazy(() => import('../views/coupons/CouponsPage')));
const NewCouponPage = Loadable(lazy(() => import('../views/coupons/AddCoupon')));
const EditCouponPage = Loadable(lazy(() => import('../views/coupons/EditCoupon')));
const InsightsPage = Loadable(lazy(() => import('../views/insights')));
const ZoomHostPage = Loadable(lazy(() => import('../views/calls/ZoomEmployeeHostCall')));
const ZoomAppointmentHostPage = Loadable(lazy(() => import('../views/calls/ZoomAppointmentHostCall')));
const ProductsPage = Loadable(lazy(() => import('../views/products')));
const NewProductPage = Loadable(lazy(() => import('../views/products/pages/CreateProduct')));
const EditProductPage = Loadable(lazy(() => import('../views/products/pages/EditProduct')));
const ProductBrandsAndCategoriesPage = Loadable(lazy(() => import('../views/products/pages/ProductBrandsAndCategories')));
const SiteBuilderPage = Loadable(lazy(() => import('../views/site-builder/SitebuilderGuard')));
const LinkTree = Loadable(lazy(() => import('../views/link-tree')));
const SmsManagement = Loadable(lazy(() => import('../views/sms-management')));
const ServicesCategoriesScreen = Loadable(lazy(() => import('../views/service-categories')));

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <WebsocketProvider>
                <UserRoleProvider>
                    <MainLayout />
                </UserRoleProvider>
            </WebsocketProvider>
        </AuthGuard>
    ),
    children: [
        // {
        //     path: '/dashboard',
        //     element: <Dashboard />
        // },
        {
            path: '/',
            element: <Calendar />
        },
        {
            path: '/organization',
            element: (
                <>
                    <Head title="Компания |" />
                    <RoleGuard action="update" subject="company">
                        <CompanyEdit />
                    </RoleGuard>
                </>
            )
        },

        {
            path: '/widget-settings',
            element: (
                <>
                    <Head title="Виджет |" />
                    <RoleGuard action="update" subject="company">
                        <WidgetSettings />
                    </RoleGuard>
                </>
            )
        },

        {
            path: '/organization/create',
            element: (
                <>
                    <Head title="Компания |" />
                    <RoleGuard action="create" subject="company">
                        <CompanyCreate />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/reports',
            element: (
                <>
                    <Head title="Отчеты |" />
                    <RoleGuard action="view" subject="company">
                        <Reports />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/settings',
            element: (
                <>
                    <Head title="Общие |" />
                    <RoleGuard action="update" subject="company">
                        <Settings />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/location',
            element: (
                <>
                    <Head title="Локации |" />
                    <RoleGuard action="list" subject="location">
                        <LocationList />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/location/:id',
            element: (
                <>
                    <Head title="Локации |" />
                    <RoleGuard action="update" subject="location">
                        <LocationEdit />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/location/create',
            element: (
                <>
                    <Head title="Локации |" />
                    <RoleGuard action="create" subject="location">
                        <LocationCreate />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/service',
            element: (
                <>
                    <Head title="Услуги |" />
                    <RoleGuard action="list" subject="service">
                        <ServiceList />
                    </RoleGuard>
                </>
            )
        },

        {
            path: '/service-categories',
            element: (
                <>
                    <Head title="Категории услуг |" />
                    <RoleGuard action="list" subject="service">
                        <ServicesCategoriesScreen />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/service/:id',
            element: (
                <>
                    <Head title="Услуги |" />
                    <RoleGuard action="update" subject="service">
                        <ServiceEdit />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/service/create',
            element: (
                <>
                    <Head title="Услуги |" />
                    <RoleGuard action="create" subject="service">
                        <ServiceCreate />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/products',
            element: (
                <>
                    <Head title="Продукты | " />
                    <RoleGuard action="list" subject="product">
                        <ProductsPage />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/products/create',
            element: (
                <>
                    <Head title="Новый продукт | " />
                    <RoleGuard action="create" subject="product">
                        <NewProductPage />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/products/brands-and-categories',
            element: (
                <>
                    <Head title="Брэнды и категории | " />
                    <RoleGuard action="update" subject="product">
                        <ProductBrandsAndCategoriesPage />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/products/:productId',
            element: (
                <>
                    <Head title="Редактирование продукта | " />
                    <RoleGuard action="update" subject="product">
                        <EditProductPage />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/employee',
            element: (
                <>
                    <Head title="Персонал |" />
                    <RoleGuard action="list" subject="employee">
                        <EmployeeList />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/employee/:id',
            element: (
                <>
                    <Head title="Персонал |" />
                    <RoleGuard action="update" subject="employee">
                        <EmployeeEdit />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/employee/invite/:email/:exist/:id',
            element: (
                <>
                    <Head title="Персонал |" />
                    <RoleGuard action="create" subject="employee">
                        <EmployeeCreate />
                    </RoleGuard>
                </>
            )
        },

        {
            path: '/employee/invite/:email/:exist',
            element: (
                <>
                    <Head title="Персонал |" />
                    <RoleGuard action="create" subject="employee">
                        <EmployeeCreate />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/employee/create/:email',
            element: (
                <>
                    <Head title="Персонал |" />
                    <RoleGuard action="create" subject="employee">
                        <EmployeeCreate />
                    </RoleGuard>
                </>
            )
        },
        {
            path: 'email/verify/:id',
            element: (
                <>
                    <Head title="Персонал |" />
                    <VerificationMail />
                </>
            )
        },
        {
            path: '/customer',
            element: (
                <>
                    <Head title="Клиенты |" />
                    <RoleGuard action="list" subject="customer">
                        <CustomerTabs />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/customer/:id',
            element: (
                <>
                    <Head title="Клиенты |" />
                    <RoleGuard action="update" subject="customer">
                        <CustomerEdit />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/customer/create/',
            element: (
                <>
                    <Head title="Клиенты |" />
                    <RoleGuard action="create" subject="customer">
                        <CustomerCreate />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/forms',
            element: (
                <>
                    <Head title="Конструктор форм |" />
                    <RoleGuard action="update" subject="company">
                        <WaiverList />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/forms/:id',
            element: (
                <>
                    <Head title="Конструктор форм |" />
                    <RoleGuard action="update" subject="company">
                        <WaiverEdit />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/forms/create',
            element: (
                <>
                    <Head title="Конструктор форм |" />
                    <RoleGuard action="update" subject="company">
                        <WaiverCreate />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/mailbox',
            element: <MailboxList />
        },
        {
            path: '/mailbox/settings/:id',
            element: <MailboxSettings />
        },
        {
            path: '/mailbox/settings/edit/:id',
            element: <MailboxSettings />
        },
        {
            path: '/mailbox/settings/connection/:id',
            element: <MailboxSettings />
        },
        {
            path: '/mailbox/settings/permissions/:id',
            element: <MailboxSettings />
        },
        {
            path: '/mailbox/create/:email/:id',
            element: <MailboxSettings />
        },
        {
            path: '/mailbox/create/:email',
            element: <MailboxSettings />
        },
        {
            path: '/mailbox/:id',
            element: <Inbox />
        },
        {
            path: '/mailbox/:id/conversations/:id/threads',
            element: <Inbox />
        },
        {
            path: '/calendar',
            element: (
                <>
                    <Head title="Календарь |" />
                    <Calendar />
                </>
            )
        },
        {
            path: '/profile',
            element: (
                <>
                    <Head title="Профиль |" />
                    <AccountProfile />
                </>
            )
        },
        {
            path: '/profile/cbvc',
            element: (
                <>
                    <Head title="Начало звонка |" />
                    <ZoomHostPage />
                </>
            )
        },
        {
            path: '/appointment/:appointmentId/cbvc',
            element: (
                <>
                    <Head title="Начало звонка |" />
                    <ZoomAppointmentHostPage />
                </>
            )
        },
        {
            path: '/test-page',
            element: (
                <>
                    <Head title="Test Form Header" />
                    <TestForm />
                </>
            )
        },

        {
            path: '/search',
            element: (
                <>
                    <Head title="Поиск |" />
                    <SearchPage />
                </>
            )
        },

        {
            path: '/coupons',
            element: (
                <>
                    <Head title="Купоны |" />
                    <CouponsPage />
                </>
            )
        },
        {
            path: '/coupons/:id',
            element: (
                <>
                    <Head title="Редактирование купона |" />
                    <EditCouponPage />
                </>
            )
        },
        {
            path: '/coupons/new',
            element: (
                <>
                    <Head title="Новый купон |" />
                    <NewCouponPage />
                </>
            )
        },
        {
            path: '/insights',
            element: (
                <>
                    <Head title="Статистика |" />
                    <InsightsPage />
                </>
            )
        },
        {
            path: '/site-builder',
            element: (
                <RoleGuard action="update" subject="company">
                    <ErrorCatcher wrappedComponentName="SiteBuilderPage">
                        <Head title="Сайт компании |" />
                        <SiteBuilderPage />
                    </ErrorCatcher>
                </RoleGuard>
            )
        },
        {
            path: '/profile-card',
            element: (
                <ErrorCatcher wrappedComponentName="LinkTree">
                    <Head title="Онлайн визитка |" />
                    <LinkTree />
                </ErrorCatcher>
            )
        },
        {
            path: '/sms-management',
            element: (
                <ErrorCatcher wrappedComponentName="SmsManagement">
                    <Head title="Настройки уведомлений |" />
                    <SmsManagement />
                </ErrorCatcher>
            )
        },
        {
            path: '/subscription-management',
            element: (
                <ErrorCatcher wrappedComponentName="SubscriptionsManagement">
                    <Head title="Управление подписками |" />
                    <SubscriptionsManagement />
                </ErrorCatcher>
            )
        },
        {
            path: '/sms-package-success',
            element: (
                <ErrorCatcher wrappedComponentName="SmsPaymentSuccess">
                    <SmsPaymentSuccess />
                </ErrorCatcher>
            )
        }
    ]
};

export default MainRoutes;
