import { FC } from 'react';
import appointmentAPI from '../../../../services/AppointmentService';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { Box } from '@material-ui/core';
import { IAppointmentHistory } from '../../../../models/IAppointment';
import BlockTimeHistoryRecord from './BlockTimeHistoryRecord';

const BlockTimeHistory: FC<{ eventId: number }> = ({ eventId }) => {
    const { data: history } = appointmentAPI.useGetAppointmentHistoryQuery(eventId ?? skipToken);

    if (history) {
        return (
            <Box sx={{ fontSize: '12px' }}>
                {[...history].reverse().map((item: IAppointmentHistory, index) => (
                    <BlockTimeHistoryRecord item={item} key={index} />
                ))}
            </Box>
        );
    }

    return null;
};

export default BlockTimeHistory;
