import { axiosBaseQuery } from '../utils/axios';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { LeadData, SaveLeadPayload } from '../views/authentication/types';

const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: axiosBaseQuery(),

    endpoints: (build) => ({
        getLead: build.query<LeadData, string>({
            query: (id) => ({
                method: 'GET',
                url: `get-lead/${id}`
            })
        }),

        saveLead: build.mutation<LeadData, SaveLeadPayload>({
            query: (payload) => ({
                url: '/save-lead',
                method: 'POST',
                data: payload
            })
        })
    })
});

export default authAPI;
