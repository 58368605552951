import { FC, useCallback, useState } from 'react';
import EntityDrawerContainer from '../entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerHeader from '../entity-drawer-layout/EntityDrawerHeader';
import EditRecurringEvent from './EditRecurringEvent';
import RecurringEventHistory from './components/RecurringEventHistory';
import { Box, Tab, Tabs } from '@mui/material';
import Segment from '@mui/icons-material/Segment';
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined';
import HistoryOutlined from '@mui/icons-material/HistoryOutlined';
import UpdatedStyleWrapper from '../updated-style-wrapper';
import appointmentService from '../../services/AppointmentService';
import { useAppDispatch } from '../../hooks/redux';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { openConfirmPopup } from '../../store/confirmPopupSlice';

type RecurringEventDetailsProps = {
    id: number | string;
    entry_date: string;
    onClose: () => void;
};

const RecurringEventDetails: FC<RecurringEventDetailsProps> = ({ id, entry_date, onClose }) => {
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const [tab, setTab] = useState<'details' | 'attendees' | 'history'>('details');

    const [deleteEvent] = appointmentService.useDeleteRecurringEventMutation();

    const handleDelete = useCallback(() => {
        dispatch(startSubmitting());

        deleteEvent(id)
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Событие удалено',
                    alertSeverity: SnackBarTypes.Success
                });
                onClose();
            })
            .catch((e) => {
                showSnackbar({
                    message: e.message || 'Событие не было удалено',
                    alertSeverity: SnackBarTypes.Error
                });
            })
            .finally(() => dispatch(stopSubmitting()));
    }, [deleteEvent, dispatch, id, onClose, showSnackbar]);

    const onDelete = useCallback(() => {
        dispatch(
            openConfirmPopup({
                onConfirm: handleDelete,
                confirmText: `Удалить`,
                text: 'Вы действительно хотите удалить это событие?'
            })
        );
    }, [dispatch, handleDelete]);

    return (
        <UpdatedStyleWrapper>
            <EntityDrawerContainer>
                <EntityDrawerHeader title="Данные события" onClose={onClose} onDelete={onDelete} entityName="событие" />
                <Box
                    sx={{
                        width: '100%',
                        '& .MuiTab-root': { minWidth: '73px', minHeight: '49px', py: 1, px: 2, flexGrow: 1 },
                        '& .MuiTabs-root': {
                            minHeight: '50px'
                        }
                    }}
                >
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        orientation="horizontal"
                        onChange={(_, v) => setTab(v)}
                        value={tab}
                        indicatorColor="secondary"
                        textColor="secondary"
                    >
                        <Tab value="details" icon={<Segment />} iconPosition="start" />
                        <Tab value="attendees" icon={<PersonOutlineOutlined />} iconPosition="start" />
                        <Tab value="history" icon={<HistoryOutlined />} iconPosition="start" />
                    </Tabs>
                </Box>
                {tab === 'details' ? <EditRecurringEvent id={id} entry_date={entry_date} onClose={onClose} /> : null}
                {tab === 'attendees' ? <EditRecurringEvent id={id} entry_date={entry_date} onClose={onClose} customersOnly /> : null}
                {tab === 'history' ? <RecurringEventHistory id={id} /> : null}
            </EntityDrawerContainer>
        </UpdatedStyleWrapper>
    );
};

export default RecurringEventDetails;
