import { AppointmentHistoryEvent, IAppointmentHistory } from '../../models/IAppointment';
import { Stack, useTheme } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { FC, Fragment, useCallback } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EventIcon from '@mui/icons-material/Event';
import moment from 'moment';
import AppointmentHistoryItem from './components/AppointmentHistoryItem';

type AppointmentHistoryProps = {
    data: IAppointmentHistory[];
    isMobile?: boolean;
};

const AppointmentHistory: FC<AppointmentHistoryProps> = ({ data, isMobile }) => {
    const theme = useTheme();

    const eventContent = {
        end_at: { icon: <AccessTimeFilledIcon />, text: 'Время окончания изменено', color: '#05843B' },
        start_at: { icon: <AccessTimeFilledIcon />, text: 'Время начало изменено', color: '#05843B' },
        entry_duration: { icon: <AccessTimeFilledIcon />, text: 'Длительность изменена', color: '#05843B' },
        service_id: { icon: <SettingsSuggestIcon />, text: 'Услуга изменена', color: theme.palette.grey[800] },
        price: { icon: <LocalAtmIcon />, text: 'Цена изменена', color: '#F86F60' },
        employee_id: { icon: <AccountCircleIcon />, text: 'Сотрудник изменен', color: '#3483FA' },
        customer_id: { icon: <AccountCircleIcon />, text: 'Клиент изменен', color: '#3483FA' },

        note: { icon: <DescriptionIcon />, text: 'Заметки изменены', color: '#708B9E' },
        private_note: {
            icon: <DescriptionIcon />,
            text: 'Скрытые заметки изменены',
            color: '#708B9E'
        },
        status: { icon: <SettingsIcon />, text: 'Статус изменен', color: '#0A3A5B' },
        payments: { icon: <LocalAtmIcon />, text: 'Оплата изменена', color: '#F86F60' },
        is_notifications_enabled: { icon: <NotificationsIcon />, text: 'Статус уведомлений изменен', color: '#9B59B6' }
    };

    const convertMessageData = useCallback(
        (item: IAppointmentHistory) => {
            let color = '#E67E22';
            let icon = item.event_source === 'widget' ? <OpenInNewIcon /> : <EventIcon />;
            let text: string | null = null;
            switch (item.event) {
                case AppointmentHistoryEvent.Created: {
                    text = 'Запись создана';
                    break;
                }

                case AppointmentHistoryEvent.ServiceCreated: {
                    text = 'Услуга добавлена';
                    break;
                }

                case AppointmentHistoryEvent.ServiceUpdated: {
                    text = 'Услуга изменена';
                    break;
                }

                case AppointmentHistoryEvent.ServiceDeleted: {
                    text = 'Услуга удалена';
                    break;
                }

                case AppointmentHistoryEvent.ProductCreated: {
                    text = 'Продукт добавлен';
                    break;
                }

                case AppointmentHistoryEvent.ProductUpdated: {
                    text = 'Продукт изменен';
                    break;
                }

                case AppointmentHistoryEvent.ProductDeleted: {
                    text = 'Продукт удален';
                    break;
                }

                case AppointmentHistoryEvent.CustomerPivotCreated: {
                    text = 'Клиент добавлен';
                    icon = eventContent.customer_id.icon;
                    color = eventContent.customer_id.color;
                    break;
                }

                case AppointmentHistoryEvent.CustomerPivotUpdated: {
                    text = 'Клиент обновлен';
                    icon = eventContent.customer_id.icon;
                    color = eventContent.customer_id.color;
                    break;
                }
            }

            return text ? (
                <AppointmentHistoryItem
                    icon={icon}
                    text={text}
                    color={color}
                    createdAt={moment(item.created_at).format('DD.MM.YY | hh:mm a (z)')}
                    createdBy={item.event_source === 'widget' ? 'через онлайн запись' : ` сотрудник: ${item?.user?.title}`}
                    isMobile={isMobile}
                />
            ) : null;
        },
        [isMobile]
    );

    return (
        <Stack spacing={2}>
            {data?.map((item, index) => (
                <Fragment key={`event-${index}`}>
                    {convertMessageData(item)
                        ? convertMessageData(item)
                        : Object.keys(item.modified_values).map((key) => {
                              const content = eventContent[key as keyof typeof eventContent];
                              return (
                                  <AppointmentHistoryItem
                                      key={key}
                                      color={content?.color ?? theme.palette.grey[800]}
                                      icon={content?.icon}
                                      text={content?.text ?? `${key.replaceAll('_', ' ')} changed`}
                                      createdAt={moment(item.created_at).format('MM.DD.YY hh:mm a (z)')}
                                      createdBy={
                                          item.event_source === 'widget' ? 'через онлайн запись' : ` сотрудник: ${item?.user?.title}`
                                      }
                                      isMobile={isMobile}
                                  />
                              );
                          })}
                </Fragment>
            ))}
        </Stack>
    );
};

export default AppointmentHistory;
