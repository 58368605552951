import { FC } from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { Button, IconButton, TextField, TextFieldProps } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface IOverrideInputProps {
    overrideValue: number | null;
    hasOverride: boolean;
    onOverrideChange: (v: boolean) => void;
    onChange: (v: NumberFormatValues) => void;
    toggleLabel: string;
    fieldProps: NumberFormatProps<TextFieldProps>;
}

const OverrideInput: FC<IOverrideInputProps> = ({ overrideValue, hasOverride, onOverrideChange, onChange, fieldProps, toggleLabel }) => {
    if (hasOverride) {
        return (
            <NumberFormat
                value={overrideValue ?? ''}
                customInput={TextField}
                onValueChange={onChange}
                {...fieldProps}
                InputProps={{
                    ...fieldProps.InputProps,
                    sx: { pr: 1, ...fieldProps.InputProps?.sx },
                    endAdornment: (
                        <IconButton color="error" size="small" onClick={() => onOverrideChange(false)}>
                            <DeleteIcon />
                        </IconButton>
                    )
                }}
                sx={{ width: '140px' }}
            />
        );
    }

    return (
        <Button
            sx={{ width: '140px', height: '45px', fontSize: '12px', p: 1 }}
            variant="contained"
            size="large"
            onClick={() => onOverrideChange(true)}
            disableElevation
        >
            {toggleLabel}
        </Button>
    );
};

export default OverrideInput;
