import { Card, Grid, Typography, Stack, Button, useMediaQuery, Theme } from '@material-ui/core';
import { gridSpacing } from 'store/constant';
import CompletedImage from 'assets/images/completed.png';
import { useTheme } from '@material-ui/core/styles';

const ThankYouPage = () => {
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const theme = useTheme();

    return (
        <Card sx={{ p: 2 }}>
            <Grid container direction="column" spacing={gridSpacing} alignItems="center" justifyContent="center" sx={{ my: 3 }}>
                <Grid item xs={12}>
                    <Typography variant={matchSm ? 'h2' : 'h1'} align="center">
                        Вы успешно оплатили подписку.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack alignItems="center" spacing={2}>
                        <Typography align="center" maxWidth={1000} color={theme.palette.grey[500]}>
                            Теперь вы можете перейти на страницу логина и начать пользоваться системой. При возникновении вопросов, вы
                            всегда можете написать нам на инфо@записьпро.рф, либо обратиться к вашему менеджеру напрямую.
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing} alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Button href="/login" variant="contained">
                                Вход
                            </Button>
                        </Grid>
                        {/* <Grid item>
                            <Button
                                variant="contained"
                                target="_blank"
                                href="https://app.zapis.pro/cal/zapispro/location-zapispro/service-oznakomitelnyi-zvonok"
                            >
                                Демо сессия
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3 }}>
                    <img src={CompletedImage} alt="Order Complete" width="100%" style={{ maxWidth: 780 }} />
                </Grid>
            </Grid>
        </Card>
    );
};

export default ThankYouPage;
