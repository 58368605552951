import locationAPI from '../../services/LocationService';
import employeeAPI from '../../services/EmployeeService';
import serviceAPI from '../../services/ServiceService';
import appointmentAPI from '../../services/AppointmentService';
import { useAppDispatch } from '../../hooks/redux';
import waiverAPI from '../../services/WaiverService';

const useInvalidateServices = () => {
    const dispatch = useAppDispatch();

    return () => {
        dispatch(locationAPI.util.invalidateTags(['Location']));
        dispatch(employeeAPI.util.invalidateTags(['Employee']));
        dispatch(serviceAPI.util.invalidateTags(['Service']));
        dispatch(appointmentAPI.util.invalidateTags(['Appointment']));
        dispatch(waiverAPI.util.invalidateTags(['Waiver']));
    };
};

export default useInvalidateServices;
