import { FC } from 'react';
import { Box, Button, ButtonBase } from '@mui/material';
import useNewPaymentOptions from './use-new-payment-options';

const OtherPaymentMethods: FC<{ onSelect: (v: string) => void }> = ({ onSelect }) => {
    const { otherMethods, getLabel } = useNewPaymentOptions();
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}
        >
            {otherMethods.map((method) => {
                if (method.color && method.icon) {
                    return (
                        <ButtonBase
                            key={method.value}
                            sx={{
                                m: 1,
                                px: 2,
                                borderRadius: '4px',
                                height: '40px',
                                background: method.color,
                                '&:hover': { filter: 'brightness(0.95)' }
                            }}
                            aria-label={getLabel(method.value)}
                            onClick={() => {
                                onSelect(method.value);
                            }}
                        >
                            <img src={method.icon} alt="" width={75} />
                        </ButtonBase>
                    );
                }

                return (
                    <Button
                        key={method.value}
                        variant="outlined"
                        sx={{ m: 1, height: '40px', minWidth: '107px' }}
                        onClick={() => {
                            onSelect(method.value);
                        }}
                    >
                        {getLabel(method.value)}
                    </Button>
                );
            })}
        </Box>
    );
};

export default OtherPaymentMethods;
