import { FC, useCallback, useContext, useMemo } from 'react';
import { IEmployee, UserRoleLabel } from '../../models/IEmployee';
import { IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import UserAvatar from '../../ui-component/UserAvatar';
import EllipsisTypography from '../../ui-component/optimized-text-fields/EllipsisTypography';
import { startCase, toLower } from 'lodash';
import GroupOfAvatars, { MoveToProps } from '../../ui-component/GroupOfAvatars';
import { getAvatarData } from '../../ui-component/AvatarData';
import Can, { AbilityContext } from '../../utils/roles/Can';
import { isAllowDeleteEmployee, isAllowEditEmployee } from '../../utils/roles/functions';
import { Link, useNavigate } from 'react-router-dom';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { IconTrashOff } from '@tabler/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import useAuth from '../../hooks/useAuth';
import { useAppDispatch } from '../../hooks/redux';
import { openConfirmPopup } from '../../store/confirmPopupSlice';
import { axiosServices } from '../../utils/axios';
import { SnackBarTypes } from '../../store/snackbarReducer';
import employeeAPI from '../../services/EmployeeService';
import locationAPI from '../../services/LocationService';
import serviceAPI from '../../services/ServiceService';
import useShowSnackbar from '../../hooks/useShowSnackbar';

const EmployeeRow: FC<{ row: IEmployee }> = ({ row }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery('(max-width:600px)');
    const ability = useContext(AbilityContext);
    const { user, checkAuthentication } = useAuth();
    const { showSnackbar } = useShowSnackbar();

    const canUpdate = useCallback((subj: 'service' | 'location') => ability.can('update', subj), [ability]);

    const moveTo = useCallback(
        ({ path, id }: MoveToProps) => {
            navigate(`/${path}/${id}`);
        },
        [navigate]
    );

    const servicesData = useMemo(
        () =>
            getAvatarData({
                data: row.services,
                path: 'service',
                moveTo,
                isClickable: canUpdate('service')
            }),
        [canUpdate, moveTo, row.services]
    );

    const locationsData = useMemo(
        () =>
            getAvatarData({
                data: row.locations,
                path: 'location',
                moveTo,
                isClickable: canUpdate('location')
            }),
        [canUpdate, moveTo, row.locations]
    );

    const removeEmployee = useCallback(async () => {
        if (row) {
            try {
                const res = await axiosServices.delete(`/employees/${row.id}`);
                if (res) {
                    showSnackbar({
                        message: 'Сотрудник деактивирован.',
                        alertSeverity: SnackBarTypes.Success
                    });
                    dispatch(employeeAPI.util.invalidateTags(['Employee']));
                    dispatch(locationAPI.util.invalidateTags(['Location']));
                    dispatch(serviceAPI.util.invalidateTags(['Service']));
                    checkAuthentication();
                }
            } catch (e) {
                dispatch(
                    openConfirmPopup({
                        text: e.message
                    })
                );
                showSnackbar({
                    message: 'Ошибка: сотрудник не был удален.',
                    alertSeverity: SnackBarTypes.Error
                });
            }
        }
    }, [row, showSnackbar, dispatch, checkAuthentication]);

    const restoreEmployee = useCallback(async () => {
        if (row) {
            try {
                const res = await axiosServices.patch(`/employees/${row.id}/restore`);
                if (res) {
                    showSnackbar({
                        message: 'Сотрудник восстановлен.',
                        alertSeverity: SnackBarTypes.Success
                    });
                    dispatch(employeeAPI.util.invalidateTags(['Employee']));
                    dispatch(locationAPI.util.invalidateTags(['Location']));
                    dispatch(serviceAPI.util.invalidateTags(['Service']));
                    checkAuthentication();
                }
            } catch (e) {
                dispatch(
                    openConfirmPopup({
                        text: e.message
                    })
                );
                showSnackbar({
                    message: 'Ошибка: сотрудник не был восстановлен.',
                    alertSeverity: SnackBarTypes.Error
                });
            }
        }
    }, [checkAuthentication, dispatch, row, showSnackbar]);

    const handleDelete = useCallback(() => {
        dispatch(
            openConfirmPopup({
                onConfirm: () => removeEmployee(),
                confirmText: `Деактивировать`,
                text: `Вы действительно хотите деактивировать ${row.user.title}?`
            })
        );
    }, [dispatch, removeEmployee, row.user.title]);

    const handleRestore = useCallback(() => {
        dispatch(
            openConfirmPopup({
                onConfirm: () => restoreEmployee(),
                confirmText: `Восстановить`,
                text: `Вы действительно хотите восстановить ${row.user.title}?`
            })
        );
    }, [dispatch, restoreEmployee, row.user.title]);

    return (
        <TableRow
            sx={{
                '&:hover': {
                    backgroundColor: user?.id !== row.user.id ? theme.palette.grey['100'] : undefined
                },
                backgroundColor: user?.id === row.user.id ? theme.palette.primary.light : undefined
            }}
        >
            <TableCell sx={{ pl: 3 }}>
                <Stack direction="row" alignItems="center">
                    <UserAvatar employee={row} />
                    <Stack sx={{ ml: 1 }}>
                        <EllipsisTypography
                            text={`${row?.user.title}`}
                            ml={0}
                            onClick={ability.can('update', 'employee') ? () => navigate(`/employee/${row.id}`) : undefined}
                        />
                        {!isMobile && (
                            <Typography variant="subtitle2" align="left" component="div">
                                {row.user.email}
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </TableCell>
            <TableCell sx={{ pl: 3 }}>
                <Typography variant="subtitle1" align="left" component="div">
                    {startCase(toLower(UserRoleLabel[row.role]))}
                </Typography>
            </TableCell>
            {!isMobile && (
                <>
                    <TableCell sx={{ p: 2, pl: 3 }}>
                        {row.locations && row.locations.length > 0 && (
                            <GroupOfAvatars data={locationsData} isClickable={canUpdate('location')} />
                        )}
                    </TableCell>
                    <TableCell sx={{ p: 2, pl: 3 }}>
                        {row.services && row.services.length > 0 && (
                            <GroupOfAvatars data={servicesData} isClickable={canUpdate('service')} />
                        )}
                    </TableCell>
                </>
            )}
            {(ability.can('update', 'employee') || ability.can('delete', 'employee')) && (
                <TableCell align="left" sx={{ p: 0, pl: 1 }}>
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <Can I="update" a="employee">
                            {user && isAllowEditEmployee(user, row) ? (
                                <Tooltip placement="top" title="Редактировать сотрудника">
                                    <Link to={`/employee/${row.id}`}>
                                        <IconButton color="primary">
                                            <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            ) : null}
                        </Can>
                        <Can I="delete" a="employee">
                            {/* check this */}
                            {row.deleted_at ? (
                                <Tooltip placement="top" title="Восстановить сотрудника">
                                    <IconButton
                                        onClick={handleRestore}
                                        color="primary"
                                        sx={{
                                            color: theme.palette.orange.dark,
                                            borderColor: theme.palette.orange.main,
                                            '&:hover ': { background: theme.palette.orange.light }
                                        }}
                                    >
                                        <IconTrashOff size="1.1rem" />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                user &&
                                isAllowDeleteEmployee(user, row) && (
                                    <Tooltip placement="top" title="Деактивировать сотрудника">
                                        <IconButton
                                            onClick={handleDelete}
                                            color="primary"
                                            sx={{
                                                color: theme.palette.orange.dark,
                                                borderColor: theme.palette.orange.main,
                                                '&:hover ': { background: theme.palette.orange.light }
                                            }}
                                        >
                                            <DeleteIcon sx={{ fontSize: '1.1rem' }} />
                                        </IconButton>
                                    </Tooltip>
                                )
                            )}
                        </Can>
                    </Stack>
                </TableCell>
            )}
        </TableRow>
    );
};

export default EmployeeRow;
