import { Tabs, Tab, Box, Stack } from '@mui/material';
import Segment from '@mui/icons-material/Segment';
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined';
import PaymentOutlined from '@mui/icons-material/PaymentOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { IAppointment } from '../../../../../models/IAppointment';
import WaiverSignIndicator from '../../../../../ui-component/WaiverSignIndicator';
import { useForceMobileLayoutContext } from '../../../../../ui-component/force-mobile-layout-context';
import AppointmentDepositOpenedIndicator from '../../../../../ui-component/AppointmentDepositOpenedIndicator';
import useAuth from '../../../../../hooks/useAuth';

interface MenuProps {
    activeTab: string;
    handleClickTab: (val: string) => void;
    has_waiver?: IAppointment['has_waiver'];
    deposit_request?: IAppointment['deposit_request'];
    disabled?: boolean;
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        display: 'none'
    },
    '& .MuiTabs-flexContainer': {
        borderBottom: 'none',
        justifyContent: 'space-around'
    },

    '& .MuiTab-root': {
        justifyContent: 'flex-start',
        minHeight: 'auto',
        color: theme.palette.grey[800],
        textTransform: 'none',
        borderRadius: '8px',

        '&.Mui-selected': {
            color: theme.palette.grey[800],
            background: theme.palette.primary.light,

            '& > .MuiSvgIcon-root': {
                color: theme.palette.grey[800]
            }
        },

        '& > .MuiSvgIcon-root': {
            color: theme.palette.grey[400]
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
            fontSize: 0,

            '& .MuiSvgIcon-root': {
                margin: 0
            }
        }
    },

    '&.forceMobile .MuiTab-root': {
        minWidth: 'auto',
        fontSize: 0,

        '& .MuiSvgIcon-root': {
            margin: 0
        }
    }
}));

const Menu = ({ activeTab, handleClickTab, has_waiver, deposit_request, disabled }: MenuProps) => {
    const { user } = useAuth();
    const productsDisabled = !user?.currentCompany.settings?.appointments.use_products;

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        handleClickTab(newValue);
    };

    const forceMobile = useForceMobileLayoutContext();
    const matchMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md')) || forceMobile;

    return (
        <Box mx={2}>
            <StyledTabs
                className={forceMobile ? 'forceMobile' : undefined}
                orientation={matchMd ? 'horizontal' : 'vertical'}
                onChange={handleChange}
                value={activeTab}
            >
                <Tab value="details" label="Запись" icon={<Segment />} iconPosition="start" disabled={disabled} />
                <Tab
                    value="customer-info"
                    label={
                        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }} spacing={0.5}>
                            Данные клиента <WaiverSignIndicator waiver={has_waiver} />
                        </Stack>
                    }
                    icon={<PersonOutlineOutlined />}
                    iconPosition="start"
                    disabled={disabled}
                />
                {!productsDisabled && (
                    <Tab value="products" label="Products" icon={<LocalMallOutlinedIcon />} iconPosition="start" disabled={disabled} />
                )}
                <Tab
                    value="payments"
                    label={
                        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }} spacing={0.5}>
                            Платежи <AppointmentDepositOpenedIndicator depositRequest={deposit_request} />
                        </Stack>
                    }
                    icon={<PaymentOutlined />}
                    iconPosition="start"
                    disabled={disabled}
                />
            </StyledTabs>
        </Box>
    );
};

export default Menu;
