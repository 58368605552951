import { FC } from 'react';
import EntityDrawerContent from '../../entity-drawer-layout/EntityDrawerContent';
import { Box, Button } from '@mui/material';
import EntityDrawerActions from '../../entity-drawer-layout/EntityDrawerActions';
import { RecurringEventFormType } from './RecurringEventForm';
import { useFormik } from 'formik';
import RecurringEventCustomers from './RecurringEventCustomers';

type RecurringEventCustomersFormProps = {
    defaults: RecurringEventFormType;
    onSubmit: (formData: RecurringEventFormType) => void;
    onClose?: () => void;
};

const RecurringEventCustomersForm: FC<RecurringEventCustomersFormProps> = ({ defaults, onSubmit, onClose }) => {
    const { values, handleSubmit, setFieldValue } = useFormik<RecurringEventFormType>({
        initialValues: defaults,
        enableReinitialize: true,
        onSubmit
    });

    return (
        <>
            <EntityDrawerContent>
                <form id="recurring-event-customer-form" noValidate onSubmit={handleSubmit} autoComplete="off">
                    <RecurringEventCustomers value={values.customers} onChange={(v) => setFieldValue('customers', v)} />
                </form>
            </EntityDrawerContent>
            <EntityDrawerActions>
                <Button color="primary" variant="text" className="forcedBg" onClick={onClose}>
                    Отмена
                </Button>
                <Button color="primary" variant="contained" type="submit" form="recurring-event-customer-form">
                    Сохранить
                </Button>
            </EntityDrawerActions>
            <Box id="newCustomerFormContainer" />
        </>
    );
};

export default RecurringEventCustomersForm;
