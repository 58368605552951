import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MomentAdapter from '@mui/lab/AdapterMoment';
import StaticDatePicker, { StaticDatePickerProps } from '@mui/lab/StaticDatePicker';
import { Moment } from 'moment-timezone';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    datepicker: {
        '& div[role=presentation]': {
            cursor: 'default'
        },
        '& > div': {
            minWidth: 'auto'
        },
        '& > div > div > div': {
            maxWidth: '500px',
            width: '100%'
        },
        '& .MuiCalendarPicker-root': {
            maxWidth: '700px',
            width: '100%',
            '& > div:first-of-type': {
                marginTop: 0,
                padding: 0,
                justifyContent: 'flex-end',
                [theme.breakpoints.down('sm')]: {
                    margin: '0 16px'
                }
            },
            '& > div:last-of-type > div:first-of-type': {
                justifyContent: 'space-between'
            },

            '@media(max-width: 375px)': {
                width: 'auto'
            }
        },
        // Days of Week wrapper selector
        '& .MuiCalendarPicker-viewTransitionContainer > div > div': {
            justifyContent: 'space-between'
        },
        '& .PrivatePickersSlideTransition-root': {
            minHeight: '230px'
        },
        '& .PrivatePickersSlideTransition-root > div > div[role="row"]': {
            justifyContent: 'space-between'
        },

        '& .MuiButtonBase-root.Mui-selected': {
            backgroundColor: theme.palette.widget.green
        }
    }
}));

const WidgetStaticCalendarPicker = (props: StaticDatePickerProps<Moment>) => {
    const classes = useStyles();

    return (
        <Box className={classes.datepicker} id="calendar-date-picker">
            <LocalizationProvider dateAdapter={MomentAdapter}>
                <StaticDatePicker<Moment> {...props} />
            </LocalizationProvider>
        </Box>
    );
};

export default WidgetStaticCalendarPicker;
