import { IEmployee } from './IEmployee';
import { ILocation } from './ILocation';
import { ImageData } from './IImage';
import { IWaiver, WaiverSignType } from './ICompany';

export enum ServiceLocationType {
    Business = 'business',
    // External = 'external',
    Virtual = 'virtual'
}

export interface IWeekDaySchedule {
    id: number;
    enable: boolean;
}

export interface IServiceMaterials {
    name: string;
    units: string;
    price_per_unit: number;
}

type ServicePolicy = {
    enabled: boolean;
    policy_title?: string | null;
    policy_text?: string | null;
};

type ServiceConfirmation = {
    from_customer: boolean;
    from_employee?: boolean | null;
};

export interface IServicePivotMaterials extends IServiceMaterials {
    amount: number;
}

export type ServiceQuestionType = {
    name: string;
    type: string;
    title: string;
    choices?: string[] | null;
    show_other_item?: boolean | null;
    show_none_item?: boolean | null;
    answer?: string | null;
};

export type ServiceAnswerType = {
    title: string;
    answer: string;
};

type ServicePivotType = {
    id: number;
    payment_type: PaymentType;
    price: number | null;
    prepay: number | null;
    use_materials: boolean;
    materials: IServicePivotMaterials;
    widget_answers?: ServiceAnswerType[] | null;
};

export interface IService {
    id: number;
    name: string;
    duration: number | null;
    interval: number | null;
    is_reschedule_enabled: boolean;
    rescheduling_interval: number;
    pivot?: ServicePivotType;
    payment_type: PaymentType;
    price: number | null;
    price_from: boolean;
    prepay: number | null;
    locations?: ILocation[];
    employees?: IEmployee[];
    deleted_at?: string | null;
    images: ImageData[];
    description: string | null;
    schedule: IWeekDaySchedule[];
    sorting_order: number;
    location_type: ServiceLocationType;
    is_private: boolean;
    slug: string;
    confirmation_note: string;
    isActive?: boolean;
    advance_booking_buffer?: number;
    is_waiver_enabled?: boolean;
    waivers?: IWaiver[];
    staff_autoassign: boolean;
    use_materials?: boolean;
    materials?: IServiceMaterials | null;
    use_rebook_reminder: boolean;
    use_service_policy: {
        enabled: boolean;
        policy_title?: string | null;
        policy_text?: string | null;
    };
    widget_questions?: ServiceQuestionType[] | null;
    requires_confirmation: {
        from_customer: boolean;
        from_employee?: boolean | null;
    };
    categories?: IServiceCategory[] | null;
}

export enum PaymentType {
    Free = 'free',
    Paid = 'paid',
    Prepaid = 'prepay'
}

export enum PaymentTypeNames {
    Free = 'Бесплатно',
    Paid = 'Платная',
    Prepaid = 'Депозит'
}

export interface ServiceOrderPayload {
    ordering: IServiceOrder[];
}

export interface IServiceOrder {
    id: number;
    sorting_order: number;
}

export type ServicePayloadType = {
    name: string;
    duration: number;
    interval: number;
    payment_type: PaymentType;
    price: number | null;
    prepay: number | null;
    locations: number[];
    employees: number[];
    is_private: boolean;
    images: Array<ImageData | string>;
    description: string | null;
    is_reschedule_enabled: boolean;
    schedule: IWeekDaySchedule[];
    rescheduling_interval: number;
    location_type: ServiceLocationType;
    confirmation_note: string | null;
    advance_booking_buffer?: number | null;
    staff_autoassign: boolean;
    is_waiver_enabled: boolean;
    waivers: number[];
    waivers_sign_type?: WaiverSignType;
    use_materials: boolean;
    materials: IServiceMaterials | null;
    use_rebook_reminder: boolean;
    use_service_policy: ServicePolicy;
    requires_confirmation: ServiceConfirmation;
    custom_success_url?: string | null;
    category_ids?: number[] | null;
};

export type ServiceFormType = Omit<ServicePayloadType, 'duration' | 'interval' | 'waivers' | 'payment_type'> & {
    duration: number | null;
    interval: number | null;
    waivers: number | null;
    payment_type: PaymentType | null;
};

export interface IServiceCategory {
    id: number;
    name: string;
    custom_css?: string | null;
    services: IService[];
    sorting_order?: number | null;
}

export type ServiceCategoryPayload = { name: string; custom_css?: string | null };
export type ServiceCategoryOrdering = { id: number; sorting_order: number };
