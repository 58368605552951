import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IService, IServiceCategory } from '../../models/IService';
import ServiceButton from '../../views/scheduling-widget/widget-wizard/forms/service-step/ServiceButton';
import { getNewMultiServicesValue } from '../../utils/services';
import { Box, useMediaQuery } from '@mui/material';
import ServiceInfoDialog from '../../views/scheduling-widget/widget-wizard/forms/service-step/ServiceInfoDialog';
import ServiceInfo from '../../views/scheduling-widget/widget-wizard/forms/service-step/ServiceInfo';
import WidgetButton from '../../views/scheduling-widget/components/WidgetButton';
import StyledBackButton from '../../views/scheduling-widget/components/StyledBackButton';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

interface IWidgetServiceSelectProps {
    options: IService[];
    value: IService[];
    onChange: (v: IService[]) => void;
    useMultiservices?: boolean;
    disableFn?: (s: IService) => boolean;
    openInfoCb?: () => void;
    closeInfoCb?: () => void;
}

const WidgetServiceSelect: FC<IWidgetServiceSelectProps> = ({
    options,
    value,
    onChange,
    useMultiservices = false,
    disableFn,
    openInfoCb,
    closeInfoCb
}) => {
    const matchDownMd = useMediaQuery('(max-width: 800px)');

    const [selectedCategory, setSelectedCategory] = useState<number | 'all' | null>(null);
    const [selectedService, setSelectedService] = useState<IService | null>(null);

    const usedCategories = useMemo(
        () =>
            options.reduce<IServiceCategory[]>((acc, current) => {
                const newCategories = current.categories?.filter(({ id }) => !acc.some((cat) => cat.id === id)) ?? [];
                if (newCategories.length) {
                    const combined = [...acc, ...newCategories];
                    const nullOrder = combined.length + 2;

                    return combined.sort((prev, next) => (prev.sorting_order ?? nullOrder) - (next.sorting_order ?? nullOrder));
                }

                return acc;
            }, []),
        [options]
    );

    const filteredServicesByCategory = useMemo(() => {
        if (selectedCategory === 'all') {
            return options;
        }
        return options.filter(({ categories }) => categories?.some((category) => category.id === selectedCategory));
    }, [options, selectedCategory]);

    const isSelected = useCallback((serviceId: number) => value.some(({ id }) => id === serviceId), [value]);

    const handleSelectService = useCallback(
        (arg: IService) => {
            if (isSelected(arg.id)) {
                const newValue = useMultiservices ? [...value].filter((s) => s.id !== arg.id) : [];
                onChange(newValue);
            } else {
                const ids = [...value, arg].map(({ id }) => id);
                const newIds = getNewMultiServicesValue(ids, options);
                const newValue = useMultiservices
                    ? options?.filter(({ id }) => newIds.includes(id)).sort((a, b) => newIds.indexOf(a.id) - newIds.indexOf(b.id))
                    : [arg];
                onChange(newValue);
            }
        },

        [isSelected, onChange, options, useMultiservices, value]
    );

    const handleDialogOpen = useCallback(
        (service: IService) => {
            setSelectedService(service);
            openInfoCb?.();
        },
        [openInfoCb]
    );

    const handleDialogClose = useCallback(() => {
        setSelectedService(null);
        closeInfoCb?.();
    }, [closeInfoCb]);

    const onChoose = useCallback(() => {
        if (selectedService) {
            handleSelectService(selectedService);
            handleDialogClose();
        }
    }, [handleDialogClose, handleSelectService, selectedService]);

    useEffect(() => {
        if (!selectedCategory && !usedCategories.length) {
            setSelectedCategory('all');
        }
    }, [selectedCategory, usedCategories.length]);

    if (selectedService && matchDownMd) {
        return <ServiceInfo service={selectedService} onClose={handleDialogClose} onChoose={onChoose} />;
    }

    return (
        <>
            {selectedCategory ? (
                <>
                    {!!usedCategories.length && (
                        <Box mb={2}>
                            <StyledBackButton variant="contained" startIcon={<ArrowBackIos />} onClick={() => setSelectedCategory(null)}>
                                Назад к списку категорий
                            </StyledBackButton>
                        </Box>
                    )}

                    {filteredServicesByCategory.map((service: IService) => (
                        <Box>
                            <ServiceButton
                                key={service.id}
                                service={service}
                                onChoose={() => {
                                    handleSelectService(service);
                                    handleDialogClose();
                                }}
                                isSelected={isSelected(service.id)}
                                handleClickInfo={() => {
                                    handleDialogOpen(service);
                                }}
                                disabled={disableFn ? disableFn(service) : undefined}
                            />
                        </Box>
                    ))}
                </>
            ) : (
                <>
                    {usedCategories.map((category) => (
                        <WidgetButton
                            onChoose={() => setSelectedCategory(category.id)}
                            name={category.name}
                            isSelected={false}
                            hideAvatar
                            customClassName={category.custom_css ?? undefined}
                        />
                    ))}
                    <WidgetButton onChoose={() => setSelectedCategory('all')} name="Показать все услуги" isSelected={false} hideAvatar />
                </>
            )}

            {!!selectedService && !matchDownMd && (
                <ServiceInfoDialog open onChoose={onChoose} onClose={handleDialogClose} service={selectedService} />
            )}
        </>
    );
};

export default WidgetServiceSelect;
