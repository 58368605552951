import { FC, useMemo } from 'react';
import { IConversationThread } from '../../models/IAppointment';
import { Card, CardContent, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import { format } from 'date-fns';
import { useTheme } from '@material-ui/core/styles';
import useGetUsernameById from '../../hooks/use-get-username-by-id';
import ChatAttachment from './ChatAttachment';

const ChatMessage: FC<{ thread: IConversationThread }> = ({ thread }) => {
    const theme = useTheme();
    const username = useGetUsernameById(thread.created_by_employee_id);
    const timestamp = format(new Date(thread.created_at), 'h:mm a, MM/dd/yyyy');

    const createdByType = thread.created_by?.type;
    const isMine = useMemo(() => createdByType !== 'customer', [createdByType]);

    const displayedUsername = useMemo(() => {
        if (thread.type === 'note') {
            return username ? `Note from ${username}` : 'Note';
        }
        return username;
    }, [username, thread.type]);

    const getCardBgColor = useMemo(() => {
        if (theme.palette.mode === 'dark') {
            return {
                bgcolor: 'grey.600',
                textColor: 'dark.900',
                bodyColor: 'dark.900'
            };
        }

        if (thread.type === 'note') {
            return {
                bgcolor: 'warning.light',
                textColor: 'warning.dark',
                bodyColor: ''
            };
        }

        if (isMine) {
            return {
                bgcolor: 'primary.light',
                textColor: 'primary.dark',
                bodyColor: ''
            };
        }

        return {
            bgcolor: 'secondary.light',
            textColor: 'secondary.dark',
            bodyColor: ''
        };
    }, [theme.palette.mode, thread.type, isMine]);

    return (
        <Card sx={{ maxWidth: '85%', alignSelf: isMine ? 'flex-end' : undefined, bgcolor: getCardBgColor.bgcolor }}>
            <CardContent
                sx={{
                    p: '16px',
                    pb: '16px !important',
                    width: 'fit-content',
                    ml: 'auto'
                }}
            >
                <Stack spacing={1}>
                    <Typography variant="body2" sx={{ fontWeight: 600, minHeight: '21px' }} color={getCardBgColor.textColor}>
                        {displayedUsername}
                    </Typography>
                    <Typography variant="body2" color={getCardBgColor.bodyColor}>
                        <div>{thread.body}</div>
                        {thread.attachments?.map((attachment) => (
                            <ChatAttachment attachment={attachment} key={attachment.file_url} />
                        ))}
                    </Typography>
                    <Typography align="right" variant="subtitle2" color={getCardBgColor.bodyColor}>
                        {timestamp}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default ChatMessage;
