import React, { useCallback, useEffect } from 'react';
import { map } from 'lodash';

// material-ui
import {
    Button,
    CardActions,
    CardContent,
    Divider,
    FormHelperText,
    Grid,
    Box,
    Switch,
    Hidden,
    InputAdornment,
    Typography
} from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, MenuItem, Select, useMediaQuery } from '@mui/material';

// project imports
import InputLabel from 'ui-component/extended/Form/InputLabel';
import { IService, IServiceMaterials, PaymentType, PaymentTypeNames, ServiceLocationType } from 'models/IService';
import locationAPI from 'services/LocationService';
import { ILocation } from 'models/ILocation';
import employeeAPI from 'services/EmployeeService';
import { IEmployee, UserRole } from 'models/IEmployee';
import DurationAutocomplete from './duration_autocomplete/DurationAutocomplete';
import OptimizedTextField from '../../ui-component/optimized-text-fields/OptimizedTextField';
import ImageUploader from 'ui-component/form/ImageUploader';
import EmployeeSelect from '../../ui-component/EmployeeSelect';
import LabelWithInfo from '../../ui-component/LabelWithInfo';
import serviceFormSchema from './service-form.schema';
import useServiceDefaults from './use-service-defaults';
import useExtendedFormik from '../../hooks/useExtendedFormik';
import usePlanName from '../../hooks/usePlanName';
import TwoColumnsSwitch from '../../ui-component/TwoColumnsSwitch';
import InfoTooltip from 'ui-component/InfoTooltip';
import ServiceCategorySelect from './elements/ServiceCategorySelect';

interface ServiceFormProps {
    service: IService;
    update: (service: IService) => void;
}

const LocationTypeLabels: Record<string, string> = {
    [ServiceLocationType.Business]: 'В локации',
    // [ServiceLocationType.External]: 'Выездная',
    [ServiceLocationType.Virtual]: 'Виртуальная'
};

const ServiceForm: React.FC<ServiceFormProps> = ({ service, update }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const defaultValues = useServiceDefaults(service);
    const planName = usePlanName();
    // get the last one of service images
    const initialPreview = service.images.length > 0 ? service.images[service.images.length - 1] : null;
    const { handleSubmit, values, touched, errors, handleBlur, handleChange, setFieldValue, setFieldTouched } = useExtendedFormik({
        enableReinitialize: true,
        validateOnBlur: true,
        initialValues: defaultValues,
        validationSchema: serviceFormSchema,
        onSubmit: (formData) => {
            update({
                ...formData,
                materials: formData.use_materials ? (formData.materials as IServiceMaterials) : null,
                // @ts-ignore
                locations: map(values.locations, 'id'),
                // @ts-ignore
                employees: map(values.employees, 'id'),
                // @ts-ignore
                waivers: values?.waivers ? [values.waivers] : []
            });
        }
    });

    const locations = locationAPI.useFetchAllLocationsQuery({});
    const employees = employeeAPI.useFetchAllEmployeesQuery({});

    useEffect(() => {
        if (!employees.isFetching && !locations.isFetching && employees.data && locations.data) {
            if (!service.id) {
                const employeesToFill = employees.data.data;
                setFieldValue('employees', employeesToFill);
                setFieldValue('locations', locations.data.data);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employees.isFetching, locations.isFetching]);

    const handleChangeLocation = (value: ILocation[]) => {
        setFieldValue('locations', value);
    };

    const handleChangeEmployee = (value: IEmployee[]) => {
        setFieldValue('employees', value);
    };

    const clearFormImage = () => {
        setFieldValue('images', []);
    };

    const handlePriceFieldChange = useCallback(
        (fieldName: string, value: string) => {
            setFieldTouched(fieldName, false);
            value = value
                .replace(/^\./, '')
                .replace(/[^\d.]/g, '')
                .replace(/(?:\.)\./, '')
                .replace(/(^\d*.\d{2})(\d*)/, '$1')
                .split('.')
                .filter((_, idx) => idx < 2)
                .join('.');
            setFieldValue(fieldName, value);
        },
        [setFieldTouched, setFieldValue]
    );

    if (locations.isFetching) return null;

    return (
        <form noValidate onSubmit={handleSubmit}>
            <CardContent sx={{ px: { xs: 0, sm: 2 }, pt: { xs: 0, sm: 2 } }}>
                <Grid container spacing={2} alignItems="center">
                    <ImageUploader
                        initialPreview={initialPreview}
                        setFieldValue={setFieldValue}
                        name="Изображение"
                        imageFieldName="images"
                        clearFormImage={clearFormImage}
                    />
                    <Grid item xs={12} sm={3} lg={4}>
                        <InputLabel horizontal>Название</InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                        <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                            <OptimizedTextField
                                placeholder="Название"
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={(event) => {
                                    handleChange(event);
                                    setFieldTouched('name', false);
                                }}
                                onBlur={handleBlur}
                            />
                            {touched.name && errors.name && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.name}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} lg={4}>
                        <InputLabel horizontal>Описание</InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                        <FormControl fullWidth error={Boolean(touched.description && errors.description)}>
                            <OptimizedTextField
                                fullWidth
                                id="description"
                                name="description"
                                rows={2}
                                multiline
                                placeholder="Описание"
                                value={values.description || ''}
                                onBlur={handleBlur}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e);
                                    setFieldTouched('description', false);
                                }}
                                error={Boolean(touched.description && errors.description)}
                                helperText={touched.description && errors.description}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} lg={4}>
                        <LabelWithInfo label="Длительность" infoText="Сколько времени занимает оказание услуги" />
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                        <FormControl fullWidth error={Boolean(touched.duration && errors.duration)}>
                            <DurationAutocomplete
                                name="service-duration"
                                min={10}
                                value={String(values.duration)}
                                onBlur={(e) => {
                                    setFieldTouched('duration');
                                    handleBlur(e);
                                }}
                                setDuration={(newValue) => {
                                    setFieldValue('duration', newValue);
                                }}
                            />
                            {touched.duration && errors.duration && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.duration}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={3} lg={4}>
                        <LabelWithInfo
                            label="Буфер времени после услуги"
                            infoText="Количество времени требуемое на подготовку рабочего места к следующему клиенту."
                        />
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                        <FormControl fullWidth error={Boolean(touched.interval && errors.interval)}>
                            <DurationAutocomplete
                                name="service-interval"
                                value={String(values.interval)}
                                onBlur={(e) => {
                                    handleBlur(e);
                                    setFieldTouched('interval');
                                }}
                                setDuration={(newValue) => {
                                    // setFieldTouched('interval', false);
                                    setFieldValue('interval', newValue);
                                }}
                            />
                            {touched.interval && errors.interval && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.interval}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid> */}
                    <Grid item xs={12} sm={3} lg={4}>
                        <LabelWithInfo label="Категории услуги" />
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                        <ServiceCategorySelect
                            value={values.category_ids ?? []}
                            onChange={(v) => setFieldValue('category_ids', v)}
                            id="category_ids"
                            name="category_ids"
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <>
                        <Grid item xs={12} sm={3} lg={4}>
                            <LabelWithInfo label="Цена, руб." infoText="Полная стоимость услуги, включая депозит." />
                        </Grid>
                        <Grid item xs={4} sm={2} lg={1}>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1">От</Typography>
                                <Switch
                                    checked={!!values.price_from}
                                    name="price_from"
                                    value={!!values.price_from}
                                    onChange={() => {
                                        setFieldValue('price_from', !values.price_from);
                                    }}
                                />
                            </Box>
                            {/* <TwoColumnsSwitch
                                    value={values.price_from}
                                    fieldName="price_from"
                                    setFieldValue={setFieldValue}
                                    label="От"
                                /> */}
                        </Grid>
                        <Grid item xs={8} sm={7} lg={5}>
                            <FormControl fullWidth error={Boolean(touched.price && errors.price)}>
                                <TextField id="payment_type" type="hidden" value="paid" name="payment_type" />
                                <TextField
                                    id="price"
                                    placeholder="Цена"
                                    type="string"
                                    value={values.price || ''}
                                    name="price"
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    onChange={(event) => {
                                        handlePriceFieldChange('price', event.target.value);
                                    }}
                                    InputProps={{
                                        endAdornment: ' ₽'
                                    }}
                                />
                                {touched.price && errors.price && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.price}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </>

                    {/* <TwoColumnsSwitch
                        value={values.use_materials}
                        fieldName="use_materials"
                        setFieldValue={setFieldValue}
                        label="Материалы"
                    />

                    {values.use_materials ? (
                        <>
                            <Grid item xs={12} sm={3} lg={4}>
                                <InputLabel htmlFor="materials.name">Название</InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={9} lg={6}>
                                <TextField
                                    id="materials.name"
                                    name="materials.name"
                                    value={values.materials.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(touched.materials?.name && errors.materials?.name)}
                                    helperText={Boolean(touched.materials?.name) && errors.materials?.name}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} lg={4}>
                                <InputLabel htmlFor="materials.units">Единицы измерения</InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={9} lg={6}>
                                <TextField
                                    id="materials.units"
                                    name="materials.units"
                                    value={values.materials.units}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(touched.materials?.units && errors.materials?.units)}
                                    helperText={!!touched.materials?.units && errors.materials?.units}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} lg={4}>
                                <InputLabel>Стоимость одной единицы, руб.</InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={9} lg={6}>
                                <NumberFormat
                                    value={values.materials.price_per_unit}
                                    customInput={FocusTextField}
                                    id="materials.price_per_unit"
                                    name="materials.price_per_unit"
                                    InputProps={{
                                        endAdornment: "₽"
                                    }}
                                    decimalScale={2}
                                    onValueChange={(v) => {
                                        setFieldValue('materials.price_per_unit', v.floatValue);
                                    }}
                                    onBlur={handleBlur}
                                    fullWidth
                                    error={Boolean(touched.materials?.price_per_unit && errors.materials?.price_per_unit)}
                                    helperText={!!touched.materials?.price_per_unit && errors.materials?.price_per_unit}
                                />
                            </Grid>
                        </>
                    ) : null} */}

                    <Grid item xs={12} sm={3} lg={4}>
                        <LabelWithInfo
                            label="Место оказания услуги"
                            infoText="Выберите Виртуальная, чтобы к записям добавлялась ссылка на видеозвонок с клиентом."
                        />
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                        <TextField
                            id="location_type"
                            name="location_type"
                            value={values.location_type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.materials?.units && errors.materials?.units)}
                            helperText={!!touched.location_type && errors.location_type}
                            fullWidth
                            select
                        >
                            {Object.values(ServiceLocationType).map((val) => (
                                <MenuItem value={val} key={val}>
                                    {LocationTypeLabels[val] ?? val}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {/* <TwoColumnsSwitch
                        value={values.is_private}
                        fieldName="is_private"
                        setFieldValue={setFieldValue}
                        label="Скрытая услуга"
                        labelDecoration={
                            <InfoTooltip text="Включите, если не хотите, чтобы услуга была общедоступна для брони через виджет. Вы сможете создать специальную ссылку для бронирования данной услуги на странице настроек виджета." />
                        }
                    /> */}
                    {/* <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={3} lg={4}>
                                <LabelWithInfo
                                    label="График доступности услуги"
                                    infoText="Если конкретная локация не работает в выбранный день, услуга все равно будет недоступна для брони в этот день, даже если включить ее здесь. Убедитесь что настройки графика работы согласованы в настройках локаций, услуг и сотрудников."
                                />
                            </Grid>
                            <Grid item xs={12} sm={9} lg={6} pl={{ xs: 0, sm: 1 }}>
                                <WeekDaysSchedule
                                    schedule={values.schedule}
                                    onChange={(updatedSchedule) => setFieldValue('schedule', updatedSchedule)}
                                />
                            </Grid>
                        </Grid>
                    </Grid> */}
                    {/* <TwoColumnsSwitch
                        value={values.is_reschedule_enabled}
                        fieldName="is_reschedule_enabled"
                        setFieldValue={setFieldValue}
                        label="Разрешить изменение брони"
                        labelDecoration={<InfoTooltip text="Разрешить клиентам менять день и время брони через виджет." />}
                    />
                    {values.is_reschedule_enabled && (
                        <>
                            <Grid item xs={12} sm={3} lg={4}>
                                <LabelWithInfo
                                    label="Буфер времени для изменения брони"
                                    infoText="Минимальное время до начала записи, когда разрешается ее изменение через виджет."
                                />
                            </Grid>
                            <Grid item xs={12} sm={9} lg={6}>
                                <FormControl fullWidth error={Boolean(touched.rescheduling_interval && errors.rescheduling_interval)}>
                                    <OptimizedTextField
                                        type="number"
                                        id="rescheduling_interval"
                                        name="rescheduling_interval"
                                        value={values.rescheduling_interval}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                const value = parseInt(e.target.value, 10);
                                                setFieldValue('rescheduling_interval', value);
                                                return;
                                            }
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            startAdornment: (
                                                <>
                                                    <InputAdornment position="start">Минуты</InputAdornment>
                                                    <Divider sx={{ height: 28, m: 0.5, mr: 1.5 }} orientation="vertical" />
                                                </>
                                            ),
                                            endAdornment: !isMobile ? (
                                                <>
                                                    <InputAdornment position="end">
                                                        Используйте &quot;0&quot; для отмены ограничений.
                                                    </InputAdornment>
                                                </>
                                            ) : null
                                        }}
                                    />
                                    {touched.rescheduling_interval && errors.rescheduling_interval && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {' '}
                                            {errors.rescheduling_interval}{' '}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </>
                    )}
                    <TwoColumnsSwitch
                        value={values.use_rebook_reminder}
                        fieldName="use_rebook_reminder"
                        setFieldValue={setFieldValue}
                        label="Напоминание о следующем бронировании"
                    /> */}
                    <Grid item xs={12} sm={3} lg={4}>
                        <LabelWithInfo
                            label="Минимальное время до брони"
                            infoText="Минимальное количество времени до желаемого времени начала записи, когда можно совершить онлайн бронирование."
                        />
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                        <FormControl fullWidth error={Boolean(touched.advance_booking_buffer && errors.advance_booking_buffer)}>
                            <OptimizedTextField
                                id="advance_booking_buffer"
                                type="number"
                                name="advance_booking_buffer"
                                value={values.advance_booking_buffer}
                                onBlur={(e) => {
                                    handleBlur(e);
                                    setFieldTouched('advance_booking_buffer');
                                }}
                                onChange={handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <>
                                            <InputAdornment position="start" sx={{ pl: '3px', pr: 0 }}>
                                                Минуты
                                            </InputAdornment>
                                            <Divider sx={{ height: 28, m: 0.5, mr: 1.5 }} orientation="vertical" />
                                        </>
                                    ),
                                    endAdornment: !isMobile ? (
                                        <>
                                            <InputAdornment position="end">
                                                Используйте &quot;0&quot; для отмены ограничений.
                                            </InputAdornment>
                                        </>
                                    ) : null
                                }}
                            />
                            {touched.advance_booking_buffer && errors.advance_booking_buffer && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {' '}
                                    {errors.advance_booking_buffer}{' '}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    {planName !== 'single user' && (
                        <>
                            <Grid item xs={12} sm={3} lg={4}>
                                <LabelWithInfo label="Локации" infoText="Локации, в которых оказывается услуга." />
                            </Grid>
                            <Grid item xs={12} sm={9} lg={6}>
                                <FormControl fullWidth error={Boolean(touched.locations && errors.locations)}>
                                    {locations.data && (
                                        <>
                                            <Autocomplete
                                                multiple
                                                id="checkboxes-tags-locations"
                                                options={locations.data.data}
                                                value={values.locations}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.name}
                                                onBlur={(e) => {
                                                    setFieldTouched('locations');
                                                    handleBlur(e);
                                                }}
                                                onChange={(e, value) => {
                                                    setFieldTouched('locations');
                                                    handleChangeLocation(value);
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                                        {option.name}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder={values.locations?.length ? undefined : 'Локации'} />
                                                )}
                                            />
                                            {touched.locations && errors.locations && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {' '}
                                                    {errors.locations}{' '}
                                                </FormHelperText>
                                            )}
                                        </>
                                    )}
                                </FormControl>
                            </Grid>
                        </>
                    )}
                    {planName !== 'single user' && (
                        <>
                            <Grid item xs={12} sm={3} lg={4}>
                                <LabelWithInfo
                                    label="Персонал"
                                    infoText="Сотрудники, оказывающие данную услугу. Не забудьте также добавить сотрудников к желаемой локации, иначе клиенты не увидят их в виджете."
                                />
                            </Grid>
                            <Grid item xs={12} sm={9} lg={6}>
                                <FormControl fullWidth error={Boolean(touched.employees && errors.employees)}>
                                    <EmployeeSelect
                                        employees={employees?.data?.data || []}
                                        onChange={handleChangeEmployee}
                                        value={values.employees}
                                        touched={touched}
                                        errors={errors}
                                        setFieldTouched={setFieldTouched}
                                        handleBlur={handleBlur}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    )}

                    <TwoColumnsSwitch
                        value={values.requires_confirmation.from_customer}
                        fieldName="requires_confirmation.from_customer"
                        setFieldValue={setFieldValue}
                        label="Запрашивать подтверждение у клиента"
                        labelDecoration={
                            <InfoTooltip text="Если включено, записи на эту услугу, созданные вами вручную, будут иметь статус 'Не подтверждено', а клиент получит запрос на подтверждение на указанный email адрес." />
                        }
                    />

                    <TwoColumnsSwitch
                        value={values.requires_confirmation.from_employee}
                        fieldName="requires_confirmation.from_employee"
                        setFieldValue={setFieldValue}
                        label="Запрашивать подтверждение исполнителя"
                        labelDecoration={
                            <InfoTooltip text="Если включено, онлайн записи клиентов на эту услугу будут создаваться в статусе 'Не подтверждено'. Клиент получит подтверждение на указанный email адрес, только после того, как вы поменяете статус записи на 'Подтверждено'." />
                        }
                    />

                    {/* <TwoColumnsSwitch
                        value={values.staff_autoassign}
                        fieldName="staff_autoassign"
                        setFieldValue={setFieldValue}
                        label="Автоназначение исполнителя"
                        labelDecoration={
                            <InfoTooltip text="При включении шаг выбора исполнителя в виджете отсутствует, и запись случайным образом назначается на доступных сотрудников." />
                        }
                    /> */}
                    {/* <Grid item xs={12} sm={3} lg={4}>
                        <LabelWithInfo
                            label="Текст подтверждения"
                            infoText="Это сообщение включено в email с подтверждением записи, отправляемым клиенту. Данная опция переопределяет глобальную настройку для компании."
                        />
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                        <FormControl fullWidth error={Boolean(touched.confirmation_note && errors.confirmation_note)}>
                            <OptimizedTextField
                                fullWidth
                                id="confirmation_note"
                                name="confirmation_note"
                                rows={2}
                                multiline
                                placeholder="Текст подтверждения"
                                value={values.confirmation_note || ''}
                                onBlur={handleBlur}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e);
                                    setFieldTouched('confirmation_note', false);
                                }}
                                error={Boolean(touched.confirmation_note && errors.confirmation_note)}
                                helperText={touched.confirmation_note && errors.confirmation_note}
                            />
                        </FormControl>
                    </Grid> */}
                </Grid>
            </CardContent>

            <Divider />
            <CardActions sx={{ px: { xs: 0, sm: 2 } }}>
                <Button type="submit" variant="contained" color="primary">
                    Сохранить
                </Button>
            </CardActions>
        </form>
    );
};

export default ServiceForm;
