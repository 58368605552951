import { useEffect, useState } from 'react';
import { UploadableFile } from '../ui-component/file-uploader-preview/AttachmentsUpload';
import { staticImageDataToUploadableFile } from '../utils/functions/uploading-images-helpers';
import { ImageData } from '../models/IImage';

export interface IAttachmentFunctionsReturn {
    attachments: UploadableFile[];
    setAttachments: (v: UploadableFile[]) => void;
    attachmentsIdsToDelete: Array<number | string>;
    setAttachmentsIdsToDelete?: (v: Array<number | string>) => void;
    attachmentError?: string;
    setAttachmentError: (v: string) => void;
}

const useAttachmentFunctions = (input: ImageData[] = [], entityKey?: number) => {
    const [attachments, setAttachments] = useState<UploadableFile[]>([]);
    const [attachmentsIdsToDelete, setAttachmentsIdsToDelete] = useState<(number | string)[]>([]);
    const [attachmentError, setAttachmentError] = useState('');

    useEffect(() => {
        if (input.length) {
            setAttachments(staticImageDataToUploadableFile(input));
        }
    }, [input]);

    return {
        attachments,
        setAttachments,
        attachmentsIdsToDelete,
        setAttachmentsIdsToDelete,
        attachmentError,
        setAttachmentError
    };
};

export default useAttachmentFunctions;
