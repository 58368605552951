import { Fragment } from 'react';
import { ListItemButton, ListItemText, Stack } from '@mui/material';
import HeaderNavGroup from './components/HeaderNavGroup';
import { Link } from 'react-router-dom';
import commonNavBtnSx from './components/common-nav-btn-sx';
import HeaderNavItemIcon from './components/HeaderNavItemIcon';
import useAvailableMenuItems from '../../menu-items/use-available-menu-items';

const HeaderNavigation = () => {
    const { filteredItems } = useAvailableMenuItems();
    return (
        <Stack direction="row">
            {filteredItems.map((item) => (
                <Fragment key={item.id}>
                    {item.title ? (
                        <HeaderNavGroup item={item} />
                    ) : (
                        <>
                            {item.children.map((child) => (
                                <ListItemButton key={child.id} component={Link} to={child.url} sx={commonNavBtnSx}>
                                    <HeaderNavItemIcon Icon={child.icon ?? undefined} />
                                    <ListItemText>{child.title}</ListItemText>
                                </ListItemButton>
                            ))}
                        </>
                    )}
                </Fragment>
            ))}
        </Stack>
    );
};

export default HeaderNavigation;
