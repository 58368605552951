import { useEffect, useState, useCallback } from 'react';

// material-ui
import { Stack, Box, useMediaQuery, Theme } from '@material-ui/core';
import Transitions from '../../../ui-component/extended/Transitions';

// project imports
import StepContent from './StepContent';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { BookingData, BookingWidgetAnswer, WizardStates } from './types';
import appointmentWidgetAPI from '../../../services/WidgetService';
import BookingSummary from './BookingSummary';
import { UploadableFile } from '../../../ui-component/file-uploader-preview/AttachmentsUpload';
import { startSubmitting } from 'store/slices/SubmittingSlice';
import { IEmployee } from '../../../models/IEmployee';
import WidgetStepper from '../components/WidgetStepper';
import { IWidgetCompany } from '../../../models/ICompany';
import useSteps from './hooks/useSteps';
import { setCoupon, setCouponError, setCreatedUuid, setNoShowDeposit } from '../../../store/slices/widgetSlice';
import moment from 'moment-timezone';
import WidgetColumnsLayout from '../../../ui-component/widgets/WidgetColumnsLayout';
import { StepsContextProvider } from '../steps-context/StepsContext';
import WidgetNav from './WidgetNav';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import { /* isAllServiceVirtual, */ isServicesAutoAssignEnabled } from '../../../utils/services';
import useWidgetDefaults from '../hooks/useWidgetDefaults';
import { createPortal } from 'react-dom';
import { useSearchParams } from 'react-router-dom';
import SchedulingWidgetFinalize from '../../../ui-component/widgets/payments/SchedulingWidgetFinalize';
import StripeWrapper from '../../../ui-component/widgets/payments/StripeWrapper';
import useRwgTokenOps from '../../../hooks/use-rwg-token-ops';
import useSubmitBookingWidget from '../../../hooks/widgets/use-submit-booking-widget';
import useServicesSurvey from '../../../hooks/use-services-survey';

interface WidgetWizardProps {
    company_slug: string;
    matchDownMd: boolean;
    scrollToTop: () => void;
    data: IWidgetCompany;
    query: string;
}

const WidgetWizard = ({ company_slug, matchDownMd, scrollToTop, data, query }: WidgetWizardProps) => {
    const { saveToken, sendAnalytics } = useRwgTokenOps();
    const { onSubmitBookingWidget } = useSubmitBookingWidget();

    const defaults = useWidgetDefaults(query, data);
    const dispatch = useAppDispatch();
    const { coupon } = useAppSelector((store) => store.widget);

    const [activeStep, setActiveStep] = useState(0);
    const [errorIndex, setErrorIndex] = useState<number | null>(null);
    const [submitted, setSubmitted] = useState(false);
    const [stepsProgress, setStepsProgress] = useState<number[]>([]);
    // data states
    const [serviceData, setServiceData] = useState<WizardStates['serviceData']>([]);
    const [locationData, setLocationData] = useState<WizardStates['locationData']>(null);
    const [providerData, setProviderData] = useState<WizardStates['providerData']>(null);
    const [isAnyProvider, setIsAnyProvider] = useState(false);
    const [dateData, setDateData] = useState<WizardStates['dateData']>(null);
    const [userData, setUserData] = useState<WizardStates['userData']>(null);
    const [attachments, setAttachments] = useState<UploadableFile[]>([]);
    // store for deleted attachments ID's - they will be deleted on-submit
    const [attachmentsIdsToDelete, setAttachmentsIdsToDelete] = useState<(number | string)[]>([]);
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const [hideNav, setHideNav] = useState<boolean>(false);
    const [timezone, setTimezone] = useState<string>(moment.tz.guess());
    const [widgetAnswers, setWidgetAnswers] = useState<BookingWidgetAnswer[] | null>(null);

    const { steps, stepsLength } = useSteps({
        staff_autoassign: isServicesAutoAssignEnabled(serviceData),
        oneProvider: data.filteredEmployees?.length === 1,
        needSurvey: serviceData.some((s) => s.widget_questions && s.widget_questions.length > 0)
    });

    // Slots source
    const [searchParams] = useSearchParams();
    const slots_request_source = searchParams.get('slots_request_source');

    const payment_intent_client_secret = searchParams.get('payment_intent_client_secret');

    const setService = useCallback(
        (service: WizardStates['serviceData']) => {
            setServiceData(service);
            dispatch(setNoShowDeposit({ required: false, amount: 0, sales_tax_amount: null }));
        },
        [dispatch]
    );

    useEffect(() => {
        const tz = locationData?.time_zone || 'UTC';

        /* if (isAllServiceVirtual(serviceData)) {
            tz = moment.tz.guess();
        } */

        setTimezone(tz);
    }, [locationData, serviceData]);

    useEffect(() => {
        scrollToTop();
    }, [activeStep, scrollToTop]);

    const substituteProgress = (step: WizardStates['step']) => {
        const index = stepsProgress.findIndex((progress) => progress === step);
        setStepsProgress(stepsProgress.slice(0, index + 1));
    };

    const addProgress = useCallback(
        (step: WizardStates['step']) => {
            setStepsProgress([...stepsProgress, step]);
        },
        [stepsProgress]
    );

    const handleNext = useCallback(() => {
        setActiveStep(activeStep + 1);
        setErrorIndex(null);
    }, [activeStep]);

    const handleBack = useCallback(() => {
        setActiveStep(activeStep - 1);
        if (isAnyProvider && activeStep - 1 === steps.date.index) {
            setProviderData({} as IEmployee);
        }
    }, [activeStep, isAnyProvider, steps.date.index]);

    const handleSetActiveStep = (stepIndex: number) => {
        setActiveStep(stepIndex);
        if (isAnyProvider && stepIndex === steps.date.index) {
            setProviderData({} as IEmployee);
        }
    };

    const rawResetWidget = () => {
        setStepsProgress([]);
        setServiceData([]);
        setLocationData(null);
        setProviderData(null);
        setDateData(null);
        setUserData(null);
        setAttachments([]);
        setActiveStep(0);
        setErrorIndex(null);
        setSubmitted(false);
        dispatch(appointmentWidgetAPI.util.invalidateTags(['Widget Slots']));
        dispatch(setCoupon());
        dispatch(setCouponError());
        dispatch(setCreatedUuid(null));
    };

    const resetWidget = () => {
        dispatch(
            openConfirmPopup({
                confirmText: 'Сбросить',
                cancelText: 'Отмена',
                text: 'Уверены что хотите сбросить данные?',
                onConfirm: rawResetWidget
            })
        );
    };

    const submitBooking = useCallback(
        (paymentDetails?: object | null, user?: WizardStates['userData'], images?: string[]) => {
            const userObj = user || userData;
            const hasProvider = isServicesAutoAssignEnabled(serviceData) ? true : Boolean(providerData);

            if (!dateData || !serviceData || !hasProvider || !locationData || !userObj) return;

            dispatch(startSubmitting());
            dispatch(setCouponError());
            const { address, ...restUser } = userObj;
            const bookingData: BookingData = {
                user: restUser,
                address: address ?? undefined,
                service_ids: serviceData?.map(({ id }) => id),
                location_id: locationData.id,
                employee_id: providerData?.id,
                start_at: dateData.start_at,
                end_at: dateData.end_at,
                note: userObj.note,
                images: images || attachments.map((file) => file.url || ''),
                time_zone: timezone,
                coupon_code: coupon?.code,
                slots_request_source: slots_request_source || undefined,
                widget_answers: widgetAnswers ?? undefined
            };

            if (paymentDetails) bookingData.payment_details = paymentDetails;

            onSubmitBookingWidget(company_slug, bookingData, (res) => {
                if (activeStep + 1 < stepsLength) handleNext();
                if (data.settings.appointments.waitlist.enabled) {
                    dispatch(setCreatedUuid(res.appointment.uuid));
                }
                setSubmitted(true);
                sendAnalytics();
            });
        },
        [
            userData,
            serviceData,
            providerData,
            dateData,
            locationData,
            dispatch,
            attachments,
            timezone,
            coupon,
            slots_request_source,
            onSubmitBookingWidget,
            company_slug,
            activeStep,
            stepsLength,
            handleNext,
            sendAnalytics,
            data.settings.appointments.waitlist.enabled,
            widgetAnswers
        ]
    );

    useEffect(() => {
        if (!providerData && data.filteredEmployees?.length === 1) {
            setProviderData(data.filteredEmployees[0]);
            setIsAnyProvider(false);
        }
    }, [data.filteredEmployees, providerData]);

    useEffect(() => {
        if (serviceData.length && isServicesAutoAssignEnabled(serviceData) && !providerData) {
            setIsAnyProvider(true);
        }
    }, [providerData, serviceData]);

    useEffect(() => {
        if (defaults.services.length && !serviceData.length) {
            setServiceData(defaults.services);
            addProgress(steps.service.index);
            handleNext();
        }
    }, [addProgress, defaults.services, handleNext, serviceData.length, steps.service.index]);

    const portalNode = document.querySelector('#cb_widget_actions_portal');

    useEffect(() => {
        if (locationData) {
            saveToken(locationData.id);
        }
    }, [locationData, saveToken]);

    const { convertServicesToSurveyAnswers, servicesHasMismatchWithAnswers } = useServicesSurvey();

    useEffect(() => {
        if (servicesHasMismatchWithAnswers(serviceData, widgetAnswers)) {
            setWidgetAnswers(convertServicesToSurveyAnswers(serviceData));
        }
    }, [convertServicesToSurveyAnswers, serviceData, servicesHasMismatchWithAnswers, widgetAnswers]);

    if (payment_intent_client_secret) {
        return (
            <StripeWrapper payment_intent_client_secret={payment_intent_client_secret}>
                <SchedulingWidgetFinalize company_slug={data.slug} payment_intent_client_secret={payment_intent_client_secret} />
            </StripeWrapper>
        );
    }

    return (
        <StepsContextProvider steps={steps} stepsLength={stepsLength}>
            <WidgetColumnsLayout
                Data={
                    <Stack sx={{ width: '100%' }}>
                        <WidgetStepper
                            step={activeStep}
                            handleSetActiveStep={handleSetActiveStep}
                            stepsProgress={stepsProgress}
                            submitted={submitted}
                        />
                        <Box sx={{ overflow: 'hidden', pt: 1 }}>
                            <StepContent
                                data={data}
                                step={activeStep}
                                addProgress={addProgress}
                                substituteProgress={substituteProgress}
                                errorIndex={errorIndex}
                                handleNext={handleNext}
                                handleBack={handleBack}
                                setErrorIndex={setErrorIndex}
                                services={data.filteredServices || data.services || []}
                                serviceData={serviceData}
                                setServiceData={setService}
                                locationData={locationData}
                                setLocationData={setLocationData}
                                providerData={providerData}
                                setProviderData={setProviderData}
                                isAnyProvider={isAnyProvider}
                                setIsAnyProvider={setIsAnyProvider}
                                dateData={dateData}
                                setDateData={setDateData}
                                userData={userData}
                                setUserData={setUserData}
                                attachments={attachments}
                                setAttachments={setAttachments}
                                // store for deleted attachments ID's - they will be deleted on-submit
                                attachmentsIdsToDelete={attachmentsIdsToDelete}
                                setAttachmentsIdsToDelete={setAttachmentsIdsToDelete}
                                isMobile={isMobile}
                                submitBooking={submitBooking}
                                resetWidget={resetWidget}
                                rawResetWidget={rawResetWidget}
                                submitted={submitted}
                                matchDownMd={matchDownMd}
                                setHideNav={setHideNav}
                                timezone={timezone}
                                setTimezone={setTimezone}
                                widgetAnswers={widgetAnswers}
                                setWidgetAnswers={setWidgetAnswers}
                            />
                        </Box>
                    </Stack>
                }
                Summary={
                    <Transitions type="slide" direction="left" in>
                        <BookingSummary
                            step={activeStep}
                            stepsProgress={stepsProgress}
                            setActiveStep={handleSetActiveStep}
                            serviceData={serviceData}
                            locationData={locationData}
                            providerData={providerData}
                            dateData={dateData}
                            userData={userData}
                            submitBooking={submitBooking}
                            submitted={submitted}
                            isAnyProvider={isAnyProvider}
                            company={data}
                            timezone={timezone}
                        />
                        {isMobile && portalNode ? (
                            createPortal(
                                <Box sx={{ background: '#fff', padding: 1 }}>
                                    <WidgetNav
                                        isMobile={isMobile}
                                        isInProgress={!!serviceData && activeStep > 0}
                                        step={activeStep}
                                        handleBack={handleBack}
                                        stepsProgress={stepsProgress}
                                        resetWidget={resetWidget}
                                        submitted={submitted}
                                        hide={hideNav}
                                    />
                                </Box>,
                                portalNode
                            )
                        ) : (
                            <WidgetNav
                                isMobile={isMobile}
                                isInProgress={!!serviceData && activeStep > 0}
                                step={activeStep}
                                handleBack={handleBack}
                                stepsProgress={stepsProgress}
                                resetWidget={resetWidget}
                                submitted={submitted}
                                hide={hideNav}
                            />
                        )}
                    </Transitions>
                }
                matchDownMd={matchDownMd}
            />
        </StepsContextProvider>
    );
};

export default WidgetWizard;
