import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import { setLicenseKey } from 'survey-core';
import 'assets/css/defaultV2.min.css';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { Box } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { ICreatorOptions, editorLocalization } from 'survey-creator-core';
import 'survey-creator-core/i18n/russian';
import { ServiceQuestionType } from '../../models/IService';

const creatorOptions: ICreatorOptions = {
    showJSONEditorTab: false,
    questionTypes: ['text', 'comment', 'dropdown'],
    allowModifyPages: false,
    showLogicTab: false,
    showPreviewTab: false,
    showPagesInPreviewTab: false,
    pageEditMode: 'single',
    showSurveyTitle: false
};

editorLocalization.currentLocale = 'ru';

const surveyJsKey = process.env.REACT_APP_SURVEY_JS_KEY ?? '';

export type SurveyData = { elements: ServiceQuestionType[] };

type ServiceSurveyProps = {
    data: SurveyData | null;
    onSaveData: (data: SurveyData) => void;
};

const ServiceSurvey: FC<ServiceSurveyProps> = ({ data, onSaveData }) => {
    const [creator, setCreator] = useState<SurveyCreator | null>(null);

    const initCreator = useCallback(() => {
        const translations = editorLocalization.getLocale('ru');
        translations.ed.surveyPlaceHolder = 'Нажмите на кнопку ниже, чтобы добавить вопрос.';
        translations.ed.placeholder = 'Выберите ответ...';
        translations.ed.otherItemText = 'Другое';
        translations.ed.noneItemText = 'Нет ответа';
        translations.ed.choices_Item = 'Ответ ';
        const creatorData = new SurveyCreator(creatorOptions);

        // @ts-ignore
        creatorData.toolboxLocation = 'none';
        creatorData.showSidebar = false;
        creatorData.allowModifyPages = false;

        creatorData.toolbar.actions = [...creatorData.toolbar.actions].filter(
            ({ id }) => id !== 'svd-grid-expand' && id !== 'svd-settings'
        );

        creatorData.JSON = data
            ? {
                  ...data,
                  elements: data.elements.map((q) => ({
                      ...q,
                      showNoneItem: q.show_none_item,
                      showOtherItem: q.show_other_item,
                      otherText: 'Другое',
                      noneText: 'Нет ответа'
                  }))
              }
            : null;

        creatorData.onQuestionAdded.add((state) => {
            creatorData.JSON = {
                ...state.JSON,
                elements: state.JSON.elements.map((el: any) => ({
                    ...el,
                    otherText: 'Другое',
                    noneText: 'Нет ответа'
                }))
            };
        });

        creatorData.onElementAllowOperations.add((_, options) => {
            options.allowChangeType = false;
            options.allowChangeInputType = false;
            options.allowChangeRequired = false;
            options.allowShowSettings = false;
        });

        creatorData.saveSurveyFunc = () => {
            const { elements, ...rest } = creatorData.JSON;
            const result = {
                elements: (elements ?? []).map((elem: any) => ({
                    ...elem,
                    choices: elem.choices
                        ? elem.choices.map((choice: any) => (typeof choice === 'string' ? choice : choice.text))
                        : undefined,
                    title: elem.title ?? elem.name,
                    show_none_item: elem.showNoneItem,
                    show_other_item: elem.showOtherItem
                })),
                ...rest
            };

            onSaveData(result);
        };

        setCreator(creatorData);
    }, [data, onSaveData]);

    useEffect(() => {
        initCreator();
        try {
            if (process.env.NODE_ENV === 'production') setLicenseKey(surveyJsKey);
        } catch {
            console.error('Survey JS license issue');
        }
    }, [initCreator]);

    return <Box sx={{ height: '100%', pb: 3 }}>{creator ? <SurveyCreatorComponent creator={creator} /> : 'Something went wrong'}</Box>;
};

export default ServiceSurvey;
