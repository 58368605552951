import { createContext, MouseEvent, ReactNode, TouchEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { addDays, closestTo, compareAsc, eachDayOfInterval, endOfMonth, isAfter, isBefore, isSameDay, startOfMonth } from 'date-fns';

export interface IShiftPickerProps {
    value: Date[];
    onChange: (v: Date[]) => void;
    excluded: Date[];
}

interface IShiftPickerProviderValue {
    value: Date[];
    open: boolean;
    currentMonth: Date;
    setCurrentMonth: (date: Date) => void;
    handleMonthChange: (m: Date) => void;
    handleDaySelect: () => void;
    nextEntry: Date | null;
    prevEntry: Date | null;
    getDayClasses: (day: Date) => string;
    handleMouseDown: (day: Date) => void;
    handleMouseEnter: (day: Date, e: MouseEvent) => void;
    handleTouchEnd: (day: Date, e: TouchEvent) => void;
    handleTouchMove: (e: TouchEvent) => void;
    isSelected: (day: Date) => boolean;
    isExcluded: (day: Date) => boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
}

interface IShiftPickerProviderProps {
    children: ReactNode;
    pickerProps: IShiftPickerProps;
}

const ShiftPickerContext = createContext<IShiftPickerProviderValue | undefined>(undefined);

const useShiftPickerContext = () => {
    const ctx = useContext(ShiftPickerContext);

    if (ctx) {
        return ctx;
    }

    console.error('Shift Provider Context must be called inside Shift Context Provider');
    return undefined;
};

const ShiftPickerContextProvider = ({ children, pickerProps }: IShiftPickerProviderProps) => {
    const { value, onChange, excluded } = pickerProps;
    const [open, setOpen] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectionStart, setSelectionStart] = useState<Date | null>(null);
    const [selectionEnd, setSelectionEnd] = useState<Date | null>(null);

    // Controlling open/close picker
    const onToggle = useCallback(() => setOpen((prev) => !prev), []);
    const onOpen = useCallback(() => setOpen(true), []);
    const onClose = useCallback(() => setOpen(false), []);

    // Handling month changes
    const handleMonthChange = (m: Date) => {
        setCurrentMonth(m);
        setSelectionStart(null);
        setSelectionEnd(null);
    };

    const nextEntry = useMemo(() => {
        const currentMonthEnd = endOfMonth(currentMonth);
        const nextEntries = value.filter((v) => isAfter(v, currentMonthEnd));

        return closestTo(currentMonthEnd, nextEntries);
    }, [currentMonth, value]);

    const prevEntry = useMemo(() => {
        const currentMonthStart = startOfMonth(currentMonth);
        const prevEntries = value.filter((v) => isBefore(v, currentMonthStart));

        return closestTo(currentMonthStart, prevEntries);
    }, [currentMonth, value]);

    const isSelected = useCallback((day: Date) => value.some((date) => isSameDay(date, day)), [value]);
    const isExcluded = useCallback((day: Date) => excluded.some((date) => isSameDay(date, day)), [excluded]);

    // Overrides default day selection for shift picker
    // Actual selection done in mouse and touch event callbacks
    const handleDaySelect = useCallback(() => {
        setSelectionStart(null);
        setSelectionEnd(null);
    }, []);

    const preselectedDays = useMemo(() => {
        if (selectionStart && selectionEnd) {
            const [start, end] = [selectionStart, selectionEnd].sort(compareAsc);
            return eachDayOfInterval({ start, end: end ?? start }).filter((day) => !isExcluded(day));
        }

        return [];
    }, [isExcluded, selectionEnd, selectionStart]);

    const getDayClasses = useCallback(
        (day: Date) => {
            const arr: string[] = [];

            if (preselectedDays.some((d) => isSameDay(d, day))) {
                arr.push('preSelected');
            }

            if (isExcluded(day)) {
                arr.push('excluded');
            }

            if (isSelected(day)) {
                const prevDay = addDays(day, -1);
                const nextDay = addDays(day, 1);

                if (!isSelected(prevDay)) {
                    arr.push('blockStart');
                }

                if (!isSelected(nextDay)) {
                    arr.push('blockEnd');
                }
            }

            return arr.join(' ');
        },
        [isExcluded, isSelected, preselectedDays]
    );

    const handleMouseDown = useCallback((day: Date) => {
        setSelectionStart(day);
        setSelectionEnd(day);
    }, []);

    const handleMouseEnter = useCallback((day: Date, e: MouseEvent) => {
        if (e.buttons) {
            setSelectionEnd(day);
        }
    }, []);

    const handleDeselectGroup = useCallback(
        (days: Date[]) => {
            onChange(value.filter((d) => !days.some((day) => isSameDay(d, day))));
        },
        [onChange, value]
    );

    const handleSelectGroup = useCallback(
        (selection: Date[]) => {
            if (selection.length) {
                const newDates = selection.filter((day) => !isSelected(day));
                if (newDates.length) {
                    onChange([...value, ...newDates]);
                } else {
                    handleDeselectGroup(selection);
                }
                setSelectionStart(null);
                setSelectionEnd(null);
            }
        },
        [handleDeselectGroup, isSelected, onChange, value]
    );

    const handleMouseUp = useCallback(() => {
        handleSelectGroup(preselectedDays);
    }, [handleSelectGroup, preselectedDays]);

    const getElementFromPoint = useCallback((e: TouchEvent) => {
        const x = e.changedTouches[0]?.clientX;
        const y = e.changedTouches[0]?.clientY;
        if (x && y) {
            return document.elementsFromPoint(x, y)[0];
        }

        return null;
    }, []);

    const handleTouchEnd = useCallback(
        (from: Date, e: TouchEvent) => {
            const el = getElementFromPoint(e);
            if (el && el instanceof HTMLElement && el.dataset.day) {
                e.preventDefault();
                const to = new Date(el.dataset.day);
                const [start, end] = [from, to].sort(compareAsc);
                const selection = eachDayOfInterval({ start, end: end ?? start }).filter((day) => !isExcluded(day));
                handleSelectGroup(selection);
            } else {
                setSelectionStart(null);
                setSelectionEnd(null);
            }
        },

        [getElementFromPoint, handleSelectGroup, isExcluded]
    );

    const handleTouchMove = useCallback(
        (e: TouchEvent) => {
            const el = getElementFromPoint(e);
            if (el && el instanceof HTMLElement && el.dataset.day) {
                setSelectionEnd(new Date(el.dataset.day));
            }
        },
        [getElementFromPoint]
    );

    useEffect(() => {
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleMouseUp]);

    return (
        <ShiftPickerContext.Provider
            value={{
                value,
                open,
                onOpen,
                onClose,
                onToggle,
                handleMonthChange,
                handleDaySelect,
                nextEntry,
                prevEntry,
                getDayClasses,
                handleMouseDown,
                handleMouseEnter,
                handleTouchEnd,
                handleTouchMove,
                isSelected,
                isExcluded,
                currentMonth,
                setCurrentMonth
            }}
        >
            {children}
        </ShiftPickerContext.Provider>
    );
};

export { useShiftPickerContext, ShiftPickerContextProvider };
