import { useMemo } from 'react';
import appointmentWidgetAPI from '../../../services/WidgetService';
import { skipToken } from '@reduxjs/toolkit/query';

type WidgetOptionsDataType = {
    company_slug?: string;
    services: string[];
    locations?: string[];
    employees?: string[];
};

const useWidgetOptions = (input: WidgetOptionsDataType, skip?: boolean) => {
    const { locations, employees, services, company_slug } = input;

    const query = useMemo(() => {
        const serviceSlugs = services.map((slug) => `service-${slug}`);
        const locationSlugs = locations?.map((slug) => `location-${slug}`) ?? [];
        const employeeSlugs = employees?.map((slug) => `employee-${slug}`) ?? [];

        return [...serviceSlugs, locationSlugs, employeeSlugs].join('/');
    }, [employees, locations, services]);

    const { data, isLoading } = appointmentWidgetAPI.useGetWidgetCompanyWithQueryStringQuery(
        company_slug && !skip
            ? {
                  slug: company_slug,
                  query
              }
            : skipToken
    );

    const dataServices = data?.filteredServices;
    const dataLocations = data?.filteredLocations;
    const dataEmployees = data?.filteredEmployees;

    const companyData = useMemo(() => {
        if (data) {
            const { payment_gws, is_coupons_allowed } = data;
            return { payment_gws, is_coupons_allowed };
        }

        return { payment_gws: undefined, is_coupons_allowed: undefined };
    }, [data]);

    const options = useMemo(
        () => ({
            services: dataServices ?? [],
            locations: dataLocations ?? [],
            employees: dataEmployees ?? [],
            companyData
        }),
        [companyData, dataEmployees, dataLocations, dataServices]
    );

    return { options, companyData, isLoading };
};

export default useWidgetOptions;
