import { memo, useEffect, useRef } from 'react';

// material-ui
import { Stack } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import { IConversationThread } from '../../models/IAppointment';
import ChatMessage from './ChatMessage';

// ==============================|| CHAT MESSAGE HISTORY ||============================== //

export interface ChartHistoryProps {
    data: IConversationThread[];
}

const ChartHistory = ({ data }: ChartHistoryProps) => {
    const wrapper = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (wrapper.current) {
            wrapper.current.scrollIntoView(false);
        }
    }, [data.length]);

    return (
        <Stack alignItems="flex-start" width="100%" spacing={gridSpacing} ref={wrapper}>
            {data.map((history) => (
                <ChatMessage key={history.id} thread={history} />
            ))}
        </Stack>
    );
};

export default memo(ChartHistory);
