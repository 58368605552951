import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, styled } from '@mui/material';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useForceMobileLayoutContext } from '../../../../../../../../../../ui-component/force-mobile-layout-context';

const StyledMenu = styled(Menu)(({ theme }) => ({
    padding: '5px 0',

    '& .MuiMenuItem-root': {
        minHeight: 'auto',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,

        '&:last-of-type': {
            borderBottom: 'none'
        },

        '& .MuiSvgIcon-root': {
            marginRight: '5px'
        }
    }
}));

interface ActionsCellProps {
    onDelete: () => void;
    onEdit?: () => void;
    entity?: string;
}

const ActionsCell = ({ onDelete, onEdit, entity }: ActionsCellProps) => {
    const forceMobile = useForceMobileLayoutContext();
    const matchSmQuery = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const matchSm = forceMobile || matchSmQuery;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return matchSm ? (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <StyledMenu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '150px'
                    }
                }}
            >
                {onEdit ? (
                    <MenuItem
                        onClick={() => {
                            onEdit();
                            handleClose();
                        }}
                    >
                        <EditOutlined color="primary" />
                        Edit
                    </MenuItem>
                ) : null}
                <MenuItem
                    onClick={() => {
                        onDelete();
                        handleClose();
                    }}
                >
                    <DeleteOutlined color="error" />
                    Delete
                </MenuItem>
            </StyledMenu>
        </>
    ) : (
        <>
            {onEdit ? (
                <IconButton title={entity ? `Edit ${entity}` : 'Edit'} size="small" onClick={onEdit}>
                    <EditOutlined color="primary" />
                </IconButton>
            ) : null}
            <IconButton title={entity ? `Delete ${entity}` : 'Delete'} size="small" onClick={onDelete}>
                <DeleteOutlined color="error" />
            </IconButton>
        </>
    );
};

export default ActionsCell;
