import { FC } from 'react';
import AppointmentCard from '../../views/calendar/appointment-card/AppointmentCard';
import { Theme, useMediaQuery } from '@material-ui/core';
import { ModalProps } from '@mui/material';
import { useForceMobileLayoutContext } from '../force-mobile-layout-context';

interface IAppointmentDetailsDialogProps {
    id: number | null;
    onClose: () => void;
    container?: ModalProps['container'];
}

const AppointmentDetailsDialog: FC<IAppointmentDetailsDialogProps> = ({ id, onClose, container }) => {
    const forceMobile = useForceMobileLayoutContext();
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    return (
        <AppointmentCard isOpen={!!id} cardEvent={String(id)} onCancel={onClose} matchSm={matchSm || forceMobile} container={container} />
    );
};

export default AppointmentDetailsDialog;
