import { Box, Tooltip, Typography } from '@material-ui/core';
// import { Link } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import CommonHeaderBtn from '../../../../ui-component/CommonHeaderBtn';

import { SnackBarTypes } from 'store/snackbarReducer';
import useShowSnackbar from 'hooks/useShowSnackbar';
import { useCallback } from 'react';
import accountService from '../../../../services/AccountService';

const WidgetLink = () => {
    const { user } = useAuth();
    const { data: cardData } = accountService.useGetAccountCardDataQuery(null);
    const host = window.location.origin;
    const { showSnackbar } = useShowSnackbar();
    const bookingLink = cardData?.enabled ? `${user?.site_url}` : `${host}/cal/${user?.currentCompany.slug}`;

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(bookingLink).then(() => {
            showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'Ссылка скопирована в буфер.' });
        });
    }, [bookingLink, showSnackbar]);

    return (
        <Tooltip title="Онлайн запись" placement="top" arrow>
            <Box component="span">
                <CommonHeaderBtn
                    // @ts-ignore
                    // component={Link}
                    // to={`/cal/${user?.currentCompany.slug}`}
                    // target="_blank"
                    size="100px"
                    onClick={handleCopy}
                >
                    <Typography variant="button" fontSize="8px" textAlign="center" component="div">
                        Онлайн запись
                    </Typography>
                </CommonHeaderBtn>
            </Box>
        </Tooltip>
    );
};

export default WidgetLink;
