const formatMinutes = (duration: number | null) => {
    if (duration) {
        const hrs = Math.floor(duration / 60);
        const mins = duration % 60;

        const hoursString = hrs > 0 ? `${hrs} ч.` : undefined;
        const minutesString = mins > 0 ? `${mins} мин.` : undefined;

        return [hoursString, minutesString].filter((i) => !!i).join(' ');
    }

    return '0 мин.';
};

export default formatMinutes;
