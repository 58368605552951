import moment from 'moment-timezone';
import { IWeekDaySchedule } from '../models/IService';
import { useCallback, useState } from 'react';
import { useMediaQuery, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

interface WeekDaysScheduleProps {
    schedule: IWeekDaySchedule[];
    onChange: (data: IWeekDaySchedule[]) => void;
}

const NewWeekDaysSchedule = ({ schedule, onChange }: WeekDaysScheduleProps) => {
    const isMobile = useMediaQuery('(max-width:480px)');
    const [innerSchedule, setInnerSchedule] = useState(schedule);
    const handleDayClick = useCallback(
        (id: number) => {
            const updatedSchedule = innerSchedule.map((dayItem) => {
                if (dayItem.id === id) {
                    return { ...dayItem, enable: !dayItem.enable };
                }
                return dayItem;
            });
            setInnerSchedule(updatedSchedule);
            onChange(updatedSchedule);
        },
        [innerSchedule, onChange]
    );

    return (
        <FormGroup
            sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: isMobile ? '100%' : 'repeat(2, 1fr)',
                gap: `8px 24px`
            }}
        >
            {schedule.map((day) => (
                <FormControlLabel
                    sx={{
                        mx: 0,
                        justifyContent: 'flex-start',
                        backgroundColor: innerSchedule.find((item) => item.id === day.id)?.enable ? 'rgba(187, 217, 237, 0.3)' : undefined,
                        borderRadius: '4px',
                        pr: 2,
                        cursor: 'pointer',
                        userSelect: 'none'
                    }}
                    key={`schedule_${day.id}`}
                    control={
                        <Checkbox
                            checked={innerSchedule.find((item) => item.id === day.id)?.enable}
                            onChange={() => {
                                handleDayClick(day.id);
                            }}
                        />
                    }
                    label={moment().day(day.id).format('dddd')}
                />
            ))}
        </FormGroup>
    );
};

export default NewWeekDaysSchedule;
