import { Box, FormControl, FormHelperText, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, PropsWithChildren, ReactChild, useMemo } from 'react';
import InfoTooltip from '../InfoTooltip';

type InputRowProps = PropsWithChildren<{
    label: string;
    info?: ReactChild | string;
    error?: string[] | string | null;
    forceColumn?: boolean;
    size?: 'sm' | 'md';
    fullHeight?: boolean;
    forceTooltips?: boolean;
    tooltipKey?: string;
}>;

const InputRow: FC<InputRowProps> = ({ label, info, children, error, forceColumn, fullHeight, size = 'md', forceTooltips, tooltipKey }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const fontSize = useMemo(() => {
        if (size === 'sm' || isMobile) {
            return '0.875rem';
        }
        return '1rem';
    }, [isMobile, size]);

    const tooltipText = useMemo(() => {
        if (info) {
            return info;
        }
        return null;
    }, [info]);

    return (
        <FormControl fullWidth sx={fullHeight ? { height: '100%' } : undefined}>
            <Stack
                sx={fullHeight ? { height: '100%' } : undefined}
                direction={forceColumn ? 'column' : 'row'}
                spacing={1}
                alignItems="flex-start"
                justifyContent="space-between"
            >
                <Box sx={{ maxWidth: '710px' }}>
                    <Typography sx={{ fontWeight: 600, fontSize, color: 'grey.900', display: 'flex', alignItems: 'center' }}>
                        {label} {tooltipText && forceTooltips ? <InfoTooltip text={tooltipText} label={label} /> : null}
                    </Typography>
                    {tooltipText && !forceTooltips ? <FormHelperText sx={{ mx: 0, fontSize }}>{info}</FormHelperText> : null}
                </Box>
                <Box sx={forceColumn ? { width: '100%' } : undefined}>{children}</Box>
            </Stack>
            {error ? (
                <FormHelperText error sx={{ ml: 0 }}>
                    {Array.isArray(error) ? error.map((e) => <div>{e}</div>) : error}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
};

export default InputRow;
