import React, { useContext, useRef, useState, useMemo } from 'react';
import Can, { AbilityContext } from '../../utils/roles/Can';

import { useTheme } from '@material-ui/core/styles';
import {
    CardContent,
    CircularProgress,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { throttle } from 'lodash';
import { useAppDispatch } from 'hooks/redux';
import { ICustomer } from 'models/ICustomer';
import customerAPI from 'services/CustomerService';
import { ArrangementOrder } from '../../types';
import AppPagePagination from '../../ui-component/PagePagination';
import { openConfirmPopup } from '../../store/confirmPopupSlice';
import { useMediaQuery } from '@mui/material';
// import Visibility from '@mui/icons-material/Visibility';
import EllipsisTypography from '../../ui-component/optimized-text-fields/EllipsisTypography';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { axiosServices } from '../../utils/axios';
import FormattedPhoneNumber from '../../ui-component/FormattedPhoneNumber';
import { useNavigate } from 'react-router';
import useMobileCreateButton from '../../hooks/useMobileCreateButton';
import { DialogTypes, openDialog } from '../../store/slices/entityDialogsSlice';
import useShowSnackbar from '../../hooks/useShowSnackbar';

const CustomerList: React.FC = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(50);
    const [search, setSearch] = useState<string | null>(null);
    const [order, setOrder] = useState<ArrangementOrder>('asc');
    const [orderBy, setOrderBy] = useState<string>('firstname');

    const isMobile = useMediaQuery('(max-width:600px)');
    const ability = useContext(AbilityContext);
    const navigate = useNavigate();

    useMobileCreateButton({
        action: () => navigate('/customer/create'),
        condition: ability.can('create', 'customer')
    });

    const { data, isLoading } = customerAPI.useFetchAllCustomersQuery({
        per_page: perPage,
        page,
        search,
        sort: orderBy,
        order
    });

    const throttled = useRef(
        throttle((newValue) => {
            setSearch(newValue);
        }, 1000)
    );

    const tableCells = useMemo(() => {
        const cells = [
            { id: 'firstname', label: 'Имя' },
            { id: 'phone', label: 'Телефон' }
        ];

        if (isMobile) return cells;

        return [...cells, { id: 'email', label: 'Email' }];
    }, [isMobile]);

    const handleOrderBy = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const newString = event?.target.value;
        throttled.current(newString?.toLowerCase());
    };

    const handleClose = (limit?: number) => {
        if (limit) {
            setPerPage(limit);
            setPage(1);
        }
    };

    const handleDelete = (customer: ICustomer) => {
        dispatch(
            openConfirmPopup({
                onConfirm: () => removeCustomer(customer),
                confirmText: `Удаление`,
                text: `Вы уверены, что хотите удалить клиента ${customer.firstname} ${customer.lastname ?? ''} ?`
            })
        );
    };

    const { showSnackbar } = useShowSnackbar();

    const removeCustomer = async (customer: ICustomer) => {
        if (customer) {
            try {
                const res = await axiosServices.delete(`/customers/${customer.id}`);
                if (res) {
                    showSnackbar({
                        message: 'Клиент удален.',
                        alertSeverity: SnackBarTypes.Success
                    });
                    dispatch(customerAPI.util.invalidateTags(['Customer']));
                }
            } catch (e) {
                dispatch(
                    openConfirmPopup({
                        text: e.message
                    })
                );
                showSnackbar({
                    message: 'Ошибка: клиент не был удален.',
                    alertSeverity: SnackBarTypes.Error
                });
            }
        }
    };

    return (
        <>
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={4} alignItems="center">
                        <TextField
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Поиск клиентов"
                            size="small"
                        />
                    </Grid>
                    {!isMobile && (
                        <Can I="create" a="customer">
                            <Grid item xs={2} sx={{ textAlign: 'right', pl: 0, flexBasis: 0 }}>
                                <Tooltip title="Добавить клиента">
                                    <Link to="/customer/create">
                                        <Fab
                                            color="secondary"
                                            size="small"
                                            sx={{
                                                boxShadow: 'none',
                                                ml: 1,
                                                width: '32px',
                                                height: '32px',
                                                minHeight: '32px'
                                            }}
                                        >
                                            <AddIcon fontSize="small" />
                                        </Fab>
                                    </Link>
                                </Tooltip>
                            </Grid>
                        </Can>
                    )}
                </Grid>
            </CardContent>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableCells.map((cell) => (
                                <TableCell key={cell.id} align="left" sx={{ pl: 3 }}>
                                    <TableSortLabel
                                        active={orderBy === cell.id}
                                        direction={orderBy === cell.id ? order : 'asc'}
                                        onClick={() => handleOrderBy(cell.id)}
                                    >
                                        {cell.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            {(ability.can('update', 'customer') || ability.can('delete', 'customer')) && (
                                <TableCell align="left" sx={{ textAlign: 'center' }}>
                                    Действия
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {!isLoading &&
                            data &&
                            data.data.map((row, index) => (
                                <TableRow hover key={row.id} id={`${row.firstname} ${row.lastname}-row`}>
                                    <TableCell
                                        onClick={
                                            ability.can('view', 'customer')
                                                ? () =>
                                                      dispatch(
                                                          openDialog({
                                                              type: DialogTypes.Customer,
                                                              id: row.id
                                                          })
                                                      )
                                                : undefined
                                        }
                                    >
                                        <EllipsisTypography
                                            text={`${row.firstname} ${row.lastname ? row.lastname : ''}`}
                                            /* onClick={
                                                ability.can('view', 'customer')
                                                    ? () =>
                                                          dispatch(
                                                              openDialog({
                                                                  type: DialogTypes.Customer,
                                                                  id: row.id
                                                              })
                                                          )
                                                    : undefined
                                            } */
                                        />
                                    </TableCell>

                                    <TableCell
                                        sx={{ pl: 3 }}
                                        onClick={
                                            ability.can('view', 'customer')
                                                ? () =>
                                                      dispatch(
                                                          openDialog({
                                                              type: DialogTypes.Customer,
                                                              id: row.id
                                                          })
                                                      )
                                                : undefined
                                        }
                                    >
                                        {row.phone && !row.is_pd_masked ? <FormattedPhoneNumber phone={row.phone} /> : row.phone || null}
                                    </TableCell>
                                    {!isMobile && (
                                        <TableCell
                                            sx={{ pl: 3 }}
                                            onClick={
                                                ability.can('view', 'customer')
                                                    ? () =>
                                                          dispatch(
                                                              openDialog({
                                                                  type: DialogTypes.Customer,
                                                                  id: row.id
                                                              })
                                                          )
                                                    : undefined
                                            }
                                        >
                                            <Typography variant="subtitle1" align="left" component="div">
                                                {row.email}
                                            </Typography>
                                        </TableCell>
                                    )}
                                    <TableCell align="right" sx={{ p: 0, pl: 1 }}>
                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                            {/* <Can I="view" a="customer">
                                                        <Tooltip placement="top" title="Просмотр клиента">
                                                            <IconButton
                                                                color="primary"
                                                                onClick={() => {
                                                                    dispatch(
                                                                        openDialog({
                                                                            type: DialogTypes.Customer,
                                                                            id: row.id
                                                                        })
                                                                    );
                                                                }}
                                                            >
                                                                <Visibility />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Can> */}
                                            <Can I="delete" a="customer">
                                                {!row.deleted_at && (
                                                    <Tooltip placement="top" title="Удалить клиента">
                                                        <IconButton
                                                            onClick={() => handleDelete(row)}
                                                            color="primary"
                                                            sx={{
                                                                color: theme.palette.orange.dark,
                                                                borderColor: theme.palette.orange.main,
                                                                '&:hover ': { background: theme.palette.orange.light }
                                                            }}
                                                        >
                                                            <DeleteIcon sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Can>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {isLoading && (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <AppPagePagination page={page} data={data} perPage={perPage} setPageSize={handleClose} setPage={setPage} />
        </>
    );
};

export default CustomerList;
