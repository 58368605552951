import { AppointmentServiceRowType } from '../elements/appointment-services-subform';

const convertedPrice = (v?: unknown) => {
    if (typeof v === 'string' || typeof v === 'number') {
        return parseFloat(String(v));
    }

    return null;
};

const compareServices = (oldValues: AppointmentServiceRowType[], newValues: AppointmentServiceRowType[]): boolean => {
    if (oldValues.length !== newValues.length) {
        return true;
    }

    return newValues.some((service, index) => {
        const oldService = oldValues[index];
        const differentId = service.service?.id !== oldService?.service?.id;
        const differentPrice = convertedPrice(service.price) !== convertedPrice(oldService?.price);
        const differentPrepay = convertedPrice(service.prepay) !== convertedPrice(oldService?.prepay);

        return differentId || differentPrice || differentPrepay;
    });
};

export default compareServices;
