import { Grid } from '@material-ui/core';
import FileHeader from './FileHeader';
import { ImageResponse } from '../../models/IImage';
import { memo, useCallback, useEffect, useState } from 'react';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { uploadImageFile } from '../../utils/functions/uploading-images-helpers';
import useShowSnackbar from '../../hooks/useShowSnackbar';

export interface SingleFileUploadProps {
    file: File;
    onDelete: (file: File) => void;
    onUpload: (data: ImageResponse, filename: string) => void;
    setIsLoading: (arg: boolean) => void;
}

const SingleFileUpload = ({ file, onDelete, onUpload, setIsLoading }: SingleFileUploadProps) => {
    const [busy, setBusy] = useState(false);
    const { showSnackbar } = useShowSnackbar();

    const onSuccess = useCallback(
        (data: ImageResponse, filename: string) => {
            setBusy(false);
            onUpload(data, filename);
        },
        [onUpload]
    );

    const onError = useCallback(
        (e: any) => {
            setBusy(false);
            showSnackbar({ alertSeverity: SnackBarTypes.Error, message: e.message ?? e.data ?? JSON.stringify(e) });
        },
        [showSnackbar]
    );

    useEffect(() => {
        if (!busy) {
            setBusy(true);
            uploadImageFile({ file, name: file.name, onUpload: onSuccess, setIsLoading, onError });
        }
    }, [busy, file, onError, onSuccess, onUpload, setIsLoading]);

    return (
        <Grid item sx={{ mb: 4 }} key={file.name}>
            <FileHeader file={file} onDelete={onDelete} onUpload={onUpload} />
        </Grid>
    );
};

export default memo(SingleFileUpload);
