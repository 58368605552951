import MainCard from '../../ui-component/cards/MainCard';
import { Box, Stack, Grid, Typography } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { useCallback, useMemo } from 'react';
import moment from 'moment';
import userAPI from '../../services/AccountService';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { useNavigate } from 'react-router-dom';
import { openConfirmPopup } from 'store/confirmPopupSlice';
import { useAppDispatch } from 'hooks/redux';
import { LoadingButton } from '@mui/lab';

const SubscriptionsManagement = () => {
    const { user, checkAuthentication } = useAuth();
    const { showSnackbar } = useShowSnackbar();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [unsubscribe, { isLoading }] = userAPI.useDeletePaymentMethodMutation();

    const nextPayment = user?.companyOwner?.subscription_next_payment_at;
    const nextPaymentDate = useMemo(() => {
        if (nextPayment) {
            return moment(nextPayment).format('DD MMMM YYYY');
        }
        return null;
    }, [nextPayment]);

    const handleUnsub = useCallback(() => {
        unsubscribe(null)
            .unwrap()
            .then((res) => {
                showSnackbar({
                    alertSeverity: SnackBarTypes.Success,
                    message: 'Платежный метод успешно удален'
                });
                checkAuthentication();
            });
    }, [checkAuthentication, navigate, showSnackbar, unsubscribe]);

    const onHandleUnsub = useCallback(() => {
        dispatch(
            openConfirmPopup({
                onConfirm: handleUnsub,
                confirmText: `Удалить`,
                text: 'Вы действительно хотите удалить метод оплаты?'
            })
        );
    }, [dispatch, handleUnsub]);

    const subscriptionTitle = `${user?.companyOwner?.subscription_name} (${user?.companyOwner?.subscription_price ?? '0'}₽) ${
        user?.currentCompany.whatsapp.is_whatsapp_enabled ? ` + Whatsapp уведомления (${user?.currentCompany.whatsapp.package.price}₽)` : ''
    }`;
    const totalSubPrice =
        Number(user?.companyOwner?.subscription_price ?? 0) +
        (user?.currentCompany.whatsapp.is_whatsapp_enabled ? Number(user?.currentCompany.whatsapp.package.price) : 0);
    return (
        <MainCard title="Управление подпиской">
            <Stack spacing={2}>
                <Box sx={{ width: '100%', maxWidth: '1200px' }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={5} sm={5} lg={3} display="flex" alignItems="center">
                            <Typography variant="h4">Активная подписка:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} lg={7} textAlign="left">
                            {subscriptionTitle}
                        </Grid>
                        <Grid item xs={5} sm={5} lg={3} display="flex" alignItems="center">
                            <Typography variant="h4">Дата следующего списания ({totalSubPrice}₽):</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} lg={7} textAlign="left">
                            {nextPaymentDate}
                        </Grid>
                        {user?.payment_method ? (
                            <>
                                <Grid item xs={5} sm={5} lg={3} display="flex" alignItems="center">
                                    <Typography variant="h4">Привязанный способ оплаты:</Typography>
                                </Grid>
                                <Grid item xs={7} sm={7} lg={7} textAlign="left">
                                    {user?.payment_method?.title}
                                    <LoadingButton
                                        onClick={onHandleUnsub}
                                        loading={isLoading}
                                        color="error"
                                        variant="contained"
                                        sx={{ alignSelf: 'flex-start', margin: '0 0 0 8px' }}
                                        disableElevation
                                        size="small"
                                    >
                                        Удалить
                                    </LoadingButton>
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                </Box>
            </Stack>
        </MainCard>
    );
};

export default SubscriptionsManagement;
