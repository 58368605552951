import { FC } from 'react';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { useShiftPickerContext } from './shift-picker-context';

const ShiftPickerDay: FC<PickersDayProps<Date>> = ({ day, key, isFirstVisibleCell, isLastVisibleCell, outsideCurrentMonth }) => {
    const ctx = useShiftPickerContext();

    if (ctx) {
        const {
            handleDaySelect,
            isSelected,
            getDayClasses,
            handleMouseDown,
            handleTouchMove,
            handleTouchEnd,
            isExcluded,
            handleMouseEnter
        } = ctx;
        return (
            <PickersDay
                data-day={day}
                day={day}
                key={key}
                isFirstVisibleCell={isFirstVisibleCell}
                isLastVisibleCell={isLastVisibleCell}
                onDaySelect={handleDaySelect}
                outsideCurrentMonth={outsideCurrentMonth}
                selected={isSelected(day)}
                disableMargin
                className={getDayClasses(day)}
                onMouseDown={() => handleMouseDown(day)}
                onMouseEnter={(event) => handleMouseEnter(day, event)}
                onTouchStart={() => handleMouseDown(day)}
                onTouchMove={handleTouchMove}
                onTouchEnd={(e) => handleTouchEnd(day, e)}
                disabled={isExcluded(day)}
            />
        );
    }

    return null;
};

export default ShiftPickerDay;
