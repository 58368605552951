import { ChangeEvent, FC, useCallback, useState } from 'react';
import { Box, Button, IconButton, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTheme } from '@material-ui/core/styles';
import StyledGreenButton from '../../../views/scheduling-widget/components/StyledGreenButton';

interface ICouponApplyFormProps {
    onApply: (coupon: string) => void;
    coupon?: { code: string | number; amount: string | number };
    onDelete?: () => void;
    error?: string;
    clearError?: () => void;
    isBooking?: boolean;
    isBusy?: boolean;
}

const CouponApplyForm: FC<ICouponApplyFormProps> = ({ coupon, onDelete, onApply, error, clearError, isBooking, isBusy }) => {
    const theme = useTheme();
    const [value, setValue] = useState('');

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLTextAreaElement>) => {
            clearError?.();
            setValue(e.target.value);
        },
        [clearError]
    );

    const handleDelete = useCallback(() => {
        if (onDelete) {
            onDelete();
            setValue('');
        }
    }, [onDelete]);

    if (coupon) {
        return (
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    backgroundColor: '#FAFAFA',
                    px: 2,
                    py: 1,
                    alignItems: 'center',
                    minHeight: '50px',
                    backgroundOrigin: 'border-box',
                    boxSizing: 'border-box'
                }}
            >
                {onDelete && (
                    <IconButton color="error" onClick={handleDelete} size="small" sx={{ ml: '-5px' }} disabled={isBusy}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                )}

                <Box
                    sx={{
                        flexGrow: 1,
                        fontSize: '1rem',
                        fontFamily: isBooking ? 'Roboto' : undefined
                    }}
                >
                    {coupon.code}
                </Box>
                <Box
                    sx={{
                        color: theme.palette?.widget?.lightGreen ?? 'success.main',
                        fontSize: '18px'
                    }}
                >{`-$${coupon.amount}`}</Box>
            </Stack>
        );
    }

    const BtnComponent = isBooking ? StyledGreenButton : Button;

    return (
        <Stack direction="row" spacing={1.5} sx={{ px: isBooking ? undefined : 2 }} alignItems="flex-start">
            <TextField
                label="Введите код купона"
                helperText={error}
                error={!!error}
                value={value}
                onChange={handleChange}
                fullWidth
                InputProps={{
                    // Disable form submit on enter
                    onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }
                }}
                disabled={isBusy}
            />

            <BtnComponent
                onClick={() => onApply(value)}
                variant="contained"
                color="primary"
                sx={{ minWidth: '88px' }}
                disabled={isBusy}
                size="large"
            >
                Применить
            </BtnComponent>
        </Stack>
    );
};

export default CouponApplyForm;
