import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const SectionHeading = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    fontWeight: 500,
    textTransform: 'capitalize'
}));

export default SectionHeading;
