import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useMemo } from 'react';

const useNavigationMode = () => {
    const theme = useTheme();
    const matchMd = useMediaQuery(theme.breakpoints.up('lg'));

    return useMemo(() => (matchMd ? 'header' : 'sidebar'), [matchMd]);
};

export default useNavigationMode;
