import { useTheme } from '@material-ui/core';
import { Box, Button, /* FormHelperText, */ Grid, Paper, Stack, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import PlanSelection from './register-wizard/PlanSelection';
// import InitAPI from '../../services/InitService';
import * as Yup from 'yup';
// import OrganizationSizeButton from './register-wizard/OrganizationSizeButton';
import { RegistrationFormType } from './register-wizard/types';
import UserDataSubform from './register-wizard/subforms/UserDataSubform';
import CompanyDataSubform from './register-wizard/subforms/CompanyDataSubform';
import { LeadData } from './types';
import { RegisterPayload } from '../../contexts/SanctumContext';
import moment from 'moment-timezone';
import useAuth from '../../hooks/useAuth';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import { useAppDispatch } from '../../hooks/redux';
import { SnackBarTypes } from '../../store/snackbarReducer';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import useExtendedFormik from '../../hooks/useExtendedFormik';
import { useNavigate } from 'react-router';

type RegisterStep = {
    title: string;
    subtitle?: string;
    fields: Array<keyof RegistrationFormType>;
};

const steps: RegisterStep[] = [
    {
        title: 'Регистрация в ЗаписьПро',
        fields: []
    },
    { title: 'Название компании', fields: ['company_name', 'address'] },
    { title: 'Подтвердите выбор плана', fields: ['stripe_price_id', 'subscription_type'] }
];

const requiredError = 'Это обязательное поле';
export const registrationFormSchema = Yup.object().shape({
    firstname: Yup.string().trim().required(requiredError).label('Имя'),
    lastname: Yup.string().trim().required(requiredError).label('Фамилия'),
    email: Yup.string().trim().email().required(requiredError).label('Email'),
    company_name: Yup.string().trim().required(requiredError).label('Название компании'),
    // industry: Yup.string().trim().required(requiredError).label('Индустрия'),
    // company_size: Yup.string().trim().required('Выберите количество сотрудников'),
    address: Yup.object().shape({
        address: Yup.string().trim().required(requiredError).label('Адрес'),
        // postal_code: Yup.string().trim().required(requiredError).label('Почтовый индекс'),
        city: Yup.string().trim().required(requiredError).label('Город')
        // country: Yup.string().trim().required(requiredError).label('Страна')
    })
    // stripe_price_id: Yup.string().trim().required().label('План')
});

const Register: FC<{ withTrial?: boolean }> = ({ withTrial = false }) => {
    const [activeStep, setActiveStep] = useState(0);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const navigate = useNavigate();

    /* const { data: initData } = InitAPI.useInitQuery(null, {
        refetchOnMountOrArgChange: true
    }); */

    const { register } = useAuth();

    const handleRegister = useCallback(
        (formData: RegistrationFormType) => {
            const { firstname, lastname, email, address, company_name, subscription_type, lead_uuid, stripe_price_id } = formData;
            const payload: RegisterPayload = {
                firstname,
                lastname,
                email,
                address: {
                    address: address.address || '',
                    city: address.city || '',
                    postal_code: address.postal_code || '000000',
                    country: address.country || 'Россия'
                },
                company: {
                    name: company_name,
                    lead_uuid,
                    time_zone: moment.tz.guess(true),
                    subscription_type
                },
                stripe_price_id,
                with_trial: withTrial
            };

            dispatch(startSubmitting());
            register(payload)
                .then(() => {
                    dispatch(stopSubmitting());

                    navigate('/login', { replace: true });
                    showSnackbar({
                        message: 'Регистрация успешна! Проверьте email.',
                        alertSeverity: SnackBarTypes.Success
                    });
                })
                .catch((err) => {
                    dispatch(stopSubmitting());
                    showSnackbar({
                        message: err.message || 'Произошла ошибка. Пожалуйста проверьте данные и попробуйте снова',
                        alertSeverity: SnackBarTypes.Error
                    });
                });
        },
        [dispatch, register, showSnackbar, withTrial]
    );

    const formik = useExtendedFormik<RegistrationFormType>({
        enableReinitialize: true,
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            company_name: '',
            industry: '',
            company_size: '1',
            address: {
                address: '',
                city: '',
                state: '',
                country: '',
                postal_code: ''
            },
            stripe_price_id: '1',
            subscription_type: 'single_user',
            lead_uuid: ''
        },
        validationSchema: registrationFormSchema,
        onSubmit: handleRegister
    });

    const { values, setValues /* errors, touched, */, validateForm, handleSubmit, setFieldTouched, setFieldValue } = formik;

    const submitBtnLabel = useMemo(() => {
        if (!activeStep) {
            return 'Продолжить';
        }

        if (activeStep === steps.length - 1) {
            return 'Зарегистрироваться';
        }

        return 'Далее';
    }, [activeStep]);

    const handleNextStep = useCallback(() => {
        if (activeStep === steps.length - 1) {
            handleSubmit();
            return;
        }

        validateForm().then((res) => {
            const { fields } = steps[activeStep];
            const invalidFields = fields.filter((field) => !!res[field]);
            invalidFields.forEach((field) => {
                if (field === 'address') {
                    setFieldTouched('address.address', true);
                    // setFieldTouched('address.postal_code', true);
                    // setFieldTouched('address.country', true);
                    setFieldTouched('address.city', true);
                } else {
                    setFieldTouched(field, true);
                }
            });
            if (!invalidFields.length) {
                setActiveStep((prev) => (prev + 1 > steps.length - 1 ? 0 : prev + 1));
            }
        });
    }, [activeStep, setFieldTouched, validateForm, handleSubmit]);

    const handleSaveLeadData = useCallback(
        ({ firstname, lastname, email, id }: LeadData) => {
            setValues({ ...values, firstname, lastname, email, lead_uuid: id }, false);
            setActiveStep(1);
        },
        [setValues, values]
    );

    // const handleSetStripePriceId = useCallback((v: string) => setFieldValue('stripe_price_id', v), [setFieldValue]);
    const handleSetSubscriptionType = useCallback((v: string) => setFieldValue('subscription_type', v), [setFieldValue]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 3,
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary.light,
                minHeight: '100vh'
            }}
        >
            <Paper elevation={10} sx={{ p: 4, width: '900px', maxWidth: '100%' }}>
                <Stack spacing={4}>
                    <Box>
                        {/* <Typography textAlign="center" gutterBottom variant="body1" sx={{ mb: 1 }}>
                            Шаг {activeStep + 1} из {steps.length}
                        </Typography> */}
                        <Typography textAlign="center" variant="h1" gutterBottom color={theme.palette.secondary.main}>
                            {steps[activeStep].title}
                        </Typography>
                        {steps[activeStep].subtitle ? (
                            <Typography textAlign="center" gutterBottom>
                                {steps[activeStep].subtitle}
                            </Typography>
                        ) : null}
                    </Box>

                    {activeStep === 0 ? (
                        <Box>
                            <UserDataSubform onSuccess={handleSaveLeadData} />
                        </Box>
                    ) : (
                        <>
                            <form autoComplete="off">
                                <Grid container spacing={2}>
                                    {activeStep === 1 && <CompanyDataSubform formInstance={formik} />}

                                    {/* {activeStep === 2 && (
                                        <>
                                            {['single_user', 'small', 'large'].map((item) => (
                                                <Grid
                                                    item
                                                    xs={4}
                                                    key={item}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <OrganizationSizeButton
                                                        type={item}
                                                        selected={values.company_size === item}
                                                        onSelect={(type) => {
                                                            setFieldValue('company_size', type);
                                                        }}
                                                    />
                                                </Grid>
                                            ))}
                                            {touched.company_size && errors.company_size ? (
                                                <Grid item xs={12}>
                                                    <FormHelperText error>{errors.company_size}</FormHelperText>
                                                </Grid>
                                            ) : null}
                                        </>
                                    )} */}

                                    {activeStep === 2 /* && initData */ && (
                                        <Grid item xs={12}>
                                            <Box>
                                                <PlanSelection
                                                    // companySize={values.company_size}
                                                    // initData={initData}
                                                    // setStripePriceId={handleSetStripePriceId}
                                                    subscriptionType={values.subscription_type}
                                                    setSubscriptionType={handleSetSubscriptionType}
                                                />
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </form>

                            <Stack direction="row" spacing={2} sx={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                <Button variant="contained" size="large" onClick={handleNextStep}>
                                    {submitBtnLabel}
                                </Button>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Paper>
        </Box>
    );
};

export default Register;
