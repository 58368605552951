import { MobileDatePicker } from '@mui/x-date-pickers';
import ShiftPickerActionsBar from './shift-picker-actions-bar';
import ShiftPickerValue from './shift-picker-value';
import ShiftPickerDay from './shift-picker-day';
import { useShiftPickerContext } from './shift-picker-context';
import { SxProps } from '@material-ui/system';

const pickerSx: SxProps = {
    '& .MuiButtonBase-root.MuiPickersDay-root': {
        borderRadius: 0,
        '&.blockStart': {
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px'
        },
        '&.blockEnd': {
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px'
        },
        '&.preSelected': {
            background: 'rgba(128,128,128,0.5) !important',
            borderRadius: '0 !important',
            color: '#616161 !important'
        },
        '@media (pointer: fine)': {
            '&:hover:not(.Mui-selected):not(.preSelected)': {
                backgroundColor: 'transparent'
            }
        }
    },

    '& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected:not(.blockStart):not(.blockEnd)': {
        background: 'rgba(24, 137,184, 0.25)',
        color: '#616161'
    },
    '.MuiButtonBase-root.MuiPickersDay-root.Mui-disabled.excluded': {
        color: 'error.dark',
        opacity: 0.75
    }
};

const ShiftPickerInput = () => {
    const ctx = useShiftPickerContext();

    if (ctx) {
        const { open, onClose, currentMonth, handleMonthChange } = ctx;
        return (
            <MobileDatePicker
                open={open}
                onClose={onClose}
                value={currentMonth}
                onMonthChange={handleMonthChange}
                views={['day']}
                openTo="day"
                closeOnSelect={false}
                orientation="landscape"
                slots={{
                    actionBar: ShiftPickerActionsBar,
                    field: ShiftPickerValue,
                    day: ShiftPickerDay
                }}
                slotProps={{
                    mobilePaper: { sx: pickerSx },
                    dialog: { sx: pickerSx },
                    toolbar: { sx: { display: 'none' } }
                }}
            />
        );
    }

    return null;
};

export default ShiftPickerInput;
