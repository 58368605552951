import AppointmentDetailsDialog from '../appointmant-details-dialog/AppointmentDetailsDialog';
import CustomerInfoDialog from '../../views/customer/customer-info/CustomerInfoModal';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { closeDialog, DialogTypes } from '../../store/slices/entityDialogsSlice';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import { ForceMobileLayoutProvider } from '../force-mobile-layout-context';
import BlockTimeDialog from '../../views/calendar/appointment-block/BlockTimeDialog';
import {
    setAppointmentDetailsFormId,
    setIsForeignAppointment,
    setRecurringEventDate,
    setSelectedEvent as setStoreEvent,
    setSelectedRange
} from '../../store/slices/calendarSlice';
import InviteCreationDialog from '../../views/calendar/invite-creation-dialog';
import NewAppointmentModal from '../../views/calendar/new-appointment-modal';
import moment from 'moment-timezone';
import { clearOuterAppointment } from '../../store/slices/outerAppointmentSlice';
import { useLocation } from 'react-router';
import { openConfirmPopup } from '../../store/confirmPopupSlice';
import { useBlocker } from 'react-router-dom';
import { setSelectedCustomerId } from '../../store/slices/calendarFilterSlice';
import useEntityDialogs from '../../layout/use-entity-dialogs';
import CreateRecurringEvent from '../recurring-events/CreateRecurrinEvent';
import RecurringEventDetails from '../recurring-events/RecurringEventDetails';

export const SIDEBAR_WIDTH = '400px'; // '490px when new styles applied'

const EntityDialogs = () => {
    const location = useLocation();
    const { entityId, entityType, showAsNormalDrawer, open } = useEntityDialogs();
    const isMobile = useMediaQuery('(max-width:768px)');
    const isSmall = useMediaQuery('(max-width:600px)');

    const width = useMemo(() => {
        if (open) {
            return isSmall ? '100vw' : SIDEBAR_WIDTH;
        }

        return 0;
    }, [isSmall, open]);

    const { selectedLocation, cellData, selectedCustomerId } = useAppSelector((state) => state.calendarFilter);
    const { selectedRange, selectedEmployeeId, shouldSubmitFormOnTabChange, recurringEventDate } = useAppSelector(
        (state) => state.calendar
    );

    const inviteDuration = useMemo(() => {
        const calendarMinDuration = cellData.duration === '01:00:00' ? 60 : 30;
        return calendarMinDuration === selectedRange?.duration ? undefined : selectedRange?.duration;
    }, [cellData.duration, selectedRange]);

    const newAppointmentSelectedRangeEnd = useMemo(() => {
        const calendarMinDuration = cellData.duration === '01:00:00' ? 60 : 30;
        if (selectedRange) {
            const start = moment(selectedRange.start);
            const end = moment(selectedRange.end);
            const diff = end.diff(start, 'minutes');

            return diff !== calendarMinDuration ? selectedRange.end : undefined;
        }

        return undefined;
    }, [cellData.duration, selectedRange]);

    const dispatch = useAppDispatch();

    const handleClose = useCallback(() => {
        dispatch(closeDialog());

        // From old calendar action
        dispatch(clearOuterAppointment());
        dispatch(setIsForeignAppointment(false));
        dispatch(setSelectedRange(null));

        // Clearing appointment edit state
        dispatch(clearOuterAppointment());
        dispatch(setStoreEvent(null));
        dispatch(setRecurringEventDate(null));

        // Clear details form id
        dispatch(setAppointmentDetailsFormId(undefined));

        // Clear selected customer id
        dispatch(setSelectedCustomerId(null));
    }, [dispatch]);

    const dialogContainerRef = useRef<HTMLDivElement | null>(null);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            currentLocation.pathname !== nextLocation.pathname && shouldSubmitFormOnTabChange && !!entityId && entityType === 'appointment'
    );

    useEffect(() => {
        if (blocker.state === 'blocked') {
            dispatch(
                openConfirmPopup({
                    text: 'Сбросить несохраненные изменения?',
                    confirmText: 'Сбросить',
                    onClose: () => {
                        blocker.reset();
                    },
                    onConfirm: () => {
                        blocker.proceed();
                        dispatch(closeDialog());
                    }
                })
            );
        }
    }, [blocker, dispatch]);

    useEffect(() => {
        if (entityType) {
            dispatch(closeDialog());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <Box sx={{ width: 0, flexShrink: 0, '&:not(:empty)': { width } }}>
            <Drawer
                anchor="right"
                className="entityDetailsDrawer"
                variant={showAsNormalDrawer ? 'temporary' : 'persistent'}
                open={open}
                ModalProps={{
                    keepMounted: false
                }}
                sx={{
                    height: '100vh',
                    // @ts-ignore
                    // eslint-disable-next-line no-dupe-keys
                    height: '100svh',
                    '& .MuiDrawer-paper': {
                        top: 0,
                        width,
                        maxWidth: '100vw',
                        borderLeft: !showAsNormalDrawer ? '2px solid' : undefined,
                        // todo: replace with grey.300 after v4 mui instance will be removed
                        borderLeftColor: '#b4b4b4'
                    }
                }}
            >
                <ForceMobileLayoutProvider value={open}>
                    <Box
                        ref={dialogContainerRef}
                        sx={{
                            height: '100%',
                            '& .MuiModal-root': { position: 'static' },
                            '& .MuiBackdrop-root': { display: 'none' },
                            '& .MuiDialog-paper': {
                                margin: 0,
                                borderRadius: '0 !important',
                                boxShadow: 'none',
                                width: '100%'
                            },
                            '& .MuiDialog-paper.MuiDialog-paperScrollPaper': {
                                height: '100vh'
                            }
                        }}
                    >
                        {/* Appointment Details */}
                        <AppointmentDetailsDialog
                            id={entityType === 'appointment' ? entityId : null}
                            onClose={handleClose}
                            container={dialogContainerRef?.current}
                        />

                        {/* New Appointment */}
                        {selectedLocation ? (
                            <NewAppointmentModal
                                location={selectedLocation}
                                employeeId={selectedEmployeeId}
                                isOpen={entityType === 'appointment' && !entityId}
                                onClose={handleClose}
                                startDate={selectedRange?.start}
                                endDate={!isMobile ? newAppointmentSelectedRangeEnd : undefined}
                                container={dialogContainerRef?.current}
                                customerId={selectedCustomerId}
                            />
                        ) : null}

                        {/* Appointment Invite */}
                        {entityType === 'invite' && !entityId ? (
                            <InviteCreationDialog
                                onClose={handleClose}
                                duration={inviteDuration || 0}
                                employeeId={selectedEmployeeId || 0}
                                locationId={selectedLocation?.id || 0}
                                container={dialogContainerRef?.current}
                            />
                        ) : null}

                        {/* Time Block */}
                        {selectedLocation ? (
                            <BlockTimeDialog
                                isOpen={entityType === 'block_time'}
                                onClose={handleClose}
                                eventId={entityId && entityType === 'block_time' ? entityId : undefined}
                                range={selectedRange}
                                location={selectedLocation}
                                matchSm={isMobile}
                                selectedEmployeeId={selectedEmployeeId}
                                container={dialogContainerRef?.current}
                            />
                        ) : null}

                        {/* Customer Info */}
                        {entityType === 'customer' && !!entityId && (
                            <CustomerInfoDialog
                                customerId={String(entityId)}
                                onClose={handleClose}
                                container={dialogContainerRef?.current}
                            />
                        )}

                        {/* New Recurring Event */}
                        {selectedLocation && entityType === DialogTypes.RecurringEvent && !entityId && (
                            <CreateRecurringEvent location={selectedLocation} date_range={selectedRange} onClose={handleClose} />
                        )}

                        {/* Edit Recurring Event */}
                        {entityType === DialogTypes.RecurringEvent && entityId && (
                            <RecurringEventDetails id={entityId} entry_date={recurringEventDate ?? ''} onClose={handleClose} />
                        )}
                    </Box>
                </ForceMobileLayoutProvider>
            </Drawer>
        </Box>
    );
};

export default EntityDialogs;
