import { useMemo, useCallback, ReactNode } from 'react';
import { IUser } from '../models/IUser';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import CreateButtonFab from './CreateButtonFab';

export interface CreateButtonProps {
    user: IUser | null;
    maxCountReachedText: string;
    tooltipText: string;
    propertyName: 'employees' | 'locations' | 'services';
    action: string | (() => void);
    renderBtnElement?: (disabled?: boolean) => ReactNode;
}

const CreateButton = ({ user, maxCountReachedText, tooltipText, propertyName, action, renderBtnElement }: CreateButtonProps) => {
    const renderBtn = useCallback(
        (disabled?: boolean) => {
            if (renderBtnElement) {
                return renderBtnElement(disabled);
            }

            return (
                <CreateButtonFab color="secondary" disabled={disabled}>
                    <AddIcon fontSize="small" />
                </CreateButtonFab>
            );
        },
        [renderBtnElement]
    );

    const isNewButtonDisabled = useMemo(() => {
        if (!user) return true;

        const limits = user?.currentCompany.limits.max;
        if (!limits || !limits[propertyName]) return false;

        return user?.currentCompany.limits.counts[propertyName] >= ((limits && limits[propertyName]) ?? 0);
    }, [propertyName, user]);

    if (isNewButtonDisabled) {
        return (
            <Tooltip title={maxCountReachedText}>
                <span>{renderBtn(true)}</span>
            </Tooltip>
        );
    }

    return typeof action === 'string' ? (
        <Tooltip title={tooltipText}>
            <Link to={action}>{renderBtn()}</Link>
        </Tooltip>
    ) : (
        <Tooltip title={tooltipText} onClick={action}>
            <span>{renderBtn(true)}</span>
        </Tooltip>
    );
};

export default CreateButton;
