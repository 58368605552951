import { stringToColor } from '../../../store/constant';
import { styled } from '@material-ui/core/styles';
import { ReactNode, useMemo } from 'react';
import { Tooltip, Avatar, Box, Stack, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import config, { Envs } from 'config';
import StyledGreenButton from './StyledGreenButton';

const StyledHeader = styled(Box)(({ theme }) => ({
    color: theme.palette.widget.title,
    display: 'flex',
    width: '100%',
    maxWidth: '920px',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',

    '& .MuiAvatar-root': {
        color: '#fff',
        width: 70,
        height: 70,
        [theme.breakpoints.down('sm')]: {
            width: 50,
            height: 50
        },

        '& img': {
            width: 'auto',
            backgroundColor: 'transparent !important'
        }
    },

    '& .MuiTypography-root.title': {
        fontSize: '24px',
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px'
        }
    },

    '& .MuiTypography-root.subtitle': {
        marginTop: '0 !important',
        fontSize: '15px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
        }
    },

    '@media(max-width: 600px)': {
        padding: theme.spacing(2, 1),
        margin: 0
    }
}));

interface WidgetHeaderProps {
    name: string;
    imageUrl?: string;
    subtitle?: string | ReactNode;
    tooltipText?: string;
    onAvatarClick?: () => void;
    appointmentsView?: boolean;
}

const WidgetHeader = ({
    name,
    imageUrl,
    subtitle = 'Онлайн запись',
    tooltipText,
    onAvatarClick,
    appointmentsView = false
}: WidgetHeaderProps) => {
    const customerPortalUrl = useMemo(() => {
        switch (config.env) {
            case Envs.Local: {
                return `http://localhost:8081/ca/`;
            }
            case Envs.Develop:
            case Envs.Staging:
            case Envs.Production: {
                return `https://client.zapis.pro`;
            }
            default: {
                return `https://client.zapis.pro`;
            }
        }
    }, []);
    return (
        <StyledHeader>
            {imageUrl && (
                <Tooltip title={tooltipText || ''}>
                    <Avatar
                        onClick={onAvatarClick}
                        color="inherit"
                        variant="rounded"
                        src={imageUrl}
                        sx={{
                            width: imageUrl ? 'auto !important' : 'inherit',
                            background: imageUrl ? 'none' : stringToColor(name),
                            cursor: onAvatarClick ? 'pointer' : 'default'
                        }}
                    >
                        <Typography sx={{ fontSize: '40px' }}>{name.charAt(0).toUpperCase()}</Typography>
                    </Avatar>
                </Tooltip>
            )}
            <Stack spacing="4px" ml={2}>
                <Typography className="title">{name}</Typography>
                <Typography className="subtitle" display="flex" alignItems="center">
                    {subtitle}
                </Typography>
            </Stack>
            <Box width={appointmentsView ? '165px' : '140px'} paddingLeft="40px">
                {!appointmentsView ? (
                    <Link to={`${customerPortalUrl}`} target="_blank" className="title">
                        Посмотреть мои записи
                    </Link>
                ) : (
                    <StyledGreenButton variant="contained" onClick={onAvatarClick} disableElevation>
                        Новая запись
                    </StyledGreenButton>
                )}
            </Box>
        </StyledHeader>
    );
};

export default WidgetHeader;
