import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { Button, Link, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Navigate, useNavigate } from 'react-router-dom';
import AuthCardWrapper from './AuthCardWrapper';
import AuthWrapper from './AuthWrapper';
import { useTheme } from '@material-ui/core/styles';
import { useCallback } from 'react';
import { SET_SUB_RENEW_DATA } from '../../store/account/actions';
import { useAppDispatch } from '../../hooks/redux';

const subtitle = 'Необходимо оплатить подписку';
const cancelBtnLabel = 'Отмена';
const okBtnLabel = 'Оплатить';

declare global {
    interface Window {
        webkit?: {
            messageHandlers?: {
                subscriptionHandler?: {
                    postMessage: (message: any) => void;
                };
            };
        };
    }
}

const SubRequired = () => {
    const isWebView = navigator.userAgent.includes('SwiftWebView'); 

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const { redirect_url, required_subscription } = useSelector((AppState: RootState) => AppState.account);
    const loginUrl='https://app.zapis.pro/login';

    const onAppClick = useCallback(() => {
        if (isWebView) {
            window.webkit?.messageHandlers?.subscriptionHandler?.postMessage({ action: 'openWebAppUrl', url: loginUrl });
        } 
    }, [isWebView]); 

    const onCancel = useCallback(() => {
        dispatch({ type: SET_SUB_RENEW_DATA, payload: { redirect_url: null, required_subscription: null } });
        navigate('/login');
    }, [dispatch, navigate]);

    if (!redirect_url || !required_subscription) {
        return <Navigate to="/login" />;
    }
    return (
        <AuthWrapper>
            <Grid container justifyContent="center" alignItems={matchDownSM ? 'flex-start' : 'center'} sx={{ minHeight: '100vh' }}>
                <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                    <AuthCardWrapper>
                        <Stack spacing={3} alignItems="center">
                            <Typography variant="h2" fontSize="16px" textAlign="center" component="div" sx={{ textDecoration: 'none' }}>
                                ЗаписьПро
                            </Typography>
                            {!isWebView ? (
                            <>
                            <Typography variant="caption" fontSize="16px" textAlign="center" component="div">
                                {subtitle}
                            </Typography>
                            <Stack direction="row" spacing={3} justifyContent="center">
                                <Button variant="outlined" onClick={onCancel}>
                                    {cancelBtnLabel}
                                </Button>
                                <Link href={redirect_url}>
                                    <Button disableElevation variant="contained">
                                        {okBtnLabel}
                                    </Button>
                                </Link>
                            </Stack>
                            </>
                            ) : (
                                <>
                                    <Typography variant="caption" fontSize="16px" textAlign="center" component="div">
                                        Возникла проблема с вашей учетной записью. Пожалуйста, войдите в аккаунт используя веб-браузер для ее устранения.
                                    </Typography>
                                    <Stack direction="row" spacing={3} justifyContent="center">
                                        <Button variant="outlined" onClick={onCancel}>
                                            {cancelBtnLabel}
                                        </Button>
                                        <Button variant="contained" onClick={onAppClick}>
                                            Перейти в браузер
                                        </Button>
                                    </Stack>
                                </>
                            )}
                        </Stack>
                    </AuthCardWrapper>
                </Grid>
            </Grid>
        </AuthWrapper>
    );
};

export default SubRequired;
