import { useCallback, useMemo } from 'react';
import moment from 'moment-timezone';

// mui
import { Stack, IconButton, ModalProps } from '@mui/material';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

// project imports
import { AppointmentType, IAppointmentPayload } from '../../../models/IAppointment';
import { UserRole } from '../../../models/IEmployee';
import BlockTimeForm, { BlockTimeFormType } from './BlockTimeForm';
import { ILocation } from '../../../models/ILocation';
import useAuth from '../../../hooks/useAuth';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { startSubmitting, stopSubmitting } from 'store/slices/SubmittingSlice';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import { DateRange } from '../types';
import { apiTimeFormat } from '../../../store/constant';
import appointmentAPI from '../../../services/AppointmentService';
import BlockSkeleton from './BlockSkeleton';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { isEventDateValid } from '../../../utils/functions/time-zones-helpers';
import CBModal from '../../../ui-component/cb-modal/CBModal';
import BlockTimeHistory from './history/BlockTimeHistory';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import appointmentWidgetAPI from '../../../services/WidgetService';

interface BlockTimeDialogProps {
    isOpen: boolean;
    onClose: () => void;
    location: ILocation;
    eventId: number | undefined;
    range: DateRange | null;
    matchSm: boolean;
    selectedEmployeeId: number | null;
    container?: ModalProps['container'];
}

const BlockTimeDialog = ({ isOpen, onClose, range, eventId, location, matchSm, selectedEmployeeId, container }: BlockTimeDialogProps) => {
    const { allEmployees: employees } = useAppSelector((state) => state.calendarFilter);
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const { user } = useAuth();
    const { data: block, isLoading: isLoadingBlock, isFetching } = appointmentAPI.useGetAppointmentQuery(eventId ?? skipToken);
    const [createAppointment, { isLoading: isCreating }] = appointmentAPI.useCreateAppointmentMutation();
    const [updateAppointment, { isLoading: isUpdating }] = appointmentAPI.useUpdateAppointmentMutation();
    const [deleteAppointment, { isLoading: isDeleting }] = appointmentAPI.useDeleteAppointmentMutation();

    const invalidateAppointmentTag = useCallback(() => {
        dispatch(appointmentAPI.util.invalidateTags(['Appointment']));
        dispatch(appointmentWidgetAPI.util.invalidateTags(['AppointmentWidget']));
    }, [dispatch]);

    const { isSubmitting } = useAppSelector((store) => store.submitting);
    const { isForeignAppointment } = useAppSelector((state) => state.calendar);

    const isEdit = useMemo(() => !!eventId, [eventId]);

    const handleEventCreate = (data: IAppointmentPayload) => {
        createAppointment(data)
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Блок создан',
                    alertSeverity: SnackBarTypes.Success
                });
                invalidateAppointmentTag();
                onClose();
                dispatch(stopSubmitting());
            })
            .catch((e) => {
                if (e.data) {
                    showSnackbar({
                        message: e.data,
                        alertSeverity: SnackBarTypes.Error
                    });
                } else {
                    showSnackbar({
                        message: data.type === AppointmentType.Appointment ? 'Запись не была создана' : 'Блок не был создан',
                        alertSeverity: SnackBarTypes.Error
                    });
                }
                dispatch(stopSubmitting());
            });
    };

    const handleUpdateEvent = (id: string, data: IAppointmentPayload) => {
        updateAppointment({ appointmentId: id, data })
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Блок обновлен',
                    alertSeverity: SnackBarTypes.Success
                });
                invalidateAppointmentTag();
                onClose();
                dispatch(stopSubmitting());
            })
            .catch((e) => {
                if (e.data) {
                    showSnackbar({
                        message: e.data,
                        alertSeverity: SnackBarTypes.Error
                    });
                } else {
                    showSnackbar({
                        message: `Блок не был обновлен`,
                        alertSeverity: SnackBarTypes.Error
                    });
                }
                dispatch(stopSubmitting());
            });
    };

    const handleEventDelete = useCallback(
        (id: string | number) => {
            deleteAppointment(id)
                .unwrap()
                .then(() => {
                    showSnackbar({
                        message: 'Блок удален',
                        alertSeverity: SnackBarTypes.Success
                    });
                    onClose();
                    invalidateAppointmentTag();
                })
                .catch(() => {
                    showSnackbar({
                        message: `Блок не был удален`,
                        alertSeverity: SnackBarTypes.Error
                    });
                });
        },
        [deleteAppointment, invalidateAppointmentTag, onClose, showSnackbar]
    );

    const deleteEventConfirm = useCallback(
        (id: string | number) => {
            dispatch(
                openConfirmPopup({
                    onConfirm: () => handleEventDelete(id),
                    confirmText: `Удалить`,
                    text: `Вы уверены что хотите удалить блок?`
                })
            );
        },
        [dispatch, handleEventDelete]
    );

    const handleSubmit = (formData: BlockTimeFormType) => {
        const sendData = (data: IAppointmentPayload) => {
            dispatch(startSubmitting());
            if (block && eventId) {
                handleUpdateEvent(String(block.id), data);
            } else {
                handleEventCreate(data);
            }
        };

        if (formData.employee && formData.start && formData.end) {
            const data = ({
                employee_id: formData.employee.id,
                start_at: formData.start.toISOString(true),
                end_at: formData.end.toISOString(true),
                note: formData.title,
                location_id: location.id,
                type: AppointmentType.Blocked_Time
            } as unknown) as IAppointmentPayload;
            // check past time
            if (!isEventDateValid(data, location.time_zone)) {
                dispatch(
                    openConfirmPopup({
                        onConfirm: () => {
                            sendData(data);
                        },
                        onClose: () => {
                            dispatch(stopSubmitting());
                        },
                        confirmText: `${block ? 'Обновить' : 'Создать'}`,
                        text: `Вы уверены что хотите ${block ? 'обновить' : 'создать'} блок на прошедшую дату?`
                    })
                );
            } else {
                sendData(data);
            }
        }
    };

    const modalActions = useMemo(
        () =>
            !isLoadingBlock ? (
                <Stack direction="row" spacing={matchSm ? 1 : 2}>
                    {eventId && (
                        <IconButton
                            disabled={isSubmitting}
                            onClick={() => deleteEventConfirm(eventId)}
                            color="error"
                            sx={{ border: '1px solid currentColor', borderRadius: 1 }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </Stack>
            ) : undefined,
        [isLoadingBlock, matchSm, eventId, isSubmitting, deleteEventConfirm]
    );

    const okButtonText = useMemo(() => (isEdit ? 'Сохранить' : 'Создать'), [isEdit]);

    const employeeId = user && user.employee.role.name === UserRole.Provider ? user.employee.id : selectedEmployeeId;

    const initialValues = useMemo<BlockTimeFormType>(() => {
        if (eventId) {
            return {
                title: block?.note || '',
                employee: block?.employee || null,
                start: moment(block?.start_at),
                end: moment(block?.end_at)
            };
        }

        return {
            title: '',
            employee: employees?.find((staff) => staff.id === employeeId) || null,
            start: moment(range?.start || null, apiTimeFormat),
            end: moment(range?.end || null, apiTimeFormat)
        };
    }, [block, employees, eventId, range, employeeId]);

    return (
        <CBModal
            id="block_wizard"
            title="Блок"
            maxWidth={matchSm ? false : 'sm'}
            fullWidth
            fullScreen={matchSm}
            onClose={onClose}
            open={isOpen}
            okButtonText={okButtonText}
            okButtonStartIcon={block ? undefined : <AddBoxOutlined />}
            okButtonFormId="block_time_form"
            okButtonDisabled={isSubmitting || isForeignAppointment}
            specialContent={modalActions}
            container={container}
        >
            {!isLoadingBlock && !isFetching ? (
                <BlockTimeForm
                    userRole={user?.employee.role.name}
                    employees={employees}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    isBlocked={isCreating || isUpdating || isDeleting}
                />
            ) : null}
            {eventId ? <BlockTimeHistory eventId={eventId} /> : null}
            {eventId && (isLoadingBlock || isFetching) ? <BlockSkeleton /> : null}
        </CBModal>
    );
};

export default BlockTimeDialog;
