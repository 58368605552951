import { IAppointment, IAppointmentPayload } from '../../../../models/IAppointment';

const useAppointmentFunctions = () => {
    const formatAppointmentPayload = (appointment: IAppointment) =>
        (({
            employee_id: appointment.employee.id,
            location_id: appointment.location.id,
            service_ids: appointment.services.map((service) => ({
                id: service.id,
                price: service.pivot?.price,
                prepay: service.pivot?.prepay
            })),
            customer_id: appointment.customer.id,
            start_at: appointment.start_at,
            end_at: appointment.end_at,
            note: appointment.note,
            private_note: appointment.private_note,
            images: appointment.images,
            payments: appointment.payments,
            deposit_request: appointment.deposit_request,
            address: appointment.address
        } as unknown) as IAppointmentPayload);

    return { formatAppointmentPayload };
};

export default useAppointmentFunctions;
