import SectionHeading from '../SectionHeading';
import appointmentAPI from '../../../../../../services/AppointmentService';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useAppSelector } from '../../../../../../hooks/redux';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import PriceChangeIcon from '@mui/icons-material/PriceChangeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import PaidIcon from '@mui/icons-material/PaidOutlined';
import { styled } from '@mui/material/styles';
import moment from 'moment/moment';

const StyledEventName = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    color: theme.palette.grey[600],
    fontSize: '16px',

    '& .MuiSvgIcon-root': {
        fontSize: '20px',
        color: theme.palette.grey[500]
    }
}));

const StyledDate = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[500],
    marginLeft: '25px',

    '& span': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        display: 'inline-block'
    }
}));

const Activity = () => {
    const { selectedEvent } = useAppSelector((state) => state.calendar);
    const { data, isLoading } = appointmentAPI.useGetAppointmentHistoryQuery(selectedEvent?.id ?? skipToken);

    const eventContent = {
        end_at: { icon: <AccessTimeIcon />, text: 'Время окончание изменено' },
        start_at: { icon: <AccessTimeIcon />, text: 'Время начала изменено' },
        service_id: { icon: <SettingsSuggestIcon />, text: 'Услуга изменена' },
        price: { icon: <PriceChangeIcon />, text: 'Стоимость изменена' },
        employee_id: { icon: <PeopleAltOutlinedIcon />, text: 'Сотрудник изменен' },
        customer_id: { icon: <PeopleAltOutlinedIcon />, text: 'Клиент изменен' },
        note: { icon: <StickyNote2OutlinedIcon />, text: 'Заметки изменены' },
        private_note: { icon: <StickyNote2OutlinedIcon />, text: 'Скрытые заметки изменены' },
        payments: { icon: <PaidIcon />, text: 'Платежи изменены' }
    };

    return (
        <>
            <SectionHeading mb={2} px={2}>
                История изменений
            </SectionHeading>
            <Divider />
            <Box p={2}>
                {isLoading ? (
                    <Box display="flex" alignItems="center" gap={1}>
                        <CircularProgress size={20} />
                        Загрузка...
                    </Box>
                ) : (
                    data?.map((item, index) => (
                        <Box key={`event-${index}`} mb={2}>
                            {item.event === 'created' ? (
                                <StyledEventName>
                                    {item.event_source === 'widget' ? <OpenInNewIcon /> : <EventIcon />}
                                    Запись создана{' '}
                                </StyledEventName>
                            ) : (
                                Object.keys(item.modified_values).map((key, i) => {
                                    const content = eventContent[key as keyof typeof eventContent];
                                    return (
                                        <StyledEventName key={`activity-${i}`}>
                                            {content ? (
                                                <>
                                                    {content.icon}
                                                    {content.text}
                                                </>
                                            ) : (
                                                <>
                                                    <SettingsSuggestIcon />
                                                    <span style={{ textTransform: 'capitalize' }}>{key.replace('_', ' ')}</span> изменено
                                                </>
                                            )}
                                        </StyledEventName>
                                    );
                                })
                            )}
                            <StyledDate>
                                {moment(item.created_at).format('MM/DD/YY h:mm a (z)')}{' '}
                                <span>{item.event_source === 'widget' ? 'через виджет' : `пользователем ${item?.user?.title}`}</span>
                            </StyledDate>
                        </Box>
                    ))
                )}
            </Box>
        </>
    );
};

export default Activity;
