import { FC, useCallback, useEffect } from 'react';
import { Grid, Skeleton, TextField } from '@mui/material';
import authAPI from '../../../../services/authService';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { LeadData, SaveLeadPayload } from '../../types';
import useShowSnackbar from '../../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../../store/snackbarReducer';
import useExtendedFormik from '../../../../hooks/useExtendedFormik';

type UserDataSubformProps = {
    onSuccess: (data: LeadData) => void;
};

const labels = {
    firstname: 'Имя',
    lastname: 'Фамилия',
    email: 'Email'
};

const fields = ['firstname', 'lastname', 'email'] as const;
export const leadSchema = Yup.object().shape({
    firstname: Yup.string().trim().required('Введите имя').label('Имя'),
    lastname: Yup.string().trim().required('Введите фамилию').label('Фамилия'),
    email: Yup.string().trim().email().required('Введите email').label('Email')
});

const UserDataSubform: FC<UserDataSubformProps> = ({ onSuccess }) => {
    const [saveLead, { isLoading }] = authAPI.useSaveLeadMutation();
    const { showSnackbar } = useShowSnackbar();

    const oldLeadId = document.cookie
        .split('; ')
        .find((row) => row.startsWith('cbLeadId='))
        ?.split('=')[1];

    const { data, isLoading: oldLeadLoading } = authAPI.useGetLeadQuery(oldLeadId ?? skipToken);

    const submit = useCallback(
        (formData: SaveLeadPayload) => {
            saveLead(formData).then((res) => {
                if ('data' in res) {
                    onSuccess(res.data);
                    document.cookie = `cbLeadId=${res.data.id}; max-age=${60 * 60 * 24}`;
                }

                if (res && 'error' in res) {
                    const { error } = res;
                    if (error && typeof error === 'object') {
                        'data' in error &&
                            showSnackbar({
                                alertSeverity: SnackBarTypes.Error,
                                message: (error as { data: any }).data
                            });

                        'errors' in error && handleErrors((error as { errors: any }).errors);
                    }
                }
            });
        },

        // handleErrors must be declared after formik instance. This function must be declared before.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onSuccess, saveLead, showSnackbar]
    );

    const { values, errors, handleChange, handleBlur, touched, handleSubmit, setErrors } = useExtendedFormik<SaveLeadPayload>({
        initialValues: {
            firstname: '',
            lastname: '',
            email: ''
        },
        validationSchema: leadSchema,
        onSubmit: submit,
        isBlocked: isLoading
    });

    const handleErrors = (formErrors: Record<string, string | string[]>) => {
        const result: Record<string, string> = {};

        Object.keys(formErrors).forEach((key) => {
            if (Array.isArray(formErrors[key])) {
                // @ts-ignore
                result[key] = formErrors[key].join('; ');
            } else {
                result[key] = String(formErrors[key]);
            }
        });

        setErrors(result);
    };

    useEffect(() => {
        if (data) {
            onSuccess(data);
        }
    }, [data, onSuccess]);

    if (oldLeadLoading) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Skeleton height={50} sx={{ transform: 'none' }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Skeleton height={50} sx={{ transform: 'none' }} />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton height={50} sx={{ transform: 'none' }} />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton height={50} sx={{ transform: 'none' }} />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container component="form" autoComplete="off" onSubmit={handleSubmit} spacing={2}>
            {fields.map((item) => (
                <Grid item xs={12} sm={item !== 'email' ? 6 : undefined} key={item}>
                    <TextField
                        fullWidth
                        id={item}
                        name={item}
                        label={labels[item]}
                        value={values[item]}
                        error={!!errors[item] && !!touched[item]}
                        helperText={touched[item] ? errors[item] : undefined}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Grid>
            ))}

            <Grid item xs={12}>
                <LoadingButton sx={{ mt: 2 }} loading={isLoading} size="large" variant="contained" type="submit" fullWidth>
                    Далее
                </LoadingButton>
            </Grid>
        </Grid>
    );
};

export default UserDataSubform;
