import AvatarGroup from '@atlaskit/avatar-group';
import { Box, ListItem } from '@mui/material';
import { CustomAvatarElement } from './AvatarData';
import { useNavigate } from 'react-router-dom';
import { memo } from 'react';

export interface MoveToProps {
    id: number;
    path: string;
}

interface GroupOfAvatarsProps {
    data: CustomAvatarElement[];
    isClickable: boolean;
    maxCount?: number;
    size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
}

const sizeMapping = {
    xxlarge: '128px',
    xlarge: '96px',
    large: '40px',
    medium: '32px',
    small: '24px',
    xsmall: '16px'
} as const;

const fontSizes = {
    xxlarge: '0.875rem',
    xlarge: '0.875rem',
    large: '0.875rem',
    medium: '0.75rem',
    small: '0.625rem',
    xsmall: '0.625rem'
};

const GroupOfAvatars = ({ data, isClickable, maxCount, size = 'large' }: GroupOfAvatarsProps) => {
    const navigate = useNavigate();
    const moveTo = ({ path, id }: MoveToProps) => {
        navigate(`/${path}/${id}`);
    };

    const handleClickAvatar = (avatar: CustomAvatarElement) => {
        if (isClickable) {
            moveTo({
                path: avatar.url,
                id: avatar.id
            });
        }
    };

    return (
        <Box
            sx={{
                '& .MuiAvatar-root': {
                    width: sizeMapping[size],
                    height: sizeMapping[size],
                    fontSize: fontSizes[size]
                }
            }}
        >
            <AvatarGroup
                appearance="stack"
                maxCount={maxCount || 4}
                data={data}
                size="large"
                isTooltipDisabled
                showMoreButtonProps={{
                    style: {
                        margin: 0,
                        width: '42px',
                        height: '42px',
                        fontSize: '14px'
                    }
                }}
                // custom dropdown
                overrides={{
                    AvatarGroupItem: {
                        render: (Component, props, index) => {
                            const avatarItem = <Component {...props} key={index} />;
                            return (
                                <ListItem
                                    key={data[index].id}
                                    onClick={() => handleClickAvatar(data[index])}
                                    sx={{
                                        p: 0,
                                        '& > button': {
                                            cursor: isClickable ? 'pointer' : 'default'
                                        }
                                    }}
                                >
                                    {avatarItem}
                                </ListItem>
                            );
                        }
                    }
                }}
            />
        </Box>
    );
};

export default memo(GroupOfAvatars);
