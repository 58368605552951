import { AppointmentStatuses } from '../../../models/IAppointment';
import Adjust from '@mui/icons-material/Adjust';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const appointmentStatuses = [
    {
        value: AppointmentStatuses.Pending,
        label: 'Не подтверждено',
        icon: <AccessTimeIcon fontSize="small" />
    },
    {
        value: AppointmentStatuses.Active,
        label: 'Подтверждено',
        icon: <Adjust fontSize="small" />
    },
    {
        value: AppointmentStatuses.Completed,
        label: 'Завершено',
        icon: <CheckCircleOutline fontSize="small" />
    },
    {
        value: AppointmentStatuses.Canceled,
        label: 'Отменено',
        icon: <CancelOutlined fontSize="small" />
    }
];

export default appointmentStatuses;
