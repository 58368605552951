import { createContext, ReactNode, useContext } from 'react';

interface IForceMobileLayoutProvider {
    children: ReactNode;
    value?: boolean;
}

const ForceMobileLayoutContext = createContext<boolean | undefined>(undefined);

const useForceMobileLayoutContext = () => {
    const ctx = useContext(ForceMobileLayoutContext);
    return !!ctx;
};

const ForceMobileLayoutProvider = ({ children, value }: IForceMobileLayoutProvider) => (
    <ForceMobileLayoutContext.Provider value={value}>{children}</ForceMobileLayoutContext.Provider>
);

export { useForceMobileLayoutContext, ForceMobileLayoutProvider };
