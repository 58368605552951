import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import serviceAPI from '../../../services/ServiceService';
import { FC, useCallback, useMemo } from 'react';

type ServiceCategorySelectProps = Pick<TextFieldProps, 'onBlur' | 'name' | 'id' | 'label' | 'disabled'> & {
    value: number[];
    onChange: (v: number[]) => void;
    error?: string | null;
};

const ServiceCategorySelect: FC<ServiceCategorySelectProps> = ({ value, onChange, error, ...rest }) => {
    const { data, isLoading } = serviceAPI.useGetCategoriesQuery({ per_page: -1 });

    const options = useMemo(() => {
        if (data) {
            return data.data;
        }

        return [];
    }, [data]);

    const getOptionLabel = useCallback((id: number) => options.find((option) => option.id === id)?.name ?? '', [options]);

    const handleChange = useCallback(
        (_: unknown, v: number[]) => {
            onChange(v ?? []);
        },
        [onChange]
    );

    return (
        <Autocomplete
            multiple
            loading={isLoading}
            value={value}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => <TextField {...params} {...rest} hiddenLabel={!rest.label} error={!!error} helperText={error} />}
            onChange={handleChange}
            options={options.map(({ id }) => id)}
        />
    );
};

export default ServiceCategorySelect;
