import { useCallback, useEffect, useState, useMemo } from 'react';
import useAuth from '../../hooks/useAuth';
import { IEmployee, IEmployeePayload, UserRole, UserRoleLabel } from '../../models/IEmployee';
import { colors } from '../../store/constant';
import locationAPI from '../../services/LocationService';
import ResendInvitationButton from './ResendInvitationButton';
import {
    CardActions,
    CardContent,
    Divider,
    Grid,
    ListItemText,
    ListItemIcon,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    Typography,
    Stack
} from '@material-ui/core';
import ImageUploader from '../../ui-component/form/ImageUploader';
import InputLabel from '../../ui-component/extended/Form/InputLabel';
import OptimizedTextField from '../../ui-component/optimized-text-fields/OptimizedTextField';
import { isAllowChangeRole } from '../../utils/roles/functions';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import TwoColumnsSwitch from '../../ui-component/TwoColumnsSwitch';
import Circle from '@mui/icons-material/Circle';
import ServiceSelect from '../../ui-component/service-select/ServiceSelect';
import LabelWithInfo from '../../ui-component/LabelWithInfo';
import InfoTooltip from '../../ui-component/InfoTooltip';
import { SnackBarTypes } from '../../store/snackbarReducer';
import serviceAPI from '../../services/ServiceService';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import employeeAPI from '../../services/EmployeeService';
import appointmentAPI from '../../services/AppointmentService';
import { useAppDispatch } from '../../hooks/redux';
import { useNavigate } from 'react-router-dom';
import CustomPhoneInput from '../../ui-component/form/CustomPhoneInput';
import { formatPhone } from '../../utils/phone-helpers';
import { LoadingButton } from '@mui/lab';
import useCustomShiftConversions from '../../hooks/useCustomShiftConversions';
import EmployeeLocationSubform from './EmployeeLocationSubform';
import useEmployeeFormSchema from './use-employee-form-schema';
import { ISchedule } from '../../models/ILocation';
import useExtendedFormik from '../../hooks/useExtendedFormik';
import usePlanName from '../../hooks/usePlanName';

interface EmployeeFormProps {
    employee: IEmployee;
    save: (employee: IEmployeePayload) => any;
    isEdit?: boolean;
    isInvite?: boolean;
}

const EmployeeForm = ({ save, employee, isEdit, isInvite }: EmployeeFormProps) => {
    const planName = usePlanName();
    const [currentLocationId, setCurrentLocationId] = useState<number | null>(null);
    const { groupShifts, ungroupShifts } = useCustomShiftConversions();
    const [isBusy, setIsBusy] = useState(false);
    const { user, checkAuthentication } = useAuth();
    const { showSnackbar } = useShowSnackbar();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const employeeFormSchema = useEmployeeFormSchema(Boolean(isEdit));

    const [roles, setRoles] = useState([UserRole.Admin, UserRole.Manager, UserRole.Provider, UserRole.FrontDesk, UserRole.ReadOnlyLimited]);

    const invalidateQueries = useCallback(() => {
        dispatch(serviceAPI.util.invalidateTags(['Service']));
        dispatch(employeeAPI.util.invalidateTags(['Employee']));
        dispatch(locationAPI.util.invalidateTags(['Location']));
        dispatch(appointmentAPI.util.invalidateTags(['Appointment']));
    }, [dispatch]);

    const handleSuccessfullCreate = useCallback(
        (res) => {
            // @ts-ignore
            if (res.error) {
                showSnackbar({
                    message: 'Данный сотрудник уже добавлен в компанию',
                    alertSeverity: SnackBarTypes.Error
                });
            } else {
                showSnackbar({
                    message: 'Сотрудник создан',
                    alertSeverity: SnackBarTypes.Success
                });
                navigate('/employee', { replace: true });
                checkAuthentication();
            }
            invalidateQueries();
        },
        [checkAuthentication, invalidateQueries, navigate, showSnackbar]
    );

    const handleSuccessfullEdit = useCallback(
        (arg) => {
            showSnackbar({
                message: 'Сотрудник обновлен',
                alertSeverity: SnackBarTypes.Success
            });
            invalidateQueries();
            navigate('/employee', { replace: true });
            if (user?.employee.id === arg.id) {
                checkAuthentication();
            }
        },
        [checkAuthentication, invalidateQueries, navigate, showSnackbar, user]
    );

    const initialValues = useMemo(
        () => ({
            ...employee,
            services: employee.services.map(({ id, pivot, payment_type }) => ({
                id,
                price: pivot?.price ?? null,
                has_price_override: Boolean(pivot?.price),
                duration: pivot?.duration ?? null,
                has_duration_override: Boolean(pivot?.duration),
                has_commission_override: Boolean(pivot?.commission_type),
                commission_type: pivot?.commission_type ?? null,
                commission_amount: pivot?.commission_amount ?? null
            })),
            background_color: employee.background_color ? `#${employee.background_color}` : colors.blue.value,
            text_color: employee.text_color ? `#${employee.text_color}` : colors.white.value,
            locations: employee.locations.map(({ id, name, schedule }) => ({ id, name, schedule })),
            location_shifts: employee.locations.map(({ id, pivot, name, schedule }) => ({
                id,
                pivot: { ...pivot, shifts: groupShifts(pivot.shifts) },
                name,
                schedule
            }))
        }),
        [employee, groupShifts]
    );

    const {
        handleSubmit,
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        setFieldError,
        validateField
    } = useExtendedFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: employeeFormSchema,
        onSubmit: (formData) => {
            const data = {
                ...formData,
                services: formData.services.map(
                    ({
                        id,
                        price,
                        has_price_override,
                        has_duration_override,
                        duration,
                        has_commission_override,
                        commission_type,
                        commission_amount
                    }) => ({
                        id,
                        pivot: {
                            price: has_price_override ? price : null,
                            duration: has_duration_override ? duration : null,
                            commission_type: has_commission_override ? commission_type : null,
                            commission_amount: has_commission_override ? commission_amount : null
                        }
                    })
                ),
                user: {
                    ...formData.user,
                    phone: formData.user.phone ? formatPhone(formData.user.phone) : formData.user.phone
                },
                locations: undefined,
                location_shifts: formData.location_shifts
                    .map(({ id, pivot }) => ({
                        id,
                        pivot: { ...pivot, shifts: ungroupShifts(pivot.shifts) }
                    }))
                    .filter((loc) => formData.locations.some(({ id }) => id === loc.id)),
                background_color: formData.background_color.replace('#', ''),
                text_color: formData.text_color.replace('#', '')
            } as IEmployeePayload;
            setIsBusy(true);

            save(data)
                .unwrap()
                .then((res: any) => {
                    if (isEdit) {
                        handleSuccessfullEdit(data);
                    } else {
                        handleSuccessfullCreate(res);
                    }
                })
                .catch((e: { data: any; errors: any }) => {
                    if (e.errors) {
                        Object.keys(e.errors).forEach((key) => {
                            setFieldError(key.replace('.pivot', ''), e.errors[key]);
                        });
                    }
                    showSnackbar({
                        message: e.data || `Ошибка: сотрудник не был ${isEdit ? 'обновлен' : 'создан'}`,
                        alertSeverity: SnackBarTypes.Error
                    });
                })
                .finally(() => {
                    setIsBusy(false);
                });
        }
    });

    const locations = locationAPI.useFetchAllLocationsQuery({});

    const handleChangeLocation = useCallback(
        (value: { name: string; id: number; schedule: ISchedule[] }[]) => {
            const shiftIds = values.location_shifts.map(({ id }) => id);
            const newIds = value.filter(({ id }) => !shiftIds.includes(id));
            setFieldValue('locations', value);
            if (newIds.length) {
                const newShifts = newIds.map((v) => ({
                    id: v.id,
                    name: v.name,
                    pivot: { use_location_schedule: true, is_shifts_enabled: false, schedule: v.schedule, shifts: [] }
                }));
                setFieldValue('location_shifts', [...values.location_shifts, ...newShifts]);
            }
        },
        [setFieldValue, values.location_shifts]
    );

    const setServicesValue = useCallback(
        (key: string, value: any) => {
            validateField('services');
            setFieldValue(key, value);
        },
        [setFieldValue, validateField]
    );

    useEffect(() => {
        if (user && (user.employee.role.name === UserRole.Manager || user.employee.role.name === UserRole.Provider)) {
            setRoles([UserRole.Manager, UserRole.Provider]);
        }
    }, [user]);

    useEffect(() => {
        if (employee.avatar && typeof employee.avatar === 'object') {
            const imageLink = employee.avatar.url.match(/images.*$/);
            setFieldValue('avatar', imageLink ? imageLink[0] : null);
        }
    }, [employee, setFieldValue]);

    const setAvatar = useCallback(
        (field: string, value: any): any => {
            setFieldValue('avatar', value ? value[0] : null);
        },
        [setFieldValue]
    );

    const clearAvatar = useCallback(() => {
        setFieldValue('avatar', null);
    }, [setFieldValue]);

    useEffect(() => {
        const currentLocationInList = values.locations.some(({ id }) => id === currentLocationId);
        const noValueSelected = !currentLocationId && values.locations.length;
        const valueWasRemoved = currentLocationId && values.locations.length && !currentLocationInList;
        if (noValueSelected || valueWasRemoved) {
            setCurrentLocationId(values.locations[0].id);
        }
    }, [currentLocationId, values.locations]);

    return (
        <>
            {planName !== 'single user' && <ResendInvitationButton employee={employee} />}
            {!locations.isFetching && user && (
                <form noValidate onSubmit={handleSubmit}>
                    <CardContent sx={{ px: { xs: 0, sm: 2 }, pt: { xs: 0, sm: 2 } }}>
                        <Grid container spacing={2} alignItems="center">
                            <ImageUploader
                                initialPreview={values?.avatar}
                                setFieldValue={setAvatar}
                                name="Аватар"
                                imageFieldName="avatar"
                                clearFormImage={clearAvatar}
                            />
                            <Grid item xs={12} sm={3} lg={4}>
                                <InputLabel horizontal>Имя</InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={9} lg={6}>
                                <FormControl fullWidth error={Boolean(touched.user?.firstname && errors.user?.firstname)}>
                                    <OptimizedTextField
                                        id="firstname"
                                        name="user.firstname"
                                        placeholder="Имя"
                                        disabled={isEdit || isInvite}
                                        value={values.user.firstname}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    {touched.user?.firstname && errors.user?.firstname && (
                                        <FormHelperText error id="error-firstname">
                                            {errors.user?.firstname}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} lg={4}>
                                <InputLabel horizontal>Фамилия</InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={9} lg={6}>
                                <FormControl fullWidth error={Boolean(touched.user?.lastname && errors.user?.lastname)}>
                                    <OptimizedTextField
                                        id="lastname"
                                        name="user.lastname"
                                        placeholder="Фамилия"
                                        disabled={isEdit || isInvite}
                                        value={values.user.lastname}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    {touched.user?.lastname && errors.user?.lastname && (
                                        <FormHelperText error id="error-lastname">
                                            {errors.user?.lastname}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} lg={4}>
                                <LabelWithInfo
                                    label="Должность"
                                    infoText="Должность отображается под именем сотрудника на странице онлайн записи."
                                />
                            </Grid>
                            <Grid item xs={12} sm={9} lg={6}>
                                <FormControl fullWidth error={Boolean(touched.profession_title && errors.profession_title)}>
                                    <OptimizedTextField
                                        id="profession_title"
                                        name="profession_title"
                                        placeholder="Должность"
                                        value={values.profession_title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormControl>
                                {touched.profession_title && errors.profession_title && (
                                    <FormHelperText error id="error-profession_title">
                                        {errors.profession_title}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={3} lg={4}>
                                <InputLabel horizontal>Телефон</InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={9} lg={6}>
                                <FormControl fullWidth error={Boolean(touched.user?.phone && errors.user?.phone)}>
                                    <CustomPhoneInput
                                        value={values.user.phone}
                                        onChange={(v) => {
                                            setFieldValue('user.phone', v);
                                        }}
                                        id="user.phone"
                                        name="user.phone"
                                        disabled={Boolean(isEdit || isInvite)}
                                        onBlur={handleBlur}
                                        placeholder="Телефон"
                                    />
                                    {touched.user?.phone && errors.user?.phone && (
                                        <FormHelperText error id="error-phone">
                                            {errors.user?.phone}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} lg={4}>
                                <InputLabel horizontal>Email</InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={9} lg={6}>
                                <FormControl fullWidth error={Boolean(touched.user?.email && errors.user?.email)}>
                                    <OptimizedTextField
                                        id="email"
                                        name="user.email"
                                        placeholder="Email"
                                        disabled={isEdit || isInvite}
                                        value={values.user.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {touched.user?.phone && errors.user?.phone && (
                                        <FormHelperText error id="error-email">
                                            {errors.user?.email}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {planName !== 'single user' && (
                                <>
                                    <Grid item xs={12} sm={3} lg={4}>
                                        <LabelWithInfo
                                            label="Роль"
                                            infoText="Администратор имеет доступ ко всему функционалу и настройкам. Менеджер отвечает за управление сотрудниками, услугами и расписанием, и не имеет доступа к глобальным настройкам. Ресепшен назначает записи для других сотрудников без доступа к остальному функционалу. Мастер - сотрудник непосредственно оказывающий услуги и управляющий своими записями."
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={9} lg={6}>
                                        {/* Owner can't change his role */}
                                        {user.employee.role.name === UserRole.Owner && employee.user.id === user.id ? (
                                            <Typography sx={{ pl: 2 }}>{UserRoleLabel[user.employee.role.name]}</Typography>
                                        ) : (
                                            <FormControl fullWidth error={Boolean(touched.role && errors.role)}>
                                                <Select
                                                    id="role"
                                                    name="role"
                                                    value={values.role}
                                                    onChange={handleChange}
                                                    disabled={!isAllowChangeRole(user, employee)}
                                                >
                                                    {roles.map((role) => (
                                                        <MenuItem key={role} value={role}>
                                                            {UserRoleLabel[role]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={3} lg={4}>
                                        <LabelWithInfo label="Цвет" infoText="Цвет записей сотрудника на календаре." />
                                    </Grid>
                                    <Grid item xs={12} sm={9} lg={6}>
                                        <FormControl fullWidth>
                                            <Select
                                                id="background_color"
                                                name="background_color"
                                                value={values.background_color}
                                                onChange={handleChange}
                                                disabled={!isAllowChangeRole(user, employee)}
                                                renderValue={(value) => (
                                                    <Stack flexDirection="row" alignItems="center" gap={1}>
                                                        <Circle sx={{ color: value }} />
                                                        {Object.values(colors).find((c) => c.value === value)?.label}
                                                    </Stack>
                                                )}
                                            >
                                                {Object.values(colors)
                                                    .filter((color) => color.label !== 'White')
                                                    .map((color) => (
                                                        <MenuItem key={color.value} value={color.value}>
                                                            <ListItemIcon>
                                                                <Circle sx={{ color: color.value }} />
                                                            </ListItemIcon>
                                                            <ListItemText>{color.label}</ListItemText>
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                            {/* <TwoColumnsSwitch
                                value={Boolean(values.settings?.widget?.accounting_google_events)}
                                fieldName="settings.widget.accounting_google_events"
                                setFieldValue={setFieldValue}
                                label="Учитывать занятось из сторонних календарей"
                            /> */}
                            {planName !== 'single user' && (
                                <TwoColumnsSwitch
                                    value={values.self_book}
                                    fieldName="self_book"
                                    setFieldValue={setFieldValue}
                                    label="Разрешить бронь через виджет"
                                    labelDecoration={
                                        <InfoTooltip text="Включите, чтобы клиенты могли бронировать услуги с данным сотрудником через виджет." />
                                    }
                                />
                            )}
                            {!!values.locations.length && (
                                <>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    {planName !== 'single user' && (
                                        <>
                                            <Grid item xs={12} sm={3} lg={4}>
                                                <InputLabel>Локация для настройки расписания</InputLabel>
                                            </Grid>

                                            <Grid item xs={12} sm={9} lg={6}>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    value={currentLocationId ?? ''}
                                                    aria-label="Локация для настройки расписания"
                                                    onChange={(e) => setCurrentLocationId(parseInt(e.target.value, 10))}
                                                    error={Boolean(errors.location_shifts && errors.location_shifts.length)}
                                                >
                                                    {values.locations
                                                        .sort((a, b) => a.id - b.id)
                                                        .map((location) => (
                                                            <MenuItem key={location.id} value={location.id}>
                                                                {location.name}
                                                            </MenuItem>
                                                        ))}
                                                </TextField>
                                                {values.location_shifts.map(({ id, name }, index) => {
                                                    const error = errors?.location_shifts?.[index];
                                                    if (error) {
                                                        return (
                                                            <FormHelperText error key={id}>{`Ошибки в локации "${name}".`}</FormHelperText>
                                                        );
                                                    }

                                                    return null;
                                                })}
                                            </Grid>
                                        </>
                                    )}
                                    {values.location_shifts.map((location, index) => {
                                        if (location.id === currentLocationId) {
                                            const itemErrors = errors?.location_shifts?.[index];
                                            return (
                                                <EmployeeLocationSubform
                                                    key={location.id}
                                                    values={location.pivot}
                                                    valuePrefix={`location_shifts.${index}.pivot`}
                                                    setFieldValue={setFieldValue}
                                                    errors={itemErrors && typeof itemErrors !== 'string' ? itemErrors.pivot : undefined}
                                                    planName={planName}
                                                />
                                            );
                                        }

                                        return null;
                                    })}
                                </>
                            )}
                            {planName !== 'single user' && (
                                <>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sm={3} lg={4}>
                                        <LabelWithInfo label="Локации" infoText="Выберите локации, в которых сотрудник оказывает услуги." />
                                    </Grid>
                                    <Grid item xs={12} sm={9} lg={6}>
                                        <FormControl fullWidth error={Boolean(touched.locations && errors.locations)}>
                                            <Autocomplete
                                                multiple
                                                id="checkboxes-tags-locations"
                                                options={
                                                    locations.data
                                                        ? locations.data?.data.map(({ id, name, schedule }) => ({
                                                              id,
                                                              name,
                                                              schedule
                                                          }))
                                                        : []
                                                }
                                                value={values.locations}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.name}
                                                onBlur={(e) => {
                                                    setFieldTouched('locations');
                                                    handleBlur(e);
                                                }}
                                                onChange={(e, value) => handleChangeLocation(value)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                                        {option.name}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder={values.locations?.length ? undefined : 'Локации'} />
                                                )}
                                            />
                                            {touched.locations && errors.locations && (
                                                <FormHelperText error id="errors-locations">
                                                    {errors.locations}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3} lg={4}>
                                        <LabelWithInfo
                                            label="Услуги"
                                            infoText="Выберите услуги, оказываемые сотрудником. Убедитесь что нужные услуги также добавлены в соответствующие локации."
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={9} lg={6}>
                                        <ServiceSelect
                                            value={values.services}
                                            touched={touched}
                                            errors={errors}
                                            setFieldTouched={setFieldTouched}
                                            setFieldValue={setServicesValue}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions sx={{ px: { xs: 0, sm: 2 } }}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <LoadingButton type="submit" variant="contained" color="primary" loading={isBusy} disabled={isBusy}>
                                    Сохранить
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </CardActions>
                </form>
            )}
        </>
    );
};

export default EmployeeForm;
