import StepTitle from '../components/StepTitle';
import Transitions from '../../../ui-component/extended/Transitions';
import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import EventAvailable from '@mui/icons-material/EventAvailable';
import Refresh from '@mui/icons-material/Refresh';
import { useAppSelector } from '../../../hooks/redux';
import WidgetConfirmButton from '../components/WidgetConfirmButton';
import { useStepContext } from '../steps-context/StepsContext';
import { Alert } from '@mui/material';
import StyledRestartButton from '../../../ui-component/widgets/styled/StyledRestartButton';
import { useParams } from 'react-router-dom';
import WidgetWaitlist from '../../../ui-component/widgets/WidgetWaitlist';

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.widget.text,
    fontSize: '20px',

    '@media(max-width: 768px)': {
        fontSize: '18px'
    }
}));

interface WizardFinalProps {
    step: number;
    handleBack: () => void;
    submitBooking: () => void;
    resetWidget: () => void;
    rawResetWidget: () => void;
    submitted: boolean;
    waitlist: { enabled: boolean; any_employee: boolean };
}

const WizardFinal = ({ step, handleBack, submitBooking, resetWidget, rawResetWidget, submitted, waitlist }: WizardFinalProps) => {
    const { company_slug } = useParams();
    const { couponError, created_uuid } = useAppSelector((store) => store.widget);
    const { isSubmitting } = useAppSelector((state) => state.submitting);
    const { stepsLength } = useStepContext();

    const handleSubmit = () => {
        if (step + 1 === stepsLength) {
            submitBooking();
        }
    };

    return (
        <Transitions type="fade" in>
            <StepTitle
                title={submitted ? '' : 'Подтвердить запись'}
                error={false}
                step={step}
                handleBack={handleBack}
                submitted={submitted}
            />
            <StyledTypography sx={{ display: 'block', textAlign: 'center' }}>
                {submitted ? 'Запись успешно создана' : 'Пожалуйста, проверьте данные и подтвердите запись.'}
            </StyledTypography>
            {submitted && couponError && (
                <Alert sx={{ my: 2 }} severity="error">
                    {couponError}
                </Alert>
            )}
            {!submitted && (
                <WidgetConfirmButton onClick={handleSubmit} disabled={isSubmitting}>
                    <EventAvailable />
                    Подтвердить запись
                </WidgetConfirmButton>
            )}

            {waitlist.enabled && company_slug && created_uuid ? (
                <WidgetWaitlist company_slug={company_slug} appointment_uuid={created_uuid} any_employee_enabled={waitlist.any_employee} />
            ) : null}

            <StyledRestartButton
                sx={{ display: { xs: submitted ? 'inline-flex' : 'none', sm: 'inline-flex' } }}
                onClick={submitted ? rawResetWidget : resetWidget}
                id="Restart-Booking"
            >
                <Refresh />
                {submitted ? 'Записаться еще' : 'Начать заново'}
            </StyledRestartButton>
        </Transitions>
    );
};

export default WizardFinal;
