import { ICustomerPayload } from '../models/ICustomer';
import { Grid, IconButton, Link, TextField } from '@mui/material';
import SectionHeading from '../views/calendar/appointment-card/appointment-details/elements/SectionHeading';
import CustomPhoneInput from './form/CustomPhoneInput';
import React, { useMemo } from 'react';
import useShouldSubmitDetailsForm from '../hooks/useShouldSubmitDetailsForm';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useForceMobileLayoutContext } from './force-mobile-layout-context';
import useCanSeeCustomerDetails from '../hooks/use-can-see-customer-details';
import useCustomerFormValidation from '../views/customer/hooks/use-customer-form-validation';
import useExtendedFormik from '../hooks/useExtendedFormik';

interface CustomerFormProps {
    customer: ICustomerPayload;
    onSubmit: (data: ICustomerPayload) => void;
    formId: string;
    hideTitles?: boolean;
    isReadOnly?: boolean;
    skipChangesCheck?: boolean;
    isNew?: boolean;
}

const CustomerForm = ({
    customer,
    onSubmit,
    formId,
    hideTitles = false,
    isReadOnly = false,
    skipChangesCheck = false,
    isNew
}: CustomerFormProps) => {
    const { canEditCustomerDetails, canEditCustomerOwner } = useCanSeeCustomerDetails();
    const { schema } = useCustomerFormValidation();

    const canSeeDetails = useMemo(() => (!customer ? true : canEditCustomerDetails(customer.employee_owner_id)), [
        canEditCustomerDetails,
        customer
    ]);

    const {
        handleSubmit,
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldTouched,
        setFieldValue,
        initialValues
    } = useExtendedFormik<ICustomerPayload>({
        enableReinitialize: true,
        initialValues: customer,
        validationSchema: schema,
        onSubmit: (formData) => {
            if (canSeeDetails) {
                onSubmit(formData);
            }
        }
    });

    const forceMobile = useForceMobileLayoutContext();

    const isDirty = useMemo(() => {
        if (canSeeDetails) {
            const simpleFields = ['firstname', 'lastname', 'email', 'employee_owner_id'] as const;
            const simpleFieldsChanged = simpleFields.some((field) => values[field] !== initialValues[field]);

            // need to remove all non-numeric symbols from the phone fields because phone formats on FE and BE are different
            const phoneChanged = (values.phone ?? '').replace(/\D/g, '') !== (initialValues.phone ?? '')?.replace(/\D/g, '');

            return simpleFieldsChanged || phoneChanged;
        }

        return false;
    }, [canSeeDetails, values, initialValues]);

    useShouldSubmitDetailsForm(isDirty, skipChangesCheck);

    return (
        <form noValidate onSubmit={handleSubmit} id={formId}>
            <Grid container spacing={2}>
                {!hideTitles && (
                    <Grid item xs={12}>
                        <SectionHeading>Личная информация</SectionHeading>
                    </Grid>
                )}
                <Grid item xs={12} sm={forceMobile ? 12 : 6}>
                    <TextField
                        fullWidth
                        label="Имя"
                        name="firstname"
                        value={values.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.firstname && errors?.firstname)}
                        helperText={touched?.firstname ? errors?.firstname : undefined}
                        disabled={isReadOnly || !canSeeDetails}
                    />
                </Grid>
                <Grid item xs={12} sm={forceMobile ? 12 : 6}>
                    <TextField
                        fullWidth
                        label="Фамилия"
                        name="lastname"
                        value={values.lastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.lastname && errors?.lastname)}
                        helperText={touched?.lastname ? errors?.lastname : undefined}
                        disabled={isReadOnly || !canSeeDetails}
                    />
                </Grid>

                {canSeeDetails ? (
                    <>
                        {!hideTitles && (
                            <Grid item xs={12}>
                                <SectionHeading>Контактные данные</SectionHeading>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={forceMobile ? 12 : 6}>
                            <CustomPhoneInput
                                fullWidth
                                id="phone"
                                name="phone"
                                label="Телефон"
                                placeholder="Телефон"
                                value={values.phone ?? ''}
                                onChange={(v) => {
                                    setFieldTouched('phone', false);
                                    setFieldValue('phone', v);
                                }}
                                onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    handleBlur(e);
                                    if (!e.target.value) {
                                        setFieldTouched('phone', false);
                                    }
                                }}
                                error={Boolean(touched?.phone && errors?.phone)}
                                helperText={touched?.phone ? errors?.phone : undefined}
                                hasActions
                                disabled={isReadOnly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={forceMobile ? 12 : 6}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                value={values.email ?? ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched?.email && errors?.email)}
                                helperText={touched?.email ? errors?.email : undefined}
                                disabled={isReadOnly}
                                InputProps={{
                                    endAdornment: (
                                        <Link
                                            component={IconButton}
                                            size="small"
                                            href={`mailto:${values.email}`}
                                            disabled={!!errors?.email || !values.email}
                                        >
                                            <MailOutlineIcon />
                                        </Link>
                                    )
                                }}
                            />
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </form>
    );
};

export default CustomerForm;
