// material-ui
import { CircularProgress, Typography, Box } from '@material-ui/core';
import Transitions from '../../../../ui-component/extended/Transitions';
import LocationOn from '@mui/icons-material/LocationOn';

// project imports
import { ILocation } from 'models/ILocation';
import { LocationFormProps } from '../types';

// third-party
import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import StepTitle from '../../components/StepTitle';
import WidgetButton from '../../components/WidgetButton';
import { setCoupon, setCouponError } from '../../../../store/slices/widgetSlice';
import { useAppDispatch } from '../../../../hooks/redux';
import useWidgetOptions from '../../hooks/useWidgetOptions';
import useExtendedFormik from '../../../../hooks/useExtendedFormik';
import { useParams } from 'react-router-dom';

const validationSchema = Yup.object({
    location: Yup.object().nullable().required('Выберите локацию')
});

const LocationForm = ({
    step,
    addProgress,
    substituteProgress,
    locationData,
    setLocationData,
    serviceData,
    setProviderData,
    setDateData,
    handleNext,
    handleBack,
    filteredLocations,
    submitted
}: LocationFormProps) => {
    const { company_slug } = useParams();
    const dispatch = useAppDispatch();
    const { errors, values: v, setFieldValue, handleSubmit } = useExtendedFormik({
        enableReinitialize: true,
        initialValues: {
            location: locationData
        },
        validationSchema,
        onSubmit: (formData) => {
            if (formData.location) {
                if (locationData && locationData.id !== formData.location.id) {
                    setProviderData(null);
                    setDateData(null);
                }
                setLocationData(formData.location);
                handleNext();
            }
        }
    });

    const { options, isLoading } = useWidgetOptions(
        { services: serviceData?.map(({ slug }) => slug) ?? [], company_slug },
        !serviceData || !!filteredLocations?.length
    );

    const locations = useMemo(() => filteredLocations ?? options.locations ?? [], [filteredLocations, options.locations]);

    useEffect(() => {
        if (!locationData && locations?.length === 1) {
            setLocationData(locations[0]);
            addProgress(step);
            handleNext();
        }
    }, [addProgress, handleNext, locationData, locations, setLocationData, step]);

    const handleSelectLocation = (location: ILocation) => {
        setFieldValue('location', location);
        if (locationData && locationData.id !== location.id) {
            setProviderData(null);
            setDateData(null);
            substituteProgress(step);
            setLocationData(location);
            handleNext();
        } else {
            addProgress(step);
            setLocationData(location);
            handleNext();
        }

        dispatch(setCoupon());
        dispatch(setCouponError());
    };

    return (
        <Transitions type="fade" in>
            <StepTitle error={!!errors.location} title="Выберите локацию" step={step} handleBack={handleBack} submitted={submitted} />
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <form onSubmit={handleSubmit} id={`widget-form-${step}`}>
                    {locations?.length ? (
                        locations.map((location) => (
                            <WidgetButton
                                key={`loc_btn_${location.id}`}
                                isSelected={v.location?.id === location.id}
                                name={location.name}
                                bottomContent={<i>{location.address.address}</i>}
                                onChoose={() => handleSelectLocation(location)}
                                avatarContent={<LocationOn />}
                            />
                        ))
                    ) : (
                        <Typography>Нет доступных локаций для данной услуги</Typography>
                    )}
                </form>
            )}
        </Transitions>
    );
};

export default LocationForm;
