import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';

const OptionTextWrapper: FC<PropsWithChildren<unknown>> = ({ children }) => (
    <Box
        sx={{
            '@media(max-width: 768px)': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        }}
    >
        {children}
    </Box>
);

export default OptionTextWrapper;
