import { useAppSelector } from '../../../../../../../hooks/redux';
import AppointmentForm from '../../../../../../../ui-component/appointment-form';

const Details = () => {
    const { selectedEvent, isForeignAppointment } = useAppSelector((state) => state.calendar);

    return <>{selectedEvent && <AppointmentForm data={selectedEvent} isReadOnly={isForeignAppointment} />}</>;
};

export default Details;
