import { FC } from 'react';
import { FormikProps } from 'formik';
import { RegistrationFormType } from '../types';
import { Grid, TextField } from '@mui/material';

type CompanyDataSubformPropsType = {
    formInstance: FormikProps<RegistrationFormType>;
};

const addressFields = ['address', 'city'] as const;
const translations = {
    address: 'Адрес',
    city: 'Город'
} as const;

const CompanyDataSubform: FC<CompanyDataSubformPropsType> = ({ formInstance }) => {
    const { values, errors, touched, handleChange, handleBlur } = formInstance;

    return (
        <>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="company_name"
                    name="company_name"
                    label="Название компании"
                    value={values.company_name}
                    error={!!errors.company_name && !!touched.company_name}
                    helperText={touched.company_name ? errors.company_name : undefined}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Grid>

            {addressFields.map((field) => (
                <Grid item xs={12} sm={6} key={field}>
                    <TextField
                        id={`address.${field}`}
                        name={`address.${field}`}
                        label={translations[field]}
                        value={values.address[field]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        error={!!touched.address?.[field] && !!errors.address?.[field]}
                        helperText={touched.address?.[field] ? errors.address?.[field] : undefined}
                        fullWidth
                    />
                </Grid>
            ))}
        </>
    );
};

export default CompanyDataSubform;
