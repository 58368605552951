const calculateCouponDiscountAmount = (
    price: string | number,
    coupon?: {
        amount: string;
        type: string;
    }
) => {
    const priceNumber = parseFloat(String(price));
    const couponAmountNumber = coupon ? parseFloat(String(coupon?.amount)) : 0;

    if (coupon?.type === 'fixed') {
        return { priceWithDiscount: priceNumber - couponAmountNumber, couponAmount: couponAmountNumber };
    }

    if (coupon?.type === 'percent') {
        const percent = (priceNumber / 100) * couponAmountNumber;
        return { priceWithDiscount: priceNumber - percent, couponAmount: percent };
    }

    return { priceWithDiscount: priceNumber, couponAmount: 0 };
};

export default calculateCouponDiscountAmount;
