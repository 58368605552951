import { ComponentCollection } from 'survey-core';

const registerCameraBlock = () => {
    if (!ComponentCollection.Instance.items.some(({ name }) => name === 'camerablock')) {
        ComponentCollection.Instance.add({
            name: 'camerablock',
            title: 'Camera Upload',
            questionJSON: {
                type: 'file',
                acceptedTypes: 'image/*',
                // maxSize: 2097152, // 2 MiB
                sourceType: 'camera'
            },
            iconName: 'icon-takepicture'
        });
    }
};

export default registerCameraBlock;
