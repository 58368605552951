import customerAPI from '../services/CustomerService';
import appointmentAPI from '../services/AppointmentService';
import { useMemo } from 'react';

interface IUseSearchOptions {
    search: string;
    page?: number;
    perPage?: number;
}

const useSearch = ({ search, page = 1, perPage = 5 }: IUseSearchOptions) => {
    const { data: customerData, isLoading: customersLoading } = customerAPI.useFetchAllCustomersQuery(
        {
            per_page: perPage,
            page,
            search
        },
        { skip: !search }
    );

    const { data: appointmentsData, isLoading: appointmentsLoading } = appointmentAPI.useFetchAllPaginatedAppointmentsQuery(
        {
            per_page: perPage,
            page,
            customerInfo: search
        },
        { skip: !search }
    );

    const total = useMemo(() => {
        const customers = customerData?.meta.total ?? 0;
        const apps = appointmentsData?.meta.total ?? 0;

        return customers + apps;
    }, [customerData, appointmentsData]);

    const loading = useMemo(() => customersLoading || appointmentsLoading, [customersLoading, appointmentsLoading]);

    return { customerData, appointmentsData, loading, total };
};

export default useSearch;
