import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppointmentStatuses, IAppointment } from '../../models/IAppointment';
import { ICustomer } from '../../models/ICustomer';
import { DateRange } from '../../views/calendar/types';

interface CalendarProps {
    isForeignAppointment: boolean;
    selectedEvent: IAppointment | null;
    appointmentDetailsFormId?: string;
    appointmentDetailsSaveButtonDisabled: boolean;
    shouldSubmitFormOnTabChange: boolean;
    selectedRange: DateRange | null;
    selectedEmployeeId: number | null;
    voiceRecordingOpened: boolean;
    recurringEventDate: string | null;
}

const initialState: CalendarProps = {
    isForeignAppointment: false,
    selectedEvent: null,
    appointmentDetailsFormId: undefined,
    appointmentDetailsSaveButtonDisabled: false,
    shouldSubmitFormOnTabChange: false,
    selectedRange: null,
    selectedEmployeeId: null,
    voiceRecordingOpened: false,
    recurringEventDate: null
};

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setIsForeignAppointment: (state, action: PayloadAction<boolean>) => {
            state.isForeignAppointment = action.payload;
        },
        setAppointmentDetailsFormId: (state, action: PayloadAction<string | undefined>) => {
            state.appointmentDetailsFormId = action.payload;
        },
        setAppointmentDetailsSaveButtonDisabled: (state, action: PayloadAction<boolean>) => {
            state.appointmentDetailsSaveButtonDisabled = action.payload;
        },
        setSelectedEvent: (state, action: PayloadAction<IAppointment | null>) => {
            state.selectedEvent = action.payload;
        },
        setSelectedEventCustomer: (state, action: PayloadAction<ICustomer>) => {
            if (!state.selectedEvent) return;
            state.selectedEvent.customer = action.payload;
        },
        setSelectedEventStatus: (state, action: PayloadAction<AppointmentStatuses>) => {
            if (!state.selectedEvent) return;
            state.selectedEvent.status = action.payload;
        },
        setShouldSubmitFormOnTabChange: (state, action: PayloadAction<boolean>) => {
            state.shouldSubmitFormOnTabChange = action.payload;
        },
        setSelectedRange: (state, action: PayloadAction<DateRange | null>) => {
            state.selectedRange = action.payload;
        },
        setSelectedEmployeeId: (state, action: PayloadAction<number | null>) => {
            state.selectedEmployeeId = action.payload;
        },
        openVoiceRecording: (state) => {
            state.voiceRecordingOpened = true;
        },
        closeVoiceRecording: (state) => {
            state.voiceRecordingOpened = false;
        },
        setRecurringEventDate: (state, action: PayloadAction<string | null>) => {
            state.recurringEventDate = action.payload;
        }
    }
});

export const {
    setIsForeignAppointment,
    setAppointmentDetailsFormId,
    setAppointmentDetailsSaveButtonDisabled,
    setSelectedEvent,
    setSelectedEventCustomer,
    setSelectedEventStatus,
    setShouldSubmitFormOnTabChange,
    setSelectedRange,
    setSelectedEmployeeId,
    openVoiceRecording,
    closeVoiceRecording,
    setRecurringEventDate
} = calendarSlice.actions;
