import { useCallback, useMemo, useState } from 'react';
import { IAppointment } from '../../../models/IAppointment';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Chip, Button, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import moment from 'moment/moment';
import { styled } from '@mui/material/styles';
import useGetColorByStatus from '../../../hooks/useGetColorByStatus';
import { getServiceArrayPivotPrice, getServiceName, isAllServicesFree } from '../../../utils/services';
import NumberFormat from 'react-number-format';
import appointmentStatuses from '../../../views/calendar/constants/appointment-statuses';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    margin: '10px 10px 10px 0 !important',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '8px !important',
    overflow: 'hidden',

    '&:first-of-type': {
        marginTop: '0 !important'
    },

    '&:before': {
        display: 'none'
    },

    '& .MuiAccordionSummary-content': {
        margin: '8px 0',

        '& .MuiTypography-root': {
            fontWeight: 600,

            '& span': {
                fontWeight: 500,
                textTransform: 'capitalize'
            }
        }
    },

    '& .MuiAccordionDetails-root': {
        backgroundColor: 'white',

        '& .MuiTypography-root': {
            display: 'flex',
            alignItems: 'center',
            gap: '5px',

            '& .MuiSvgIcon-root': {
                fontSize: '1.2rem',
                color: theme.palette.grey[400]
            }
        }
    },

    '& .MuiChip-root': {
        color: 'white',
        textTransform: 'capitalize',
        fontWeight: 600,
        borderRadius: '8px',
        marginRight: '5px',

        '& .MuiChip-label': {
            padding: '0 20px'
        }
    }
}));

interface AppointmentRowProps {
    appointment: IAppointment;
    onViewAppointment?: () => void;
}

const AppointmentRow = ({ appointment, onViewAppointment }: AppointmentRowProps) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const { getColorByStatus } = useGetColorByStatus();

    const toggleIsOpened = useCallback(() => setIsOpened(!isOpened), [isOpened]);
    const { fullValue, counter, firstItemValue } = getServiceName(appointment.services);
    const counterTitle = counter ? `${firstItemValue} + ${counter}` : firstItemValue;

    const price = useMemo(() => getServiceArrayPivotPrice(appointment.services), [appointment.services]);
    const isFree = useMemo(() => isAllServicesFree(appointment.services), [appointment.services]);

    return (
        <StyledAccordion expanded={isOpened} onChange={toggleIsOpened}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    <Typography>
                        {`${moment(appointment.start_at).format('MMM  DD, YYYY')} - ${counterTitle}, `}
                        <span>{appointment.employee.title}</span>
                    </Typography>
                    <Chip
                        label={appointmentStatuses.find((appointmentStatus) => appointmentStatus.value === appointment.status)?.label}
                        variant="filled"
                        sx={{ backgroundColor: getColorByStatus(appointment.status), minWidth: 120 }}
                    />
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Stack direction="row">
                    <Box flexGrow={1}>
                        <Typography mb={1}>
                            <AccessTimeIcon />
                            {moment(appointment.start_at).format('HH:mm')} -{' '}
                            {moment(appointment.end_at).clone().add(1, 'second').format('HH:mm')}{' '}
                            {counter ? <span>{`Услуги: ${fullValue}`}</span> : null}
                        </Typography>
                        {price && !isFree ? (
                            <Typography>
                                <LocalAtmIcon />
                                <NumberFormat displayType="text" value={price} suffix=" ₽" decimalScale={2} fixedDecimalScale />
                            </Typography>
                        ) : null}

                        {isFree ? (
                            <Typography>
                                <LocalAtmIcon />
                                Бесплатно
                            </Typography>
                        ) : null}
                    </Box>
                    {onViewAppointment ? (
                        <Box flexGrow={0} flexShrink={0}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={onViewAppointment}
                                sx={{ minWidth: 120, mr: '29px', borderRadius: '8px', minHeight: 32 }}
                            >
                                Просмотр
                            </Button>
                        </Box>
                    ) : null}
                </Stack>
            </AccordionDetails>
        </StyledAccordion>
    );
};

export default AppointmentRow;
